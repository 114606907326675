import React from "react";
import { Link } from "react-router-dom";

import { IntlProvider, FormattedMessage } from "react-intl";

const containerStyle = {
  padding: "20px",
  margin: "20px auto",
  maxWidth: "800px",
};

const titleStyle = {
  textAlign: "center",
  margin: "20px auto",
};

const backButtonStyle = {
  display: "block",
  margin: "20px auto",
  textAlign: "center",
};

const TermsPage = () => {
  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>
        <FormattedMessage id="terms.title" />
      </h1>
      <p>
        <FormattedMessage id="terms.para1" />
      </p>
      <p>
        <ol>
          <li>
        <FormattedMessage id="terms.item1" />
          </li>
          <li>
        <FormattedMessage id="terms.item2" />
          </li>
          <li>
        <FormattedMessage id="terms.item3" />
          </li>
          <li>
        <FormattedMessage id="terms.item4" />
          </li>
          <li>
        <FormattedMessage id="terms.item5" />
          </li>
          <li>
        <FormattedMessage id="terms.item6" />
          </li>
        </ol>
      </p>
      <div style={backButtonStyle}>
        <Link to="/" className="btn btn-secondary">
        <FormattedMessage id="button.backhome" />
        </Link>
      </div>
    </div>
  );
};

export default TermsPage;