import { Grid, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import FilterComponent from "../components/FilterComponent";
import ItemCard from "../components/ItemCard";
import LocationDiv from "../components/LocationDiv";
import MenuPanel from "../components/MenuPanel";
import TitleDiv from "../components/TitleDiv";
import { useSelector, useDispatch } from "react-redux";
import "./HomePage.css";
import { useEffect, useMemo, useState } from "react";
import { getProducts } from "../Redux/CounterSlice";
import {
  fetchUserAccount,
  getPropertiesReducer,
  walletBalanceFetch,
} from "../Redux/properties/PropertiesSlice";

import {
  CURRENT_PURPOSE,
  WEBSITE_CONSTANTS,
  WEBSITE_FARM_TRADE,
  WEBSITE_REAL_ESTATE,
} from "../configs/website-config";
import RealEstateMediaCard from "../components/RealEstateItem";
import { HashConnect } from "hashconnect";
import SalePanel from "../components/SalePanel";
import { LoginModal } from "../components/LoginModal";
import Footer from "../components/Footer";
import SecondFooter from "../components/SecondFooter";

const backgroundImage =
  CURRENT_PURPOSE === WEBSITE_REAL_ESTATE
    ? "../assets/bg.jpeg"
    : "../assets/summer.png";

const style = {
  papaerStyle: {
    width: "100%",
    backgroundImage: "url('" + backgroundImage + "')",
  },
  gridStyle: {
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    // backgroundColor: "rgba(00,00,99,0.75)",
    backgroundColor: "rgb(122 139 164 / 75%)",

    //    backgroundColor: "#371D10",
  },
  addressBar: {
    height: "5vh",
    width: "90vw",
    opacity: 1,
    marginTop: "10px",
    marginRight: "100px",
    marginLeft: "100px",
  },
  menuBar: {
    height: "120px",
    width: "100%",
    opacity: 1,
    // marginTop: "10px",
    // marginRight: "100px",
    // marginLeft: "100px",
  },
  titleBar: {
    height: "25vh",
    opacity: 1,
  },
  dummBar: {
    height: "100px",
    color: "#ffffff",
    textAlign: "center",
    fontSize: "35px",
    fontWeight: "bolder",
    opacity: 1,
    // paddingLeft: "10%",
    // paddingRight: "10%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dummBar1: {
    height: "10px",
    color: "#ffffff",
    textAlign: "center",
    fontSize: "35px",
    fontWeight: "bolder",
    opacity: 1,
  },
  lineDiv: {
    height: "100px",
    color: "#ffffff",
    textAlign: "center",
    fontSize: "35px",
    fontWeight: "bolder",
    opacity: 1,
    marginTop: "2%",
  },
  Filter: {
    // height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80px"
  },
  items: {
    height: "auto",
  },
  whole: {
    height: "100%",
    width: "100%",
    overflow: "auto", 
    display: "flex",
  },
};

const Marketplace = () => {
  let { level } = useParams();
  const [filter, setFilter] = useState({ category: '', product: '', UPC: '', product_name_id: '' })
  const products = useSelector((state) => state.properties.data);

  const productsFilter = useMemo(() => {
    return products.filter(e => {
      const productFilter = filter.category
        ? e?.product_name_id === filter.UPC
        : true;

      const upcFilter = filter.category
      ? e?.product_name_id === filter.UPC
      : true;

      return productFilter && upcFilter;
    });
  }, [products, filter]);

  const [salePanelModal, setSalePanelModal] = useState(false);
  const [saleSelected, setSaleSelected] = useState();

  useEffect(() => {
    if (saleSelected) console.log("saleSelected", saleSelected);
    setSalePanelModal(true);
  }, [saleSelected]);

  return (
    <>
        {saleSelected && (
          <SalePanel
            modal={salePanelModal}
            toggle={() => {
              setSalePanelModal(false);
              setSaleSelected(undefined);
            }}
            title={saleSelected?.title}
            value={saleSelected}
          >
            {" "}
          </SalePanel>
        )}
        <div className="whole" style={style.whole}>
        <Paper sx={style.papaerStyle} className="backgroundImage">
          <Grid container sx={style.gridStyle}>
            <Grid item sx={style.menuBar}>
              <MenuPanel level={level} />
            </Grid>
            <Grid item sx={style.dummBar}>
              <h3>{WEBSITE_CONSTANTS.QUOTE1}</h3>
            </Grid>
            <Grid item sx={style.Filter}>
              <FilterComponent setFilter={setFilter} filter={filter} />
            </Grid>
            <Grid item sx={style.items}>
              <Items selectedSale={setSaleSelected} products={productsFilter} />
            </Grid>
              <SecondFooter />
              <Footer />
          </Grid>
        </Paper>
        </div>
        <LoginModal />
    </>
  );
};

const Items = ({ selectedSale, products }) => {
  const data12 = useSelector((state) => state.counter.data);

  const dispatch = useDispatch();

  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (!fetched) {
      setFetched(true);
      loadDetails();
    }
  }, [fetched]);

  const loadDetails = async () => {
    if (CURRENT_PURPOSE === WEBSITE_REAL_ESTATE) {
      dispatch(getPropertiesReducer());
    } else {
      dispatch(getProducts());
    }
  };
  const ItemsStyle = {
    gridItem: {
      width: "400px",
      height: "auto",
      padding: "50px",
      paddingTop: "0",
    },
  };
  return (
    <Grid container>
      {CURRENT_PURPOSE === WEBSITE_FARM_TRADE &&
        data12.map((x, ind) => (
          <Grid item sx={ItemsStyle.gridItem} key={ind}>
            <ItemCard selectedSale={selectedSale} item={x} />
          </Grid>
        ))}
      {CURRENT_PURPOSE === WEBSITE_REAL_ESTATE &&
        products.map((x, ind) => {
          if (x.publishId === 1) {
            return (
              <Grid item sx={ItemsStyle.gridItem} key={ind}>
                <RealEstateMediaCard selectedSale={selectedSale} item={x} />
              </Grid>
            )
          }
          return null
        })}
    </Grid>
  );
};

export default Marketplace;