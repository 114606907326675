// RemoveFieldConfirmationModal.js
import React from 'react';
import { Modal, Text, Button } from '@mantine/core';
import { FormattedMessage, useIntl } from 'react-intl';

const RemoveFieldConfirmationModal = ({ fieldName, onConfirm, onCancel, opened }) => {
  const intl = useIntl();  // Use the useIntl hook to access the formatting functions

  // Get the formatted message for the title
  const title = intl.formatMessage({ id: 'modal.remConfirm.title' });
  return (
    <Modal
      title={`${title} ${fieldName.sku} ?`}
      size="sm"
      opened={opened}
      onClose={onCancel}
      shadow="xs"
    >
      <Text style={{ margin: '10px' }}>
        <FormattedMessage id="modal.remConfirm.text" />
      </Text>
      <Button variant="light" color="blue" onClick={onCancel} style={{ margin: '10px' }}>
        <FormattedMessage id="button.cancel" />
      </Button>
      <Button variant="filled" color="green" onClick={onConfirm} style={{ margin: '10px' }}>
      <FormattedMessage id="button.confirm" />
      </Button>
    </Modal>
  );
};

export default RemoveFieldConfirmationModal;
