import {
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { contractsByUser } from "../Redux/products/slice";
import UpdateContractModal from "./UpdateContract";
import { PropertyContractTableConfig } from "../components/mockData";
import moment from "moment";
import {   Select, createStyles, Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  rem, } from '@mantine/core';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons-react';
import Dropdown from "../components/Dropdown";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { IntlProvider, FormattedMessage, useIntl } from 'react-intl';

const useStyles = createStyles((theme) => ({
  th: {
    padding: '0 !important',
  },
  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },
  icon: {
    width: rem(21),
    height: rem(21),
    borderRadius: rem(21),
  },
  resizeHandle: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '8px',
    zIndex: 1,
    backgroundColor: '#fff',
  },
  resizeOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '8px',
    zIndex: 2,
    opacity: 0,
    cursor: 'col-resize',
  },
}));

function Th({ children, reversed, sorted, onSort }) {
  const { classes } = useStyles();
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position="apart">
          <div> {/* Wrap the contents of the <th> element */}
          <Text fw={500} fz="sm">
            {children}
          </Text>
          {sorted && <Icon size="0.9rem" stroke={1.5} />} 
          </div>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function sortData(data, payload) {
  const { sortBy } = payload;

  let sortedData = [...data];

  if (sortBy) {
    sortedData.sort((a, b) => {
      const valueA = a[sortBy].toString().toLowerCase();
      const valueB = b[sortBy].toString().toLowerCase();

      if (payload.reversed) {
        return valueB.localeCompare(valueA);
      }

      return valueA.localeCompare(valueB);
    });
  }

  return filterData(sortedData, payload.search);
}

function filterData(data, search) {
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    Object.values(item).some((value) =>
      typeof value === "string" && value.toLowerCase().includes(query)
    )
  );
}

export default function MobileMyContract() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.products.userContracts);
  const [open, setOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selected, setSelected] = useState({});
  const [selectedAction, setSelectedAction] = useState(null);
  const [columnWidths, setColumnWidths] = useState({});
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState(data || []);

  useEffect(() => {
    if (data && data.length > 0) {
      setSortedData(data);
    }
  }, [data]);

  useEffect(() => {
    dispatch(contractsByUser());
  }, []);

  const handleSearchChange = (event) => {
    const { value } = event.currentTarget;
    setSearch(value);
    const sorted = sortData(data, { sortBy, reversed: reverseSortDirection, search: value });
    setSortedData(sorted);
  };

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    const sorted = sortData(data, { sortBy: field, reversed, search });
    setSortedData(sorted);
  };

  const actions = [
    { label: <FormattedMessage id="action.operate" />, value: 'view' },
    { label: <FormattedMessage id="action.negotiate" />, value: 'update' },
  ];

  const handleActionChange = (value, rowData) => {
    setSelectedAction(value);

    // Perform action based on the selected value and rowData
    if (value === 'view') {
      setIsUpdate(false);
      setOpen(true);
      setSelected(rowData);
    } else if (value === 'update') {
      setIsUpdate(true);
      setOpen(true);
      setSelected(rowData);
    }
  };

  const getImages = async (value) => {
    const imageUrl = `https://vertoledgerstorage.blob.core.windows.net/sqldbledgerdigests/images/${value.productRefID}.png`;
    const defaultImage= `https://vertoledgerstorage.blob.core.windows.net/sqldbledgerdigests/images/${value.product_name_id}.jpg`; 
    try {
      const response = await fetch(imageUrl);
      if (response.ok) {
        return imageUrl;
      } else {
        return defaultImage;
      }
    } catch (error) {
      return defaultImage;
    }
  };

  const [imageUrls, setImageUrls] = useState({});
  useEffect(() => {
    const fetchImages = async () => {
      const urls = await Promise.all(sortedData.map(async (product) => {
        const url = await getImages(product);
        return { productRefID: product.productRefID, url };
      }));
      setImageUrls(urls.reduce((acc, { productRefID, url }) => ({ ...acc, [productRefID]: url }), {}));
    };
  
    fetchImages();
  }, [sortedData]);  

  const intl = useIntl();
  const renderMobileProducts = () => {
    return sortedData.map((product, index) => (
      <div key={index} style={{ display: 'flex', marginBottom: 5, border: '1px solid #ccc', borderRadius: 8, backgroundColor: 'white' }}>
        <div style={{ width: '20%', position: 'relative', overflow: 'hidden', backgroundColor: 'white' }}>
          {/* Image component */}
          <img
            src={imageUrls[product.productRefID]}
            // alt={product.productName}
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        </div>
        <div style={{ width: '80%', padding: 0, display: 'flex' }}>
          <div style={{ flex: '1 0 0', display: 'flex', flexDirection: 'column', marginRight: "2px", justifyContent: 'center', padding: '1px' }}>
            <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}>{product.productName}</p>
            <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}>{intl.formatMessage({ id:"product.skuid1" })}{product.productSKU}</p>
            <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}>{intl.formatMessage({ id:"product.details1" })}{product.productNotes}</p>
            <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}>{intl.formatMessage({ id:"product.quantity1" })}{product.quantity}</p>
            <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}>{intl.formatMessage({ id:"contract.seller1" })}{product.SellerID}</p>
            <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}>{intl.formatMessage({ id:"contract.buyer1" })}{product.BuyerID}</p>
          </div>
          <div style={{ flex: '1 0 0', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '1px' }}>
            <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}>{intl.formatMessage({ id:"product.state01" })}{product.contractState}</p> 
            <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}>{intl.formatMessage({ id:"product.location1" })}{product.location}</p>
            <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}>{intl.formatMessage({ id:"product.period1" })}{product.period}</p>
            <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}>{intl.formatMessage({ id:"product.price1" })}{product.price}</p>
            <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}>{intl.formatMessage({ id:"contract.TnC01" })}{product.contractDetails}</p>
            {/* <select onChange={(e) => handleActionChange(e.target.value, product)} style={{ width: '100%', fontSize: 'calc(0.5em + 0.5vw)' }}>
                <>
                  <option value="view"><FormattedMessage id="action.operate" /></option>
                  <option value="update"><FormattedMessage id="action.negotiate" /></option>
                </>
            </select> */}
            <div style={{ width: '5px', fontSize: 'calc(0.5em + 0.5vw)' }}>
            <Dropdown
                  actions={actions}
                  handleActionChange={handleActionChange}
                  data={product}
                />
            </div>
          </div>
        </div>
      </div>
    ));
  };  

  return (
    <>
      {/* <ToastContainer
        style={{height: "30px", width: "250px",fontSize: "0.7em", position: "top-center"}}
        position="top-center"
        autoClose= {5000}
        hideProgressBar= {false}
        closeOnClick= {true}
        pauseOnHover= {true}
        draggable= {true}
        progress= {undefined}
        theme="light"
      /> */}
      <UpdateContractModal open={open} toggle={() => setOpen(false)} isUpdate={isUpdate} selected={selected}/>
      <ScrollArea>
      <TextInput
        placeholder={intl.formatMessage({ id:"myprods.search.placeholder" })}
        mb="md"
        icon={<IconSearch size="0.9rem" stroke={1.5} />}
        value={search}
        onChange={handleSearchChange}
        style={{marginTop: '10px', display: 'flex' }}
      />
      {sortedData && sortedData.length > 0 ? renderMobileProducts() : <p><FormattedMessage id="contract.nocontract" /></p>}
      </ScrollArea>
    </>
  );
}
