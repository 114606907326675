import React, { useState } from "react";
import { IntlProvider, FormattedMessage } from "react-intl";

const Dropdown = ({ actions, handleActionChange, handleActionClick, data }) => {
  const [selectedAction, setSelectedAction] = useState("");

  const handleMouseDown = () => {
    setSelectedAction("");
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedAction(value);
    handleActionChange(value, data);
  };

  return (
    <select
      value={selectedAction}
      onChange={handleChange}
      onMouseDown={handleMouseDown}
      // onBlur={handleClick}
      style={{
        maxWidth: "150px",
        color: selectedAction ? "black" : "#aaa",
      }}
    >
      <option value="" style={{ color: "#aaa" }} disabled={!selectedAction}>
        {selectedAction ? selectedAction : <FormattedMessage id="myprods.actionsmenu.default" />}
      </option>
      {actions.map((action) => (
        <option key={action.value} value={action.value}>
          {action.label}
        </option>
      ))}
    </select>
  );
};


export default Dropdown;