import {FormattedMessage, IconFilePdf } from "react-intl";
export const tableConfig = [
  {
    column: <FormattedMessage id="product.upc" />,
    accessor: "UPC",
  },
  /*  {
          column: "Category",
        x  accessor: "catego+ry"
      },
      {
          column: "Sub Category",
          accessor: "subCategory"
      },*/
  {
    column: <FormattedMessage id="product.name" />,
    accessor: "productName",
    defaultValue: "Coffee",
  },
  {
    column: <FormattedMessage id="product.description" />,
    accessor: "notes",
  },
  {
    column: <FormattedMessage id="product.quantity" />,
    accessor: "quantity",
  },

  {
    column: <FormattedMessage id="product.state0" />,
    accessor: "productState",
  },

  {
    column: <FormattedMessage id="product.ownerID" />,
    accessor: "ownerID",
    nonVisible: true,
  },
  {
    column: <FormattedMessage id="product.owner" />,
    accessor: "owner",
  },
  {
    column: <FormattedMessage id="product.dateposted" />,
    type: "date",
    accessor: "actualPeriod",
  },
  {
    column: <FormattedMessage id="product.location" />,
    accessor: "actualLocation",
  },
];

export const PropertyTableConfig = [
  {
    column: <FormattedMessage id="product.lotid" />,
    accessor: "sku",
  },
  {
    column: <FormattedMessage id="product.name0" />,
    accessor: "productName",
  },
  // {
  //   column: <FormattedMessage id="contract.contractid" />,
  //   accessor: "owner_commerical_contract_id",
  //   link: true
  // },
  // {
  //   column: <FormattedMessage id="product.owner" />,
  //   accessor: "ownerID",
  // },
  {
    column: <FormattedMessage id="product.details" />,
    accessor: "productNotes",
  },
  {
    column: <FormattedMessage id="product.quantity" />,
    accessor: "quantity",
  },

  {
    column: <FormattedMessage id="product.location" />,
    accessor: "location",
  },
  {
    column: <FormattedMessage id="product.physicalstate" />,
    accessor: "physicalState",
  },
  {
    column: <FormattedMessage id="product.state0" />,
    accessor: "state",
  },
  {
    column: <FormattedMessage id="myprods.action.loadpdf" />,
    accessor: 'pdfLink',
    type: 'pdf', 
  },
];

export const PropertyContractTableConfig = [
  {
    column: <FormattedMessage id="product.name" />,
    accessor: "productName",
  },
  {
    column: <FormattedMessage id="product.lotid" />,
    accessor: "productSKU",
  },
  {
    column: <FormattedMessage id="contract.contractid" />,
    accessor: "sku",
  },
  {
    column: <FormattedMessage id="contract.seller" />,
    accessor: "SellerID",
  },
  {
    column: <FormattedMessage id="contract.buyer" />,
    accessor: "BuyerID",
  },
  // {
  //   column: <FormattedMessage id="contract.contractid" />,
  //   accessor: "owner_commerical_contract_id",
  //   link: true
  // },
  {
    column: <FormattedMessage id="product.period" />,
    accessor: "period"
  },
  {
    column: <FormattedMessage id="product.quantity" />,
    accessor: "quantity",
  },
  {
    column: <FormattedMessage id="product.price" />,
    accessor: "price",
  },
  {
    column: <FormattedMessage id="product.location" />,
    accessor: "location",
  },
  {
    column: <FormattedMessage id="product.state0" />,
    accessor: "contractState",
  },
];