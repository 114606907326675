import { createSlice } from '@reduxjs/toolkit';
import counterReducer from '../Redux/CounterSlice';

export const counterSlice = createSlice({
    name: 'counter',
    initialState: {
        isSuccess: false,
        fetching: false,
        msg: "",
        data: [
        ]
    },
    reducers: {
        increment: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.value += 1
        },
        decrement: (state) => {
            state.value -= 1
        },
        incrementByAmount: (state, action) => {
            state.value += action.payload
        },
        AddValue: (state, value) => {
            var statVariable = state.data;

            statVariable.push(value.payload);
            state.data = statVariable;
        },
        getProducts: (state) => {
            state.fetching = true;
            state.msg = "";
            state.isSuccess = false;

        },
        saveProductAction: (state) => {
            state.fetching = true;
            state.msg = "";
            state.isSuccess = false;

        },
        getProductsSucess: (state, { payload, type }) => {
            console.log("Result", payload);
            state.data = payload.result;
            state.fetching = false;
            state.msg = "";
            state.isSuccess = true;

        },
        failMsg: (state, { payload, type }) => {
            state.data = [];
            state.fetching = false;
            state.msg = payload;
            state.isSuccess = false;
        },
        deleteValue: (state, value) => {
            console.log("DB");
            var Data = state.data.filter(da => da.upc !== value.payload);
            state.data = Data;

        },
    },
})

// Action creators are generated for each case reducer function
export const {  AddValue, deleteValue,
getProducts,
getProductsSucess,
failMsg,
saveProductAction

} = counterSlice.actions

export default counterSlice.reducer