import { Avatar, Box, Checkbox, FormControlLabel, TextField, Typography, Link, Grid, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { setShowLogin, validateUser, setUserInfo, setRedirectUrl } from "../Redux/user/slice";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import React, { useEffect } from "react";

import { IntlProvider, FormattedMessage } from "react-intl";

export function LoginModal() {
  const dispatch = useDispatch();
  const { showLogin, callBack, invalidUser, redirectUrl, isUserLoggedIn, user, account, isFetching } = useSelector((state) => state.user);
  const userId = user?.userId; // Get the userId value from the user object
  const accountId = account?.accountId;
  const [passwordHelpText, setPasswordHelpText] = React.useState("");
  const [userHelpText, setUserHelpText] = React.useState("");
  const redirectUrlRef = React.useRef(null); // Create a ref to store redir

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (!data.get("email")) {
      setUserHelpText("Please provide a username.");
      return;
    }
    if (!data.get("password")) {
      setPasswordHelpText("Please provide a password.");
      return;
    }

    redirectUrlRef.current = redirectUrl; // Store the redirectUrl in the

    dispatch(
      validateUser({
        email: data.get("email"),
        password: data.get("password"),
        cb: callBack,
      })
    );
  };

  useEffect(() => {
    const redirectUrl = redirectUrlRef.current; // Get the redirectUrl from the ref
    if (redirectUrl && !invalidUser && isUserLoggedIn) {
      // Dispatch the action to set the user info directly
      // dispatch(getUserInfo()); // Dispatch the action to get the user info
      dispatch(
        setUserInfo({
          accountId: account?.accountId,
          userId: user?.userId,
        })  
      );
      
    }
  }, [invalidUser, isUserLoggedIn, dispatch, redirectUrlRef]);

  const handleClose = () => {
    dispatch(setShowLogin({ value: false, cb: null })); // Close the LoginModal by setting showLogin to false
    dispatch(setUserInfo(null)); // Reset the user info
    dispatch(setRedirectUrl({ redirectUrl: "" }));
  };

  return (
    <Modal isOpen={showLogin} style={{ height: "auto" }} toggle={handleClose} backdrop onBackdropClick={handleClose}>    
      <ModalHeader style={{ justifyContent: "center", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255,255,255,0.8)",
            padding: 2,
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            <FormattedMessage id="loginmodal.title" />
          </Typography>
        </Box>
      </ModalHeader>
      <ModalBody>
        <Grid container>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column nowrap",
              width: "100%",
              rowGap: "1em",
            }}
          >
            <Grid item>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={<FormattedMessage id="userInput.email" />}
                name="email"
                autoComplete="email"
                onKeyDown={() => setUserHelpText("")}
                error={userHelpText !== ""}
                helperText={userHelpText}
                autoFocus
                sx={{ margin: "0" }}
                type="email"
              />
            </Grid>
            <Grid item>
              <TextField
                margin="normal"
                helperText={passwordHelpText}
                required
                fullWidth
                name="password"
                label={<FormattedMessage id="userInput.pwd" />}
                type="password"
                onKeyDown={() => setPasswordHelpText("")}
                id="password"
                error={passwordHelpText !== ""}
                autoComplete="current-password"
                sx={{ margin: "0" }}
              />
            </Grid>
            <Grid item>
              <FormControlLabel control={<Checkbox value="remember" color="primary" />} label={<FormattedMessage id="loginmodal.remember" />} />
            </Grid>
            {invalidUser && (
              <Typography
                color={"#f00"}
                sx={{
                  background: "#ff00001a",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {invalidUser}
              </Typography>
            )}
            <Grid item>
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                {isFetching ? <CircularProgress size={24} /> : <FormattedMessage id="button.signin" />}
              </Button>
            </Grid>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  <FormattedMessage id="loginmodal.forgot" />
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </ModalBody>

      <ModalFooter style={{ background: "#061534", color: "white" }}>
        <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <FormattedMessage id="loginmodal.register" />&nbsp;<a style={{ color: "red" }} href="mailto: info@vertocommodities.io">
            <FormattedMessage id="loginmodal.register.click" />
          </a>
        </Grid>
      </ModalFooter>
    </Modal>
  );
}
