export class MockData {



    static getData = () => {
        return this.Data;
    }


    static deleteData = (upc) => {

        this.Data = this.Data.filter(da => da.upc !== upc);

    }
}
