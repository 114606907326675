import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function AssetsBoard({ record }) {


    const { tokens } = record || [] ;
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow sx={{backgroundColor: "rgb(20,20,64)", color: ""}}>
                        <TableCell sx={{color: "white",backgroundColor: "rgb(20,20,64)"}}  align="center">Asset Id</TableCell>
                        <TableCell sx={{color: "white",backgroundColor: "rgb(20,20,64)"}} align="center">Balance</TableCell>
                        <TableCell sx={{color: "white",backgroundColor: "rgb(20,20,64)"}} align="center">Decimals</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {tokens?.map((token) => (
                        <TableRow
                            key={token.tokenId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell sx={{color: "white"}} align="center" component="th" scope="row">
                                {token.tokenId}
                            </TableCell>
                            <TableCell sx={{color: "white"}} align="center">{token.balance}</TableCell>
                            <TableCell sx={{color: "white"}} align="center">{token.decimals}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}