import { Grid, Paper } from "@mui/material";
import LocationDiv from "../components/LocationDiv";
import SignIn from "../components/Login";
import MenuPanel from "../components/MenuPanel";
import TitleDiv from "../components/TitleDiv";
import brandLogo from "./../assets/brand.png";

const style = {
  papaerStyle: {
    height: "100vh",
    width: "100vw",
  },
  gridStyle: {
    // backgroundColor: "rgba(00,00,99,0.75)",
    backgroundColor: "rgb(122 139 164 / 75%)",

    //    backgroundColor: "#371D10",
  },
  addressBar: {
    height: "5vh",
    width: "90vw",
    opacity: 1,
    marginTop: "10px",
    marginRight: "100px",
    marginLeft: "100px",
  },
  menuBar: {
    height: "auto",
    // width: "80vw",
    opacity: 1,
    marginTop: "10px",
    marginRight: "100px",
    marginLeft: "100px",
    textAlign: "center",
  },
  titleBar: {
    height: "25vh",
    opacity: 1,
  },
  dummBar: {
    height: "58vh",

    opacity: 1,
  },
};

const Login = () => {
  return (
    <>
      <Paper sx={style.papaerStyle} className="backgroundImage">
        <Grid container sx={style.gridStyle}>
          <Grid item sx={style.menuBar}>
            <img src={brandLogo} alt="Brand Logo" />
          </Grid>
          <Grid item sx={style.titleBar}>
            <SignIn />
          </Grid>
          <Grid item sx={style.dummBar}></Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Login;
