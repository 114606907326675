import React from "react";
import { Box, Container, Heading, Text, List, ListItem, Link, Divider, ChakraProvider } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

const CookiesPolicy = () => {
  return (
    <ChakraProvider>
      <Container maxW="container.md" py={10}>
        <Heading as="h1" size="2xl" mb={6} textAlign="center">
          <FormattedMessage id="cookiesPolicy.title" />
        </Heading>

        <Text fontSize="sm" color="gray.500" mb={8} textAlign="center">
          <FormattedMessage id="cookiesPolicy.effectiveDate" />
        </Text>

        <Text mb={6}>
          <FormattedMessage
            id="cookiesPolicy.intro"
            values={{
              websiteLink: (
                <Link href="https://vertocommodities.io" color="teal.500" isExternal>
                  https://vertocommodities.io
                </Link>
              ),
            }}
          />
        </Text>

        <Divider my={8} />

        <Heading as="h2" size="lg" mb={4}>
          <FormattedMessage id="cookiesPolicy.section1.title" />
        </Heading>
        <Text mb={6}>
          <FormattedMessage id="cookiesPolicy.section1.content" />
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          <FormattedMessage id="cookiesPolicy.section2.title" />
        </Heading>
        <Text mb={4}>
          <FormattedMessage id="cookiesPolicy.section2.intro" />
        </Text>
        <List spacing={4} pl={4} mb={6}>
          <ListItem>
            <FormattedMessage
              id="cookiesPolicy.section2.listItem1"
              values={{
                boldText: (
                  <Text as="span" fontWeight="bold">
                    <FormattedMessage id="cookiesPolicy.section2.listItem1.boldText" />
                  </Text>
                ),
              }}
            />
          </ListItem>
          <ListItem>
            <FormattedMessage
              id="cookiesPolicy.section2.listItem2"
              values={{
                boldText: (
                  <Text as="span" fontWeight="bold">
                    <FormattedMessage id="cookiesPolicy.section2.listItem2.boldText" />
                  </Text>
                ),
              }}
            />
          </ListItem>
          <ListItem>
            <FormattedMessage
              id="cookiesPolicy.section2.listItem3"
              values={{
                boldText: (
                  <Text as="span" fontWeight="bold">
                    <FormattedMessage id="cookiesPolicy.section2.listItem3.boldText" />
                  </Text>
                ),
              }}
            />
          </ListItem>
          <ListItem>
            <FormattedMessage
              id="cookiesPolicy.section2.listItem4"
              values={{
                boldText: (
                  <Text as="span" fontWeight="bold">
                    <FormattedMessage id="cookiesPolicy.section2.listItem4.boldText" />
                  </Text>
                ),
              }}
            />
          </ListItem>
        </List>

        <Heading as="h2" size="lg" mb={4}>
          <FormattedMessage id="cookiesPolicy.section3.title" />
        </Heading>
        <Text mb={6}>
          <FormattedMessage id="cookiesPolicy.section3.content" />
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          <FormattedMessage id="cookiesPolicy.section4.title" />
        </Heading>
        <Text mb={4}>
          <FormattedMessage id="cookiesPolicy.section4.content1" />
        </Text>
        <Text mb={6}>
          <FormattedMessage id="cookiesPolicy.section4.content2" />
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          <FormattedMessage id="cookiesPolicy.section5.title" />
        </Heading>
        <Text mb={6}>
          <FormattedMessage id="cookiesPolicy.section5.content" />
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          <FormattedMessage id="cookiesPolicy.section6.title" />
        </Heading>
        <Text mb={4}>
          <FormattedMessage id="cookiesPolicy.section6.content" />
        </Text>
        <Box pl={4} borderLeft="4px" borderColor="teal.500">
          <Text fontWeight="bold">Verto SA</Text>
          <Link href="mailto:info@vertocommodities.io" color="teal.500">
            info@vertocommodities.io
          </Link>
        </Box>
      </Container>
    </ChakraProvider>
  );
};

export default CookiesPolicy;
