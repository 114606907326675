import { createSlice } from "@reduxjs/toolkit";

export const OptionsSlice = createSlice({
  name: "options",
  initialState: {
    fetching: false,
    categories: [],
    countries: [],
    currencies: [],
    productNames: []
  },
  reducers: {
    getCategories: (state) => {
    },
    setCategories: (state, { payload, type }) => {
      state.categories = payload;
    },
    getCountries: (state) => {
    },
    setCountries: (state, { payload, type }) => {
      state.countries = payload;
    },
    getCurrencies: (state) => {
    },
    setCurrencies: (state, { payload, type }) => {
      state.currencies = payload;
    },
    getProductNames: (state) => {
    },
    setProductNames: (state, { payload, type }) => {
      state.productNames = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getCategories,
  setCategories,
  getCountries,
  setCountries,
  getCurrencies,
  setCurrencies,
  getProductNames,
  setProductNames
} = OptionsSlice.actions;

export default OptionsSlice.reducer;
