/* eslint-disable jsx-a11y/iframe-has-title */
import { Grid, Paper } from "@mui/material";
import MobileMenuPanel from "./MobileMenuPanel";
import {
  CURRENT_PURPOSE,
  WEBSITE_REAL_ESTATE,
} from "../configs/website-config";
import MobileSecondFooter from "./MobileSecondFooter";
import MobileFooter from "./MobileFooter";
import { LoginModal } from "./LoginModal";
import { useState } from "react";
import ProductCertificate from "./ProductCertificate";

const backgroundImage =
  CURRENT_PURPOSE === WEBSITE_REAL_ESTATE
    ? "../assets/bg.jpeg"
    : "../assets/summer.png";

const style = {
  papaerStyle: {
    height: "100%",
    width: "100%",
    backgroundImage: "url('" + backgroundImage + "')",
  },
  gridStyle: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    backgroundColor: "rgb(122 139 164 / 75%)",
  },
  menuBar: {
    // height: "120px",
    width: "100%",
    opacity: 1,
  },
  whole: {
    // width: "100%",
    // position: "fixed",
    justifyContent: "center",
  },
  iframe: {
    width: "100%",
    height: `calc(100vh - 100px)`
  },
  explorer: {
    justifyContent: "center",
    alignItems: "center",
    minHeight: "1000px",
    width: "100%",
    padding: '5px', // Adjust padding as needed
    boxSizing: 'border-box', // Include padding in total width
  }
};

const TransactionExplorer = () => {

  return (
    <>
      <div className="whole" style={style.whole}>
        {/* <Paper sx={style.papaerStyle} className="backgroundImage"> */}
        <MobileMenuPanel level={1}/>
        <div className="ex" style={{ ...style.explorer, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <ProductCertificate />
        </div>
        <MobileSecondFooter />
        <MobileFooter />
      </div>
      <LoginModal/>
    </>
  );
};

export default TransactionExplorer;
