import { CURRENT_PURPOSE, WEBSITE_FARM_TRADE } from "./website-config";

const RealEstate = [
  {
    name: "My workspace",
    url: "/my-list",
    enableAuthentication: true,
  },
  {
    name: "Marketplace",
    url: "/marketplace",
  },
  {
    name: "Transaction Explorer",
    url: "/transaction-explorer",
  },
  {
    name: "Verto GPT",
    url: "/gpt",
  },
];

export const MobileMenuConfig = [
  {
    name: "Home",
    url: "/",
    enableAuthentication: true,
  },
  {
    name: "My workspace",
    url: "/my-list",
    enableAuthentication: true,
  },
  {
    name: "Marketplace",
    url: "/marketplace",
  },
  {
    name: "Transaction Explorer",
    url: "/transaction-explorer",
  },
  {
    name: "Verto GPT",
    url: "/gpt",
  },
];

export const MenuConfig = RealEstate;