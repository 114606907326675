import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getFetchLedgerLot, getFetchLedgerLotHistory } from "../Redux/products/slice";
import { BASE_URL } from '../Redux/apiConfig';
import { Timeline, Text, Title, Center, Button, Loader } from '@mantine/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Prism } from '@mantine/prism';
import jsPDF from "jspdf";
import styled from 'styled-components';
import logoImage from '../../src/assets/Logo.png';
import { FaSearch } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDolly } from '@fortawesome/free-solid-svg-icons';

const Row = ({ children }) => (
  <div className="row" style={{ alignItems: "center", justifyContent: "center", marginTop: "40px" }}>{children}</div>
);

const Column = ({ children }) => (
  <div className="col" style={{ alignItems: "center", justifyContent: "center" }}>{children}</div>
);

const ProductState = ({ product }) => (
  <Prism style={{ maxHeight: "none", padding: "10px", overflow: "auto" }} colorScheme="dark" language="json">
    {JSON.stringify(product, null, 2)}
  </Prism>
);

function ProductTimeline({ productHist }) {
    const sortedItems = Object.entries(productHist)
      .sort(([linkA, itemA], [linkB, itemB]) => {
        // Get the dates for comparison
        let dateA;
        let dateB;
  
        // switch (itemA.method) {
        //   case 'transform':
        //     dateA = itemA[1];
        //     dateB = itemB[1];
        //     break;
        //   case 'transport':
        //     dateA = itemA[0];
        //     dateB = itemB[0];
        //     break;
        //   case 'sell':
        //     dateA = itemA[1];
        //     dateB = itemB[1];
        //     break;
        //   default:
        //     // Handle the default case if needed
        //     dateA = itemA["link"];
        //     dateB = itemB["link"];
        // }
        // temporary
        dateA = itemA["link"];
        dateB = itemB["link"];
  
        // Compare the dates
        const dateComparison = dateA?.localeCompare(dateB);
  
        if (dateComparison === 0) {
          // If dates are equal, compare timestamps
          return itemA["link"].localeCompare(itemB["link"]);
        }
  
        // Return the result of the date comparison
        return dateComparison;
      })
      .map(([timestamp, item]) => {
        // Customize the output based on the method name
        let description, title;
        switch (item.method) {
          case 'transform':
            // var title = item.method.charAt(0).toUpperCase() + item.method.slice(1);
            title = <FormattedMessage id="explorer.timeline.transformation" />;
            // Modify the title or description for 'transform' method
            description = (
              <>
                <FormattedMessage id="explorer.timeline.process" /> {item[0]}
                <br />
                <FormattedMessage id="product.period1" /> {item[1]}
                <br />
                <FormattedMessage id="product.location1" /> {item[2]}
              </>
            );
            break;
          case 'transport':
            title = <FormattedMessage id="explorer.timeline.transport" />;
            // Modify the title or description for 'transport' method
            description = (
              <>
                <FormattedMessage id="product.period1" /> {item[0]}
                <br />
                <FormattedMessage id="product.location1" /> {item[1]}
              </>
            );
            break;
          case 'sell':
            title = <FormattedMessage id="explorer.timeline.sell" />;
            // Modify the title or description for 'sell' method
            description = <FormattedMessage id="explorer.timeline.prodforsale" />;
            break;
          default:
            // Handle the default case if needed
            title = <FormattedMessage id="explorer.timeline.operation" />;
            description = (           
            <>
            <FormattedMessage id="explorer.timeline.newOwneradr" /> {item[0]}
            </>);
        }
  
        return {
          timestamp,
          title,
          description
        };
      });
  
      return (
        <Timeline active={0} bulletSize={20} lineWidth={2}>
          {sortedItems.map((item, index) => (
            <Timeline.Item
              key={index}
              bullet={<FontAwesomeIcon icon={faDolly} />}
              title={item.title}
            >
              <Text color="dimmed" size="sm">
                {item.description}
              </Text>
            </Timeline.Item>
          ))}
        </Timeline>
      );
    }  

const CertificatePage = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [productDict, setProductDict] = useState({});
  const [productHist, setProductHist] = useState({});
  const dispatch = useDispatch();
  const fetchLedgerLot = useSelector((state) => state.products.fetchLedgerLot);
  const fetchLedgerLotHistory = useSelector((state) => state.products.fetchLedgerLotHistory);
  const intl = useIntl();
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await dispatch(getFetchLedgerLot({ inputValue: id }));
        setProductDict(fetchLedgerLot);

        await dispatch(getFetchLedgerLotHistory({ inputValue: id }));
        setProductHist(fetchLedgerLotHistory);

        if (fetchLedgerLot.result && fetchLedgerLot.result.ProductID) {
          const qrCodeUrl = `https://vertoledgerstorage.blob.core.windows.net/sqldbledgerdigests/qrcode/${fetchLedgerLot.result.ProductID}.png`;
          setQrCodeImageUrl(qrCodeUrl);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, id, fetchLedgerLot]);

  return (
    <div style={{ padding: '30px' }}>
      <Title order={3} weight={200} align="center">
        <FormattedMessage id="explorer.title" />
      </Title>
      <Center>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: "20px" }}>
          {qrCodeImageUrl && (
            <img src={qrCodeImageUrl} alt="QR Code" style={{ maxWidth: '100%' }} />
          )}
          <Column>
            <Title order={3} weight={200} align="center" style={{ marginTop: "20px", marginBottom: "30px"  }}>
              <FormattedMessage id="explorer.section.pinfo" />
            </Title>
            <ProductState product={productDict} style={{ marginTop: "20px"}}/>
          </Column>
          <Column>
            <Title order={3} weight={200} align="center" style={{ marginTop: "20px", marginBottom: "30px"  }}>
              <FormattedMessage id="explorer.section.phistory" />
            </Title>
            {isLoading ? (
              <Loader />
            ) : (
            <Column>
              <ProductTimeline productHist={productHist} style={{ marginTop: "20px", marginBottom: "20px" }}/>
            </Column>
            )}
          </Column>
        </div>
      </Center>
    </div>
  );
};

// You may want to implement ProductTimeline similarly to how it was done in ProductCertificate

export default CertificatePage;