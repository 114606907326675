import { BASE_URL } from '../apiConfig';

export const validateUserAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    `${BASE_URL}/validateUser`, 
    // "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/validateuser",
    options
  ).then((res) => res.json());
};

export const getuserinfoAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    `${BASE_URL}/getuserInfo`, 
    // "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/getuserinfo",
    options
  ).then((res) => res.json());
};

export const fetchUserDetailsApi = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    `${BASE_URL}/getUserFullDetails`, 
    options
  ).then((res) => res.json());
};

export const addFieldAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    `${BASE_URL}/addField`, 
    options
  ).then((res) => res.json());
};

export const getFieldAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    `${BASE_URL}/getField`, 
    options
  ).then((res) => res.json());
};