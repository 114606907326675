
export const getProductApiCall = async () => {
  let options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'abc123',
      'apikey': 'abc123',
    },
  }
  return await fetch("https://s4kyjm76vdmpjod2vui3cz7zxm0jbxbh.lambda-url.ap-southeast-1.on.aws/getallproducts", options)
    .then(res => res.json())
}


export const InsertProduct = async (productDetails) => {

  var body = JSON.stringify(productDetails);
  console.log("request", body);

  let options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'abc123',
      'apikey': 'abc123',
    },
    body: body
  }
  return await fetch("https://s4kyjm76vdmpjod2vui3cz7zxm0jbxbh.lambda-url.ap-southeast-1.on.aws/insertproduct", options)
    .then(res => res.json())
}


