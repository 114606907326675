import {
  AddCircleRounded,
  ArrowLeft,
  DeleteForever,
  Publish,
} from "@mui/icons-material";
import {
  Backdrop,
  Button,
  CircularProgress,
  Fab,
  Grid,
  IconButton,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { tableConfig } from "../components/mockData";
import { Data, MockData } from "../configs/mockData";
import "../pages/HomePage.css";
import {
  AddValue,
  deleteValue,
  getProducts,
  saveProductAction,
} from "../Redux/CounterSlice";
import { useSelector, useDispatch } from "react-redux";
import BasicModal from "../components/CreateProductModal";
import FormDialog from "../components/CreateProductModal";
import Details from "../components/DetailsList";
import DetailModal from "../components/DetailsList";
import moment from "moment";
import { saveProperties } from "../Redux/properties/PropertiesSlice";
import { getProductStateList } from "../Redux/products/slice";

const style = {
  papaerStyle: {
    height: "100vh",
    width: "100vw",
  },
  gridStyle: {
    // backgroundColor: "rgba(00,00,99,0.75)",
    backgroundColor: "rgb(122 139 164 / 75%)",
  },
};

const ProductList = () => {
  const data12 = useSelector((state) => state.counter.data);
  const props = useSelector((state) => state.counter);
  const prodStore = useSelector((state) => state.products);

  const dispatch = useDispatch();

  let { level } = useParams();

  const [modal, setModal] = useState(false);

  const [mockData, setMockData] = useState([]);
  const [fetched, setFetched] = useState(false);

  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    if (!fetched) {
      setFetched(true);
      loadDetails();
    }
  }, [fetched]);

  const loadDetails = async () => {
    dispatch(getProducts());
  };

  const [selectedValue, setSelectedValue] = useState();

  const handleChange = (item) => {
    setSelectedValue(item);
  };

  const controlProps = (item) => ({
    checked: selectedValue?.index === item.index,
    onChange: () => handleChange(item),
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const publish = (data) => {
    var obj = {
      ...data,
      publish: data["publish"] === "Y" ? "N" : "Y",
    };

    dispatch(saveProperties({ payload: obj }));
  };

  const onSave = (obj) => {
    dispatch(saveProperties({ payload: obj }));
  };

  const handleTransfer = () => {};

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.fetching || prodStore.fetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FormDialog
        open={modal}
        handleClose={() => setModal(false)}
        onSave={(obj) => onSave(obj)}
      >
        {" "}
      </FormDialog>
      <DetailModal
        show={show}
        data={selectedData || {}}
        handleclose={() => setShow(false)}
      />
      <Paper sx={style.papaerStyle}>
        <Grid container sx={style.gridStyle}>
          <Grid item sx={{ height: "10%" }}>
            {/* {(props.fetching || prodStore.fetching) && ( */}
            {/* <CircularProgress sx={{ color: "white" }} /> */}
            {/* )} */}
            {(!props.fetching || !prodStore.fetching) && (
              <IconButton onClick={() => window.location.assign("/")}>
                <ArrowLeft fontSize="large" sx={{ color: "white" }} />
              </IconButton>
            )}
          </Grid>
          <Grid sx={{ height: "500px", overflow: "auto" }}>
            <Table>
              <TableHead sx={{ opacity: "1" }}>
                <TableRow>
                  <TableCell> Action</TableCell>
                  {tableConfig.map((data) => (
                    <TableCell sx={data.nonVisible && { display: "none" }}>
                      {data.column}{" "}
                    </TableCell>
                  ))}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data12.map((data, index) => {
                  return (
                    <TableRow>
                      <TableCell>
                        <Radio {...controlProps({ index, data })} />
                      </TableCell>
                      {tableConfig.map((data1) => {
                        return (
                          <TableCell
                            sx={
                              data1.nonVisible
                                ? { color: "white", display: "none" }
                                : { color: "white" }
                            }
                            onDoubleClick={() => {
                              setShow(true);
                              setSelectedData(data);
                            }}
                          >
                            {(data1.type &&
                              data1.type === "date" &&
                              moment(data[data1["accessor"]]).format(
                                "DD-MM-yyyy"
                              )) ||
                              ""}
                            {(!data1.type && data[data1["accessor"]]) ||
                              data1.defaultValue}
                          </TableCell>
                        );
                      })}
                      <TableCell>
                        <Button
                          variant={"contained"}
                          sx={{ width: "150px" }}
                          onClick={() => publish(data)}
                        >
                          {data["publish"] !== "Y" && "Publish"}
                          {data["publish"] === "Y" && "Un-Publish"}
                        </Button>
                        <IconButton
                          onClick={() => dispatch(deleteValue(data["upc"]))}
                        >
                          <DeleteForever sx={{ color: "white" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>

              {/* <TableFooter> */}

              {/* </TableFooter> */}
            </Table>
            <Fab
              sx={{
                float: "right",
                position: "absolute",
                right: "2%",
                top: "4%",
              }}
              onClick={() => setModal(true)}
              color="primary"
              aria-label="add"
            >
              <AddCircleRounded />
            </Fab>
            <Button
              variant="contained"
              sx={{
                float: "right",
                position: "absolute",
                right: "12%",
                top: "4%",
              }}
              disabled={!selectedValue}
              onClick={handleTransfer}
            >
              Tranfer
            </Button>
            <Button
              variant="contained"
              sx={{
                float: "right",
                position: "absolute",
                right: "20%",
                top: "4%",
              }}
              disabled={!selectedValue}
            >
              Transport
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Details />
    </>
  );
};

export default ProductList;
