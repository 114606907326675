import { all } from "@redux-saga/core/effects";
import productSaga from "./Productsaga";
import PropertySaga from "./properties/PropertiesSaga";
import productsSaga from "./products/sagas";
import UserSaga from "./user/saga";
import OptionsaSaga from "./options/sagas";

export default function* rootSaga() {
  yield all([productSaga(), PropertySaga(), productsSaga(), UserSaga(), OptionsaSaga()]);
}
