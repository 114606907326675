import { BASE_URL } from '../apiConfig';

export const getCategoriesListAPI = async (language) => {
  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
  };
  return await fetch(
    `${BASE_URL}/getCategoryList?language=${language}`, 
    // "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/getcategory",
    options
  ).then((res) => res.json());
};

export const getCountriesListAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/getcountries",
    options
  ).then((res) => res.json());
};

export const getCurrenciesListAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/getcurrencies",
    options
  ).then((res) => res.json());
};

export const getProductNamesAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",

    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/getproductstatebyupc",
    options
  ).then((res) => res.json());
};