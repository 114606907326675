import { CircularProgress, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  removeSuccessMessage,
  transferNFT,
} from "../Redux/properties/PropertiesSlice";
import SuccessMSGModel from "./SuccessMessage";

function BuyConfirmationModal({
  toggle,
  modal,
  buyerID,
  SellerID,
  buyerName,
  sellerName,
  value,
}) {
  const realEstateData = useSelector((state) => state.properties);
  const dispatch = useDispatch();

  const save = () => {
    dispatch(transferNFT(value));
  };

  return (
    <div>
      {realEstateData.transferedNFT && (
        <SuccessMSGModel
          modal={true}
          value={value.price}
          toggle={() => {
            dispatch(removeSuccessMessage());
            toggle();
          }}
          title={value.title + "(" + value.NFT_TokenId + ")"}
        />
      )}
      <Modal isOpen={modal} toggle={toggle} style={{ height: "auto" }}>
        <ModalHeader toggle={toggle}>{"Buy confirmation"}</ModalHeader>
        <ModalBody>
          <Grid container>
            {"Are you sure you want to buy" +
              " " +
              value?.title +
              " for " +
              value?.price +
              " HBAR ?"}
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => save()}>
            {realEstateData.fetching ? (
              <CircularProgress sx={{ color: "white" }} />
            ) : (
              "Buy"
            )}
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default BuyConfirmationModal;
