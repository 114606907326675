import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mantine/core";

import { IntlProvider, FormattedMessage } from "react-intl";

const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
  };

const titleStyle = {
    padding: "20px",
    margin: "20px auto",
    textAlign: "center", // Add textAlign: "center" to center the text
};

const EthicsPage = () => {
  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>
        <FormattedMessage id="ethics.title" />
      </h1>
      <p>
        <FormattedMessage id="ethics.para1" />
      </p>
      <ol>
        <li>
          <FormattedMessage id="ethics.item1" />
          <ul>
            <li>
            <FormattedMessage id="ethics.item1.bullet1" />
            </li>
            <li>
            <FormattedMessage id="ethics.item1.bullet2" />
            </li>
          </ul>
        </li>
        <li>
          <FormattedMessage id="ethics.item2" />
          <ul>
            <li>
            <FormattedMessage id="ethics.item2.bullet1" />
            </li>
            <li>
            <FormattedMessage id="ethics.item2.bullet2" />
            </li>
          </ul>
        </li>
        <li>
          <FormattedMessage id="ethics.item3" />
          <ul>
            <li>
            <FormattedMessage id="ethics.item3.bullet1" />
            </li>
            <li>
            <FormattedMessage id="ethics.item3.bullet2" />
            </li>
          </ul>
        </li>
        <li>
          <FormattedMessage id="ethics.item4" />
          <ul>
            <li>
            <FormattedMessage id="ethics.item4.bullet1" />
            </li>
            <li>
            <FormattedMessage id="ethics.item4.bullet2" />
            </li>
            <li>
            <FormattedMessage id="ethics.item4.bullet3" />
            </li>
          </ul>
        </li>
        <li>
          <FormattedMessage id="ethics.item5" />
          <ul>
            <li>
            <FormattedMessage id="ethics.item5.bullet1" />
            </li>
            <li>
            <FormattedMessage id="ethics.item5.bullet2" />
            </li>
          </ul>
        </li>
        <li>
          <FormattedMessage id="ethics.item6" />
          <ul>
            <li>
            <FormattedMessage id="ethics.item6.bullet1" />
            </li>
            <li>
            <FormattedMessage id="ethics.item6.bullet2" />
            </li>
          </ul>
        </li>
        <li>
          <FormattedMessage id="ethics.item7" />
          <ul>
            <li>
            <FormattedMessage id="ethics.item7.bullet1" />
            </li>
            <li>
            <FormattedMessage id="ethics.item7.bullet2" />
            </li>
          </ul>
        </li>
      </ol>
      <p>
        <FormattedMessage id="ethics.para2" />
      </p>
      <Link to="/">
        <Button
          variant="outline"
          color="blue"
          style={{ marginTop: "20px", marginBottom: "50px" }}
        >
          <FormattedMessage id="button.backhome" />
        </Button>
      </Link>
    </div>
  );
};

export default EthicsPage;
