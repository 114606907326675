import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoginModal } from "../components/LoginModal";

export const ProtectedRoute = ({ children }) => {
  const getUser = localStorage.getItem("userLogged");

  if (!getUser) {
    window.location.assign("/");
  }
  return <>{children}</>;
};
