import {
  delay,
  put,
  takeEvery,
  Effect,
  ForkEffect,
  call,
} from "redux-saga/effects";
import {
  CreateNFTAPI,
  SaveProduct,
  SavePropertiesAPI,
  SaveDraftPropertiesAPI,
  DeleteDraftPropertiesAPI,
  loadLotImageAPI,
  associateTokenAPI,
  getAccountIdApi,
  getAllProperties,
  getRecentTransactionAPI,
  getWalletBalance,
  transferTokenApi,
  createContractAPI,
  sellerSignAPI,
  buyerSignAPI,
  createCommercialContractAPI,
  signUpFormApi,
} from "./PropertiesAPI";
import {
  failMsg,
  fetchRecentTransaction,
  fetchRecentTransactionSuccess,
  fetchUserAccount,
  fetchUserAccountSuccess,
  getPropertiesReducer,
  getPropertiesSuccess,
  saveProperties,
  saveDraftProperties,
  deleteDraftProperties,
  loadLotImage,
  transferNFT,
  transferNFTSuccess,
  walletBalanceFetch,
  walletBalanceFetchFailed,
  walletBalanceFetchSuccess,
  createContract,
  createCommercialContract,
  sellerSign,
  buyerSign,
  createContractSuccess,
  createCommercialContractSuccess,
  sellerSignSuccess,
  buyerSignSuccess,
  setFetching,
  signUpForm,
} from "./PropertiesSlice";
import { toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import 'react-toastify/dist/ReactToastify.css';
import { getProductsListByUserS } from "../products/sagas";
import { ToastProperties } from "../../utils/constants/toastProperties";
import { FormattedMessage } from "react-intl";

const showToast = (type, message) => {
  // Use the toast function to display the appropriate toast message
  if (type === 'success') {
    toast.success(message, {
      ...ToastProperties
    });
  } else if (type === 'error') {
    toast.error(message, {
      ...ToastProperties
    });
  }
};

export function* getPropertiesList() {
  const storedLanguage = localStorage.getItem("language");
  const targetLanguage = storedLanguage ? storedLanguage.replace(/["']/g, '') : "en";
  try {
    var result = yield call(getAllProperties, targetLanguage);
    yield put(getPropertiesSuccess(result));
  } catch (error) {
    console.log("API ERROR", error);
    yield put(failMsg(error));
  }
  yield put(setFetching(false))
}

export function* getWalletBalanceSaga() {
  try {
    var result = yield call(getWalletBalance);
    yield put(walletBalanceFetchSuccess(result));
  } catch (error) {
    console.log("API ERROR", error);
    yield put(walletBalanceFetchFailed(error));
  }
  yield put(setFetching(false))
}

export function* getAccountId({ payload, type }) {
  try {
    var result = yield call(getAccountIdApi, payload);
    yield put(fetchUserAccountSuccess(result));
  } catch (error) {
    console.log("API ERROR", error);
    yield put(failMsg(error));
  }
  yield put(setFetching(false))
}

export function* getRecentTransaction({ payload, type }) {
  try {
    var result = yield call(getRecentTransactionAPI, payload);
    console.log("rest", result);
    yield put(fetchRecentTransactionSuccess(result));
  } catch (error) {
    console.log("API ERROR", error);
    yield put(failMsg(error));
  }
  yield put(setFetching(false))
}

export function* getTransferSaga({ payload, type }) {
  try {
    var asssociatePayload = {
      tokenIds: [payload.NFT_TokenId],
      accountUserId: "testbuyer1",
    };

    var result = yield call(associateTokenAPI, asssociatePayload);

    var transferPayload = {
      tokenId: payload.NFT_TokenId,
      fromUserId: "testseller2",
      toUserId: "testbuyer1",
      nftId: "1@" + payload.NFT_TokenId,
      amount: payload.price,
    };

    var result1 = yield call(transferTokenApi, transferPayload);
    yield put(fetchUserAccount("testbuyer1"));

    yield put(transferNFTSuccess());
  } catch (error) {
    console.log("API ERROR", error);
    yield put(failMsg(error));
  }
  yield put(setFetching(false))
}

export function* saveProperty({ payload, type }) {
  const { payload: reqPayload, cb } = payload;
  let result; // Declare the result variable

  try {
    result = yield call(SavePropertiesAPI, reqPayload); // Capture the result here

    const { payload, status, sku, productID } = result;

    if (status === "Success") {
      yield showToast('success',
        <>
          <FormattedMessage id="toast.product.created.success" />
        </>);
    } else if (result.error && result.error === "Unauthorized: Invalid token") {
      yield showToast('error', <FormattedMessage id="toast.expiredtoken" />);
    } else if (result.error) {
      yield showToast('error',
        <>
          {<FormattedMessage id="toast.product.created.error" />}: {result.error}
        </>);
    }

    // Now, update the state
    yield call(getProductsListByUserS);

    // Set fetching to false
    yield put(setFetching(false));

  } catch (error) {
    console.log("API ERROR", error);
    yield put(failMsg(error));
  }

  if (cb) {
    cb();
    yield put(getPropertiesReducer());
  }
}

export function* saveDraftProperty({ payload, type }) {
  const { payload: reqPayload, cb } = payload;
  let result; // Declare the result variable
  try {

    result = yield call(SaveDraftPropertiesAPI, reqPayload); // Capture the result here

    if (result.payload === "Success") {
      yield showToast('success',
        <>
          <FormattedMessage id="toast.productdraft.created.success" />
        </>);
    } else if (result.error && result.error === "Unauthorized: Invalid token") {
      yield showToast('error',
        <>
          <FormattedMessage id="toast.expiredtoken" />
        </>);
    } else if (result.error) {
      yield showToast('error',
        <>
          {<FormattedMessage id="toast.product.created.error" />}: {result.error}
        </>);
    }

    yield call(getProductsListByUserS)

  } catch (error) {
    console.log("API ERROR", error);
    yield put(failMsg(error));
  }
  if (cb) {
    cb();
    yield put(getPropertiesReducer());
  }
  yield put(setFetching(false))
}

export function* deleteDraftProperty({ payload, type }) {
  // console.log("something", { payload });
  const { payload: reqPayload, cb } = payload;
  try {

    const result = yield call(
      DeleteDraftPropertiesAPI,
      payload,
    );

    if (result.payload === "Product lot updated successfully") {
      yield showToast('success',
        <>
          {<FormattedMessage id="toast.productdraft.delete.success" />}
        </>);
    } else if (result.payload && result.payload === "Unauthorized: Invalid token") {
      yield showToast('error',
        <>
          <FormattedMessage id="toast.expiredtoken" />
        </>);
    } else if (result.error) {
      yield showToast('error',
        <>
          {<FormattedMessage id="toast.productdraft.delete.error" />}: {result.payload}
        </>
      );
    }
    yield call(getProductsListByUserS)

  } catch (error) {
    console.log("API ERROR", error);
    yield put(failMsg(error));
  }
  if (cb) {
    cb();
    yield put(getPropertiesReducer());
  }
  yield put(setFetching(false))
}

export function* loadImageProperty({ payload, type }) {
  // console.log("something", { payload });
  const { payload: reqPayload, cb } = payload;
  try {

    var { status, message, sku, productID, error } = yield call(
      loadLotImageAPI,
      payload,
    );

    if (message === "Image uploaded successfully") {
      yield showToast('success',
        <>
          {<FormattedMessage id="toast.imageload.success" />}
        </>
      );
    } else if (error && error === "Unauthorized: Invalid token") {
      yield showToast('error',
        <>
          <FormattedMessage id="toast.expiredtoken" />
        </>
      );
    } else if (error) {
      yield showToast('error',
        <>
          {<FormattedMessage id="toast.imageload.error" />}: {error}
        </>
      );
    }
    yield call(getProductsListByUserS)

  } catch (error) {
    console.log("API ERROR", error);
    yield put(failMsg(error));
  }
  if (cb) {
    cb();
    yield put(getPropertiesReducer());
  }
  yield put(setFetching(false))
}

export function* createContractTransaction({ payload, type }) {
  try {
    var result = yield call(createContractAPI, payload);
    console.log("rest", result);
    yield put(createContractSuccess(result));
  } catch (error) {
    console.log("API ERROR", error);
    yield put(failMsg(error));
  }
  yield put(setFetching(false))
}

export function* createCommericalContractTransaction({ payload, type }) {
  const { payload: reqPayload, cb } = payload;
  try {
    var result = yield call(createCommercialContractAPI, reqPayload);
    if (result.status === "Success") {
      yield put(createCommercialContractSuccess(result));
      yield showToast('success', <FormattedMessage id="toast.contract.contractcreation.success" />);
      yield cb();
    } else {
      yield showToast('error', <FormattedMessage id="toast.contract.contractcreation.error" />);
      yield cb();
  }
  } catch (error) {
    yield showToast('error', <FormattedMessage id="toast.contract.contractcreation.error" />);
  }
  yield put(setFetching(false))
}

export function* sellerSignTransaction({ payload, type }) {
  const { payload: reqPayload, cb } = payload;
  try {
    var result = yield call(sellerSignAPI, reqPayload);
    if (result.status === "Success") {
      yield put(sellerSignSuccess(result));
      yield showToast('success', <FormattedMessage id="toast.contract.sellersigned.success" />);
      yield cb();
    } else {
      yield showToast('error', <FormattedMessage id="toast.contract.sellersigned.error" />);
      yield cb();
    }  
  } catch (error) {
    yield showToast('error', <FormattedMessage id="toast.signup.error" />);
  }
  yield put(setFetching(false))
}

export function* buyerSignTransaction({ payload, type }) {
  const { payload: reqPayload, cb } = payload;
  try {
    var result = yield call(buyerSignAPI, reqPayload);
    if (result.status === "Success") {
      yield put(buyerSignSuccess(result));
      yield showToast('success', <FormattedMessage id="toast.contract.buyersigned.success" />);
      yield cb();
    } else {
      yield showToast('error', <FormattedMessage id="toast.contract.buyersigned.error" />);
      yield cb();
    } 
  } catch (error) {
    yield put(setFetching(false))
  }
}

export function* setSignUpForm({ payload, type }) {
  try {
    var result = yield call(signUpFormApi, payload);
    if (result.status === "Success") {
      yield showToast('success', <FormattedMessage id="toast.signup.success" />);
    }
  } catch (error) {
    yield showToast('error', <FormattedMessage id="toast.signup.error" />);
  }
  yield put(setFetching(false))
}

function* PropertySagaLoc() {
  yield takeEvery(getPropertiesReducer, getPropertiesList);
  yield takeEvery(walletBalanceFetch, getWalletBalanceSaga);
  yield takeEvery(saveProperties, saveProperty);
  yield takeEvery(saveDraftProperties, saveDraftProperty);
  yield takeEvery(deleteDraftProperties, deleteDraftProperty);
  yield takeEvery(loadLotImage, loadImageProperty);
  yield takeEvery(transferNFT, getTransferSaga);
  yield takeEvery(fetchUserAccount, getAccountId);
  yield takeEvery(fetchRecentTransaction, getRecentTransaction);
  yield takeEvery(createContract, createContractTransaction);
  yield takeEvery(
    createCommercialContract,
    createCommericalContractTransaction
  );
  yield takeEvery(sellerSign, sellerSignTransaction);
  yield takeEvery(buyerSign, buyerSignTransaction);
  yield takeEvery(signUpForm, setSignUpForm);
}

const PropertySaga = PropertySagaLoc;

export default PropertySaga;
