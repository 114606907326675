import React from "react";
import { Link } from "react-router-dom";

import { IntlProvider, FormattedMessage } from "react-intl";

const containerStyle = {
  padding: "20px",
  margin: "20px auto",
  maxWidth: "800px",
};

const titleStyle = {
  textAlign: "center",
  padding: "20px",
  margin: "20px auto",
};

const contactLinkStyle = {
  display: "block",
  textAlign: "center",
  marginTop: "20px",
};

const backButtonStyle = {
  display: "block",
  margin: "20px auto",
  textAlign: "center",
};

const PrivacyPage = () => {
  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>
         <FormattedMessage id="privacy.title" />
      </h1>
      <p>
        <FormattedMessage id="privacy.para1" />
      </p>
      <p>
        <ol>
          <li>        
            <FormattedMessage id="privacy.item1" />
          </li>
          <li>
            <FormattedMessage id="privacy.item2" />
         </li>
         <li>
            <FormattedMessage id="privacy.item3" />
         </li>
         <li>
            <FormattedMessage id="privacy.item4" />
         </li>
         <li>
            <FormattedMessage id="privacy.item5" />
         </li>
         <li>
            <FormattedMessage id="privacy.item6" />
         </li>
         </ol>
      </p>
      <p>
        <FormattedMessage id="privacy.para2" />
        {" "}
        <a href="mailto:info@vertocommodities.io">info@vertocommodities.io</a>.
      </p>
      <div style={contactLinkStyle}>
        <Link to="/" className="btn btn-secondary">
        <FormattedMessage id="button.backhome" />
        </Link>
      </div>
    </div>
  );
};

export default PrivacyPage;