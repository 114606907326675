import {
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { contractsByUser } from "../Redux/products/slice";
import UpdateContractModal from "./UpdateContract";
import { PropertyContractTableConfig } from "../components/mockData";
import moment from "moment";
import {   Select, createStyles, Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  rem, } from '@mantine/core';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons-react';
import Dropdown from "../components/Dropdown";

import { IntlProvider, FormattedMessage, useIntl } from 'react-intl';

const useStyles = createStyles((theme) => ({
  th: {
    padding: '0 !important',
  },
  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },
  icon: {
    width: rem(21),
    height: rem(21),
    borderRadius: rem(21),
  },
  resizeHandle: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '8px',
    zIndex: 1,
    backgroundColor: '#fff',
  },
  resizeOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '8px',
    zIndex: 2,
    opacity: 0,
    cursor: 'col-resize',
  },
}));

function Th({ children, reversed, sorted, onSort }) {
  const { classes } = useStyles();
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position="apart">
          <div> {/* Wrap the contents of the <th> element */}
          <Text fw={500} fz="sm">
            {children}
          </Text>
          {sorted && <Icon size="0.9rem" stroke={1.5} />} 
          </div>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function sortData(data, payload) {
  const { sortBy } = payload;

  let sortedData = [...data];

  if (sortBy) {
    sortedData.sort((a, b) => {
      const valueA = typeof a[sortBy] === 'number'
        ? a[sortBy]
        : (a[sortBy] || '').toString().toLowerCase();

      const valueB = typeof b[sortBy] === 'number'
        ? b[sortBy]
        : (b[sortBy] || '').toString().toLowerCase();

      if (payload.reversed) {
        return valueB > valueA ? 1 : valueB < valueA ? -1 : 0;
      }

      return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
    });
  }

  return filterData(sortedData, payload.search);
}

function filterData(data, search) {
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    Object.values(item).some((value) => {
      if (typeof value === 'string') {
        return value.toLowerCase().includes(query);
      } else if (typeof value === 'number' || typeof value === 'bigint') {
        return value.toString().includes(query);
      }
      // Handle other data types if needed
      return false; // Return false for non-searchable types
    })
  );
}

export default function MyContracts() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.products.userContracts);
  const [open, setOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selected, setSelected] = useState({});
  const [selectedAction, setSelectedAction] = useState(null);
  const [columnWidths, setColumnWidths] = useState({});
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState(data || []);
  const [updateTrigger, setUpdateTrigger] = useState(0); // Using a counter for demonstration

  useEffect(() => {
    if (data && data.length > 0) {
      setSortedData(data);
    }
  }, [data]);

  useEffect(() => {
    dispatch(contractsByUser());
  }, []);

  const handleSearchChange = (event) => {
    const { value } = event.currentTarget;
    setSearch(value);
    const sorted = sortData(data, { sortBy, reversed: reverseSortDirection, search: value });
    setSortedData(sorted);
  };

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    const sorted = sortData(data, { sortBy: field, reversed, search });
    setSortedData(sorted);
  };

  const actions = [
    { label: <FormattedMessage id="action.operate" />, value: 'view' },
    { label: <FormattedMessage id="action.negotiate" />, value: 'update' },
  ];

  const handleActionChange = (value, rowData) => {
    setSelectedAction(value);

    // Perform action based on the selected value and rowData
    if (value === 'view') {
      setIsUpdate(false);
      setOpen(true);
      setSelected(rowData);
    } else if (value === 'update') {
      setIsUpdate(true);
      setOpen(true);
      setSelected(rowData);
    }
  };

  useEffect(() => {
    // Here you would fetch new data or simply indicate that a recalculation is needed
    // For demonstration, assuming data fetch or update operation happens here
    
    // Once data is assumed to be updated, re-apply sorting and filtering
    const sorted = sortData(data, { sortBy, reversed: reverseSortDirection, search });
    setSortedData(sorted);
  }, [updateTrigger, data, sortBy, reverseSortDirection, search]); // Reacting to updateTrigger changes  

  const intl = useIntl();

  const handleModalClose = () => {
    // Perform any necessary actions after the modal is closed
    dispatch(contractsByUser());
    // Close the modal
    setOpen(false);
  };

  return (
    <>
      <UpdateContractModal open={open} toggle={() => {handleModalClose()}} isUpdate={isUpdate} selected={selected}/>
      <ScrollArea>
      <TextInput
        placeholder={intl.formatMessage({ id:"myprods.search.placeholder" })}
        mb="md"
        icon={<IconSearch size="0.9rem" stroke={1.5} />}
        value={search}
        onChange={handleSearchChange}
      />
      <Table striped highlightOnHover withBorder withColumnBorders>
      <thead style={{ opacity: "1" }}>
        <tr>
        {PropertyContractTableConfig.map((data, ind) => (
              <Th
                sx={{
                  width: columnWidths[data.accessor],
                  overflow: "auto",
                  resize: "horizontal",
                  borderRight: "1px solid grey",
                }}
                key={ind}
                sorted={sortBy === data.accessor}
                reversed={reverseSortDirection}
                onSort={() => setSorting(data.accessor)}
              >
                {data.column}{" "}
              </Th>
            ))}
            <th><FormattedMessage id="myprods.actionsmenu.title" /></th>
        </tr>
      </thead>
      <tbody>
        {sortedData.map((data, ind) => (
          <tr key={data.productRefID + data.SellerID + ind}>
                {PropertyContractTableConfig.map((data1, idx) => {
                  let cellValue = '';
                  if (data1['accessor'] && data1['accessor'] === 'period' && data[data1['accessor']] && data[data1['accessor']].includes(' - ')) {
                    const splitValues = data[data1['accessor']].split(' - ');
                    cellValue = splitValues[0].substring(0, 10) + ' - ' + splitValues[1].substring(0, 10);
                  } else {
                    cellValue = data[data1['accessor']] || data1.defaultValue || '';
                  }   
                  // Check if productID is 99999
                  if (data1['accessor'] && data1['accessor'] === 'sku' && data[data1['accessor']] === 99999 || data[data1['accessor']] === -1) {
                    cellValue = 'Draft';
                  }
                  return (
                    <td
                      key={idx}
                      sx={{ width: columnWidths[data1['accessor']], overflow: 'auto' }}
                    >
                      {cellValue}
                      {data1.link && (
                        <a
                          rel="noopener noreferrer"
                          href={`https://hashscan.io/testnet/contract/${data[data1['accessor']]}`}
                          target="_blank"
                          style={{ color: '#2c5810' }}
                        >
                          {cellValue}
                        </a>
                      )}
                    </td>
                  );
                })}
                <td>
              <div style={{ maxHeight: '50px', overflow: 'auto' }}>
              <Dropdown
                  actions={actions}
                  handleActionChange={handleActionChange}
                  data={data}
                />
                {/* <Select
                  dropdownPosition="bottom"
                  placeholder={intl.formatMessage({ id:"myprods.actionsmenu.default" })}
                  data={actions}
                  value={selectedAction}
                  onChange={(value) => handleActionChange(value, data)}
                  menuPosition="auto" // Use "auto" for default positioning behavior
                /> */}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    </ScrollArea>
    </>
  );
}
