import React from "react";
import { Link } from "react-router-dom";

import { IntlProvider, FormattedMessage } from "react-intl";

  const containerStyle = {
    padding: "20px",
    margin: "20px auto",
    maxWidth: "800px",
  };

  const titleStyle = {
    textAlign: "center",
    padding: "20px",
    margin: "20px auto",
  };

  const sectiontitleStyle = {
    fontSize: "calc(1.325rem + .9vw)",
  }

  const subtitleStyle = {
    fontSize: "calc(1.275rem + .3vw)",
  };

  const backButtonStyle = {
    display: "block",
    margin: "20px auto",
    textAlign: "center",
  };

  const PressPage = () => {
  return (
    <div className="container" style={containerStyle}>
      <div className="text" >
        <h1 style={titleStyle}>
          <FormattedMessage id="press.title" />
        </h1>
        <div>
          <h2 style={sectiontitleStyle}>
          <FormattedMessage id="press.press.title" />
          </h2>
          <h4 style={subtitleStyle}>
            <FormattedMessage id="press.press.subtitle" />
            </h4>
          <p>
            <FormattedMessage id="press.press.para1" />
          </p>
          <p>
            <FormattedMessage id="press.press.para2" />
          </p>
        </div>
        <div>
          <h2 style={sectiontitleStyle}>
            <FormattedMessage id="press.contact.title" />
          </h2>
          <p>
            <FormattedMessage id="press.contact.email" />
            {" "}
            <a href="mailto:info@vertocommodities.io">info@vertocommodities.io</a>.
          </p>
          <p>
            <FormattedMessage id="press.contact.linkedin" />
            {" "}
            <a href="https://www.linkedin.com/company/vertocommodities">https://www.linkedin.com/company/vertocommodities</a></p>
          <p>
            <FormattedMessage id="press.contact.address" />
          </p>
        </div>
        <div style={backButtonStyle}>
          <Link to="/" className="btn btn-secondary">
           <FormattedMessage id="button.backhome" />
          </Link>
        </div>
      </div>
    </div>
    
  );

 

};

export default PressPage;