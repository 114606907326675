import { delay, put, takeEvery, Effect, ForkEffect, call } from 'redux-saga/effects';
import { failMsg, getProducts, getProductsSucess, saveProductAction } from "./CounterSlice";
import { InsertProduct } from './productApi';

// export function* getProductList() {
//     try {
//         var result = yield call(getProductApiCall);
//         yield put(getProductsSucess(result));

//     } catch (error) {
//         console.log("API ERROR", error);
//         yield put(failMsg(error));
//     }


// }


export function* saveProduct({payload, type}) {
    try {
        var result = yield call(InsertProduct, payload);
        // console.log(result);
        yield put(getProducts());

    } catch (error) {
        console.log("API ERROR", error);
        yield put(failMsg(error));
    }


}

function* productSagaLoc() {
    // yield takeEvery(getProducts, getProductList)
    yield takeEvery(saveProductAction, saveProduct)

}


const productSaga = productSagaLoc;

export default productSaga;