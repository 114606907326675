import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Text, Container, Grid, Button, Image, Badge } from '@mantine/core';
import { getUserFullDetails } from '../Redux/user/slice';
import { DropzoneButton } from './DropzoneButton.js';

import { IntlProvider, FormattedMessage } from "react-intl";

export default function MobileMyPersonalSpace() {
  const userinfo = JSON.parse(localStorage.getItem('userinfo'));
  const userFullDetails = useSelector((state) => state.user.userFullDetails);
  const dispatch = useDispatch();
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    if (!userFullDetails.userId) {
      dispatch(getUserFullDetails({ email: userinfo.email }));
    }
  }, [dispatch, userinfo.email, userFullDetails.userId]);

  const userDetails = userFullDetails;

  // Function to copy PublicKey to clipboard
  const copyPublicKey = () => {
    navigator.clipboard.writeText(userDetails.PublicKey);
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 2000); // Reset copy success message after 2 seconds
  };

  return (
    <Container size="md">
      <Paper padding="md" shadow="xs"  style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1, padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Image
            src={`https://vertoledgerstorage.blob.core.windows.net/sqldbledgerdigests/userSpace/${userinfo.accountId}/p.avif`}
            alt="User Profile Image"
            style={{ width: '200px', height: 'auto', float: 'center' }}
          />
        </div>
        <div style={{ flex: 2, padding: '20px' }}>
          <Grid gutter="md">
            <div style={{ marginBottom: '20px' }}>
              <Text size="xl" weight={700}>
                <FormattedMessage id="userprofile.title" />
              </Text>
            </div>
            {/* Display user details */}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <Text weight={500}><FormattedMessage id="userInfo.name" /></Text>
              <Text>{userDetails.userName}</Text>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <Text weight={500}><FormattedMessage id="userInfo.email" /></Text>
              <Text>{userDetails.email}</Text>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <Text weight={500}><FormattedMessage id="userInfo.country" /></Text>
              <Text>{userDetails.Country}</Text>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <Text weight={500}><FormattedMessage id="userInfo.acctID.profile" /></Text>
              <Text>{userDetails.accountId}</Text>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <Text weight={500}><FormattedMessage id="userInfo.prodcontractID" /></Text>
              <Text>{userDetails.product_contractId}</Text>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <Text weight={500}><FormattedMessage id="userInfo.comercontractID" /></Text>
              <Text>{userDetails.commercial_contractId}</Text>
            </div>
            {/* Display PublicKey as a button */}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <Text weight={500}><FormattedMessage id="userInfo.pubKey" /></Text>
              <Button
                color="blue"
                size="xs"
                onClick={copyPublicKey}
                disabled={copySuccess}
              >
                {copySuccess ? 'Copied' : 'Copy'}
              </Button>
            </div>
            {/* Display password */}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <Text weight={500}><FormattedMessage id="userInfo.pwd" /></Text>
              <Text>•••••••••</Text>
            </div>
            <Button color="blue" variant="outline" fullWidth style={{ marginTop: '20px' }}>
              <FormattedMessage id="button.editprofile" />
            </Button>
          </Grid>
        </div>
      </Paper>
      {/* Include the DropzoneButton component below the user profile box */}
      <div style={{ marginTop: '20px' }}>
        <DropzoneButton />
      </div>
    </Container>
  );
}