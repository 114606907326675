import {
  CircularProgress,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { transferNFT } from "../Redux/properties/PropertiesSlice";

import { IntlProvider, FormattedMessage } from "react-intl";

function WalletDetails({ toggle, modal = false }) {
  const data = useSelector((state) => state.properties);

  const { details } = data.userDetails || {};

  const { accInfo, acctBalInfo } = details || {};
  const realEstateData = useSelector((state) => state.properties);

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} style={{ height: "auto" }}>
        <ModalHeader toggle={toggle}>
          {<FormattedMessage id="wallet.details" /> + accInfo?.accountId}
        </ModalHeader>
        <ModalBody>
          <Grid container>
            <Grid item>
              <Stack>
                <span>{<FormattedMessage id="wallet.acctID" /> + accInfo?.accountId} </span>
                <span>{<FormattedMessage id="wallet.balance" /> + accInfo?.balance} </span>
                <span>{<FormattedMessage id="wallet.expirytime" /> + accInfo?.expirationTime} </span>
                <span>
                  {<FormattedMessage id="wallet.contractAcct" /> + accInfo?.contractAccountId}{" "}
                </span>
              </Stack>
            </Grid>
            <Grid item>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><FormattedMessage id="wallet.table.assetname" /></TableCell>
                      <TableCell><FormattedMessage id="wallet.table.balance" /></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell><FormattedMessage id="wallet.table.hbars" />H-bars</TableCell>
                      <TableCell>{acctBalInfo?.hbars}</TableCell>
                    </TableRow>

                    {acctBalInfo?.tokens?.map((token) => {
                      return (
                        <TableRow>
                          <TableCell>{token.tokenId}</TableCell>
                          <TableCell>{token?.balance}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            <FormattedMessage id="button.ok" />
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default WalletDetails;
