import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ContractStates } from "../utils/constants/common";
import { useDispatch, useSelector } from "react-redux";
import { addCommercialContract, buyerConfirm, sellerConfirm, updateCommercialDB, updateCommmercialDB2 } from "../Redux/products/slice";
import { toast } from "react-toastify";
import { select } from "redux-saga/effects";
import { ToastProperties } from '../utils/constants/toastProperties';

import { IntlProvider, FormattedMessage, useIntl } from 'react-intl';

export default function UpdateContractModal({ open = false, toggle, isUpdate, selected }) {

  const dispatch = useDispatch()
  const { updateCommercialMessage } = useSelector(state => state.products)
  const filtedState = (s) => ContractStates.find(ec => ec.id === s)

  const [selectedState, setSelectedState] = useState(filtedState(selected.contractState))
  const [updating, setUpdating] = useState({})

  const handleSave = () => {
    console.log({ selected })
    const payload = {
      ...selected,
      "CONTRACTID": selected.contractId,
      BUYERID: selected.BuyerID,
      SELLERID: selected.SellerID,
      ...updating
    }
    dispatch(updateCommercialDB(payload));
  }

  const handleAllSubjects = () => {
    const userinfo = JSON.parse(localStorage.getItem('userinfo'));
    const payload = {
      ...selected,
      "CONTRACTID": selected.contractId,
      BUYERID: selected.BuyerID,
      SELLERID: selected.SellerID,
      "state": "AllSubjectsLifted",
      requestUserId: userinfo.userId,
      ownerAccountID: userinfo.accountId,
      productRefId: selected.productRefID,
    }
    dispatch(updateCommmercialDB2(payload))
  }

  const handleSellerSign = () => {
    const userinfo = JSON.parse(localStorage.getItem('userinfo'));
    dispatch(sellerConfirm({
      contractId: selected.contractId,
      sku: selected.sku,
      requestUserId: userinfo.userId,
      ownerAccountID: userinfo.accountId,
      state: selected.contractState,
      productRefId: selected.productRefID,
      BuyerID: selected.BuyerID,
      contractRefId: selected.contractRefID,
    }))

    setTimeout(() => {
      toggle()
    }, 3000)
  };

  const handleBuyerSign = () => {
    const userinfo = JSON.parse(localStorage.getItem('userinfo'));
    dispatch(buyerConfirm({
      contractId: selected.contractId,
      sku: selected.sku,
      requestUserId: userinfo.userId,
      ownerAccountID: userinfo.accountId,
      state: selected.contractState,
      productRefId: selected.productRefID,
      BuyerID: selected.BuyerID,
      contractRefId: selected.contractRefID,
    }))

    setTimeout(() => {
      toggle()
    }, 3000)
  };

  const updatePayload = () => {
    // console.log({ value, requiredPayload })
    // return { ...value, ...requiredPayload, productRefId: value.productID };
    const userinfo = JSON.parse(localStorage.getItem('userinfo'))
    return {
      productRefId: selected.productRefID,
      contractId: selected.contractId,
      sellerId: selected.SellerID,
      buyerId: selected.BuyerID,
      contractDetails: selected.contractDetails,
      price: selected.price,
      location: selected.location,
      period: selected.period,
      quantity: selected.quantity,
      productNotes: selected.productNotes,
      requestUserId: userinfo.userId,
      ownerAccountID: userinfo.accountId,
      contractRefId: selected.contractRefID,
    }
  };

  const handleConfirmOrder = () => {
    if (selected.contractState === "Completed") {
      toast.info("You cannot confirm order as this contract is already completed", {
        ...ToastProperties
      })
    } else if  (selected.contractState === "Draft") {
      toast.info("You cannot confirm as there is no Buyer, this is a draft contract", {
        ...ToastProperties
      })
    } else {
      dispatch(addCommercialContract(updatePayload()));
    }

    setTimeout(() => {
      toggle()
    }, 3000)

  };

  const setNewValues = (k, v) => {
    setUpdating(e => ({
      ...e,
      [k]: v
    }))
  }

  const isUpdateDisabled = useMemo(() => {
    return selected.contractState === 'Completed' || selected.contractState === 'allsubjectlifted'
  }, [selected.contractState])

  const isDisabled = useMemo(() => {
    return selected.contractState === 'Completed' || selected.contractState === 'allsubjectlifted' || selected.contractState === 'Draft'
  }, [selected.contractState])

  useEffect(() => {
    if (updateCommercialMessage) {
      toggle()
    }
  }, [updateCommercialMessage]);

  const [userinfo, setUserinfo] = useState(null);

  // Fetch user info from local storage on component mount
  useEffect(() => {
    const storedUserinfo = JSON.parse(localStorage.getItem("userinfo"));
    setUserinfo(storedUserinfo);
  }, []);

  const intl = useIntl();

  return (
    <>
      <Modal isOpen={open} toggle={toggle} style={{ height: "auto", maxWidth: "80%" }}>
        <ModalHeader><FormattedMessage id="modal.updateContract.title" /></ModalHeader>
        <ModalBody>
          <Grid item>
            <Grid container>
              <Grid item mb="10pt">
                <TextField
                  id="outlined-basic"
                  label={intl.formatMessage({ id:"product.id" })}
                  aria-readonly
                  variant="outlined"
                  defaultValue={selected.productSKU}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item mb="10pt">
                <TextField
                  id="outlined-basic"
                  label={intl.formatMessage({ id:"product.period" })}
                  variant="outlined"
                  defaultValue={selected.period}
                  onChange={e => setNewValues('period', e.target.value)}
                  InputProps={{
                    readOnly: true && !isUpdate,
                  }}
                />
              </Grid>
              <Grid item mb="10pt">
                <TextField
                  id="outlined-basic"
                  label={intl.formatMessage({ id:"product.quantity" })}
                  variant="outlined"
                  defaultValue={selected.quantity}
                  onChange={e => setNewValues('quantity', e.target.value)}
                  InputProps={{
                    readOnly: true && !isUpdate,
                  }}
                />
              </Grid>
              <Grid item mb="10pt">
                <TextField
                  id="outlined-basic"
                  label={intl.formatMessage({ id:"product.price" })}
                  variant="outlined"
                  defaultValue={selected.price}
                  onChange={e => setNewValues('price', e.target.value)}
                  InputProps={{
                    readOnly: true && !isUpdate,
                  }}
                />
              </Grid>
              <Grid item mb="10pt">
                <TextField
                  id="outlined-basic"
                  label={intl.formatMessage({ id:"product.location" })}
                  variant="outlined"
                  defaultValue={selected.location}
                  onChange={e => setNewValues('location', e.target.value)}
                  InputProps={{
                    readOnly: true && !isUpdate,
                  }}
                />
              </Grid>
              <Grid item mb="10pt">
                <TextField
                  id="outlined-basic"
                  label={intl.formatMessage({ id:"product.notes" })}
                  variant="outlined"
                  defaultValue={selected.productNotes}
                  onChange={e => setNewValues('productNotes', e.target.value)}
                  InputProps={{
                    readOnly: true && !isUpdate,
                  }}
                />
              </Grid>
              <Grid item mb="10pt">
                <TextField
                  id="outlined-basic"
                  label={intl.formatMessage({ id:"contract.details" })}
                  variant="outlined"
                  defaultValue={selected.contractDetails}
                  onChange={e => setNewValues('contractDetails', e.target.value)}
                  InputProps={{
                    readOnly: true && !isUpdate,
                  }}
                />
              </Grid>
              <Grid item mb="10pt">
              </Grid>
            </Grid>
          </Grid>
        </ModalBody>
        <ModalFooter style={{ display: "flex", justifyContent: "center" }}>
          {
            isUpdate && <>
              <Button color="primary" disabled={isUpdateDisabled || (userinfo && userinfo.userId === selected.BuyerID)} onClick={handleSave}>
                <FormattedMessage id="button.save" />
              </Button>
              <Button color="danger" disabled={isUpdateDisabled} onClick={toggle}>
              <FormattedMessage id="button.cancel" />
              </Button>
            </>
          }
          {!isUpdate && <>
            <Button 
              disabled={isDisabled || userinfo?.userId === selected.SellerID || selected.contractState !== 'InNegotiation'} 
              color="success" onClick={handleConfirmOrder}>
              <FormattedMessage id="button.confirmorder" />
            </Button>
            {selected.contractState !== 'Completed' && <Button 
              disabled={isDisabled || selected.contractState !== 'OnSubjects'} 
              color="info" onClick={handleAllSubjects}>
              <FormattedMessage id="button.liftallsubjects" />
            </Button>}
            <Button 
              disabled={isDisabled || userinfo?.userId === selected.SellerID || !(selected.contractState === 'ConfirmedBySeller' || selected.contractState === 'AllSubjectsLifted')} 
              color="warning" onClick={handleBuyerSign}>
              <FormattedMessage id="button.buyersign" />
            </Button>
            <Button 
              disabled={isDisabled || userinfo?.userId === selected.BuyerID || !(selected.contractState === 'ConfirmedByBuyer' || selected.contractState === 'AllSubjectsLifted')} 
              color="primary" onClick={handleSellerSign}>
              <FormattedMessage id="button.sellersign" />
            </Button>
          </>}
        </ModalFooter>
      </Modal>
    </>
  );
}