// HomePage.jsx
import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Image,
  Flex,
  Button,
  ListItem,
  SimpleGrid,
  HStack,
  VStack,
  useDisclosure,
  ChakraProvider,
  extendTheme,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  List,
  ListIcon,
  Grid,
} from "@chakra-ui/react";

import { FaDownload, FaPhone } from "react-icons/fa";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { motion } from "framer-motion";

import MenuPanel from "../components/MenuPanel";
import SalePanel from "../components/SalePanel";
import Footer from "../components/Footer";
import SecondFooter from "../components/SecondFooter";
import { LoginModal } from "../components/LoginModal";

import landingPicture from "../assets/images/landing-page-picture.jpg";
import hederaLogo from "../assets/HederaLogo.png";
import hero1 from "../assets/images/hero1.jpg";
import pate from "../assets/images/pate.jpg";
import livre_blanc from "../assets/images/livre_blanc.png";
import desk from "../assets/images/desk.png";

import "@fontsource/raleway/400.css";
import "@fontsource/raleway/500.css";
import "@fontsource/raleway/600.css";
import "@fontsource/playfair-display"; // Defaults to weight 400

const theme = extendTheme({
  fonts: {
    heading: "Raleway, sans-serif",
    body: "Raleway, sans-serif",
  },
});

const HomePage = () => {
  let { level } = useParams();
  const [salePanelModal, setSalePanelModal] = useState(false);
  const [saleSelected, setSaleSelected] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (saleSelected) {
      setSalePanelModal(true);
    }
  }, [saleSelected]);

  const IntroductionSection = () => (
    <>
      <Box width="100%" height="50vh">
        <Image src={landingPicture} alt="Marketplace Image" width="100%" height="100%" objectFit="cover" />
      </Box>

      <Box bg="white" py={10} px={4} textAlign="center">
        <motion.div initial={{ opacity: 0, y: 30 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
          <Heading fontSize={{ base: "2xl", md: "4xl" }} fontWeight="bold" color="gray.800" mb={4}>
            <FormattedMessage fontFamily="Playfair Display" id="app.homepage.title" />
          </Heading>
        </motion.div>
      </Box>

      <Box textAlign="center" mt={0}>
        <Text
          fontSize={{ base: "lg", md: "xl" }}
          fontStyle="italic"
          color="gray.600"
          mb={4}
          mx="auto"
          maxWidth="80%"
        >
          <FormattedMessage id="app.homepage.subtitle" />
        </Text>
        <Image src={hederaLogo} alt="Logo" height="40px" mx="auto" />
      </Box>
    </>
  );

  const PlatformSection = () => (
    <Box bg="white" py={12} px={{ base: "5%", md: "10%" }} fontFamily="Raleway, sans-serif">
      <Heading fontSize={{ base: "2xl", md: "3xl" }} fontWeight="bold" textAlign="center" mb={8} color="teal.700">
        <FormattedMessage id="app.homepage.vertoPlatform" />
      </Heading>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
        <Box p={6} borderWidth="1px" borderRadius="lg" boxShadow="sm" bg="transparent">
          <Text fontSize={{ base: "md", md: "lg" }} color="gray.600" textAlign="justify">
            <FormattedMessage id="app.homepage.vertoPlatformDescriptionPart1" />
          </Text>
        </Box>

        <Box p={6} borderWidth="1px" borderRadius="lg" boxShadow="sm" bg="transparent">
          <Text fontSize={{ base: "md", md: "lg" }} color="gray.600" textAlign="justify">
            <FormattedMessage id="app.homepage.vertoPlatformDescriptionPart2" />
          </Text>
        </Box>

        <Box p={6} borderWidth="1px" borderRadius="lg" boxShadow="sm" bg="transparent">
          <Text fontSize={{ base: "md", md: "lg" }} color="gray.600" textAlign="justify">
            <FormattedMessage id="app.homepage.vertoPlatformDescriptionPart3" />
          </Text>
        </Box>

        <Box p={6} borderWidth="1px" borderRadius="lg" boxShadow="sm" bg="transparent">
          <Text fontSize={{ base: "md", md: "lg" }} color="gray.600" textAlign="justify">
            <FormattedMessage id="app.homepage.vertoPlatformDescriptionPart4" />
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );

  // Commented for now, in case
  const ProcessSection = () => {
    const steps = [
      {
        image: pate,
        title: "app.homepage.block3.step1.title",
        description: "app.homepage.block3.step1.description",
        subDescription: "app.homepage.block3.step1.subDescription",
      },
      {
        image: hero1,
        title: "app.homepage.block3.step2.title",
        description: "app.homepage.block3.step2.description",
        subDescription: "app.homepage.block3.step2.subDescription",
      },
      {
        image: desk,
        title: "app.homepage.block3.step3.title",
        description: "app.homepage.block3.step3.description",
        subDescription: "app.homepage.block3.step3.subDescription",
      },
    ];

    return (
      <Box bg="gray.50" py={16} fontFamily="Raleway, sans-serif">
        <Box width={{ base: "90%", md: "80%" }} mx="auto" textAlign="center">
          <Heading fontSize={{ base: "2xl", md: "4xl" }} fontWeight="bold" mb={6}>
            <FormattedMessage id="app.homepage.block3.title" />
          </Heading>
          <Text fontSize="lg" color="gray.600" mb={10}>
            <FormattedMessage id="app.homepage.block3.subtitle" />
          </Text>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
            {steps.map((step, index) => (
              <Box key={index} textAlign="center" bg="white" p={6} borderRadius="md" shadow="md">
                <Image
                  src={step.image}
                  alt={`Étape ${index + 1}`}
                  width="100%"
                  height="200px"
                  objectFit="cover"
                  borderRadius="md"
                  mb={4}
                />
                <Heading fontSize="xl" mb={2}>
                  <FormattedMessage id={step.title} />
                </Heading>
                <Text fontSize="md" color="gray.600" mb={2}>
                  <FormattedMessage id={step.description} />
                </Text>
                <Text fontSize="sm" color="gray.500">
                  <FormattedMessage id={step.subDescription} />
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      </Box>
    );
  };

  const EthicalStandardsSection = () => (
    <Box bgGradient="linear(to-b, gray.50, white)" py={20} px={4} fontFamily="Raleway, sans-serif">
      <Box width={{ base: "90%", md: "80%" }} mx="auto">
        <Heading
          fontSize={{ base: "2xl", md: "4xl" }}
          mb={10}
          textAlign="center"
          fontWeight="bold"
          color="gray.800"
        >
          <FormattedMessage id="app.homepage.securedByEthicalStandards" />
        </Heading>

        <Grid templateColumns={{ base: "1fr", md: "1fr 1px 1fr" }} gap={8} alignItems="center">
          <Box>
            <VStack spacing={6} align="start">
              <Text textAlign="justify" fontSize="lg" color="gray.600" lineHeight="1.8">
                <FormattedMessage id="app.homepage.smartContractsDescription" />
              </Text>
            </VStack>
          </Box>

          <Box display={{ base: "none", md: "block" }} height="100%" width="1px" bg="gray.300" />

          <Box>
            <VStack spacing={6} align="start">
              <Heading fontSize={{ base: "xl", md: "2xl" }} fontWeight="medium" color="gray.800">
                <FormattedMessage id="app.homepage.whyDataSecuredHedera" />
              </Heading>
              <Text textAlign="justify" fontSize="lg" color="gray.600" lineHeight="1.8">
                <FormattedMessage id="app.homepage.hederaLedgerDescription" />
              </Text>
            </VStack>
          </Box>
        </Grid>
      </Box>
    </Box>
  );

  const BenefitsSection = () => {
    const benefits = [
      "app.homepage.benefitsLifecycle",
      "app.homepage.benefitsReliableSupply",
      "app.homepage.benefitsTraceabilityAdmin",
      "app.homepage.benefitsEnhancedEfficiency",
      "app.homepage.benefitsRealTimeTracking",
      "app.homepage.benefitsTransactionExplorer",
      "app.homepage.benefitsEfficientTransactions",
      "app.homepage.benefitsCostSavings",
      "app.homepage.benefitsFairTrustedSystem",
      "app.homepage.benefitsUserFriendlyPlatform",
      "app.homepage.benefitsExpertSupport",
    ];

    return (
      <Box bgGradient="linear(to-b, white, gray.50)" py={20} fontFamily="Raleway, sans-serif">
        <Box width={{ base: "90%", md: "80%" }} mx="auto">
          <Heading
            fontSize={{ base: "2xl", md: "4xl" }}
            mb={10}
            textAlign="center"
            fontWeight="bold"
            color="gray.800"
          >
            <FormattedMessage id="app.homepage.benefitsSectionTitle" />
          </Heading>

          <Grid
            templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} // 1 column on mobile, 3 on desktop
            gap={8}
            alignItems="start"
          >
            {benefits.map((benefitId, index) => (
              <Box key={index} p={6} borderWidth={1} borderRadius="md" bg="white" boxShadow="sm">
                <List spacing={3}>
                  <ListItem display="flex" alignItems="start">
                    <CheckCircleIcon color="green.500" boxSize={5} mr={3} />
                    <Text>
                      <FormattedMessage
                        id={benefitId}
                        values={{
                          bold: (chunks) => (
                            <Text as="strong" fontWeight="bold">
                              {chunks}
                            </Text>
                          ),
                        }}
                      />
                    </Text>
                  </ListItem>
                </List>
              </Box>
            ))}
          </Grid>
        </Box>
      </Box>
    );
  };

  const WhitePaperSection = () => (
    <Box bg="blue.900" color="white" py={16} fontFamily="Raleway, sans-serif">
      <Flex
        width={{ base: "90%", md: "80%" }}
        mx="auto"
        alignItems="center"
        justifyContent="space-between"
        flexDirection={{ base: "column", md: "row" }}
        gap={10}
      >
        <Box
          width={{ base: "100%", md: "50%" }}
          mb={{ base: 6, md: 0 }}
          textAlign={{ base: "center", md: "left" }}
        >
          <Heading fontSize={{ base: "2xl", md: "4xl" }} fontWeight="bold" mb={6}>
            <FormattedMessage id="app.homepage.block4.title" />
          </Heading>
          <Text fontSize="lg" mb={4}>
            <FormattedMessage id="app.homepage.block4.subtitle" />
          </Text>
          <Text fontSize="lg" mb={6}>
            <FormattedMessage id="app.homepage.block4.description" />
          </Text>
          <Button
            as="a"
            href="../assets/Verto-SmartContractForTraceabilityWhitePaper.pdf"
            variant="solid"
            colorScheme="teal"
            leftIcon={<FaDownload />}
            download
          >
            <FormattedMessage id="app.homepage.block4.buttonText" />
          </Button>
        </Box>
        <Box width={{ base: "100%", md: "50%" }} display="flex" justifyContent="center" alignItems="center">
          <Image src={livre_blanc} alt="Livre Blanc" maxWidth="100%" height="auto" borderRadius="md" shadow="lg" />
        </Box>
      </Flex>
    </Box>
  );

  const ContactSection = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const phoneNumber = "+41 76 609 25 37";

    return (
      <Box bg="white" py={16} fontFamily="Raleway, sans-serif">
        <Box
          bg="gray.50"
          width={{ base: "90%", md: "60%" }}
          mx="auto"
          p={8}
          borderRadius="md"
          shadow="md"
          textAlign="center"
        >
          <HStack spacing={2} alignItems="center" justifyContent="center" mb={4}>
            <FaPhone size={24} />
            <Heading fontSize={{ base: "2xl", md: "3xl" }} fontWeight="bold">
              <FormattedMessage id="app.homepage.block5.title" />
            </Heading>
          </HStack>
          <Text fontSize="lg" fontWeight="bold" mb={6}>
            <FormattedMessage id="app.homepage.block5.subtitle" />
          </Text>
          <Button onClick={onOpen} colorScheme="teal" variant="solid">
            <FormattedMessage id="app.homepage.block5.buttonText" />
          </Button>
          {isOpen && (
            <Box mt={4} bg="white" p={4} borderRadius="md" shadow="md">
              <Text fontSize="md">{phoneNumber}</Text>
              <Button onClick={onClose} mt={2} size="sm">
                <FormattedMessage id="menu.close" />
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const PricingOffers = () => {
    return (
      <Box p={5} maxW="75%" mx="auto" mt={15} mb={15}>
        <Heading as="h1" mb={5} textAlign="center">
          <FormattedMessage id="pricingOffers.title" />
        </Heading>
        <Accordion allowToggle>
          {/* Offer 1 */}
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Heading as="h2" size="md">
                  <FormattedMessage id="offer1.title" />
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text fontWeight="bold">
                <FormattedMessage id="offer1.scopeTitle" />
              </Text>
              <Text>
                <FormattedMessage id="offer1.scopeDescription" />
              </Text>

              <Text mt={4} fontWeight="bold">
                <FormattedMessage id="offer1.featuresTitle" />
              </Text>
              <List spacing={2} mt={2}>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  <FormattedMessage id="offer1.features.0" />
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  <FormattedMessage id="offer1.features.1" />
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  <FormattedMessage id="offer1.features.2" />
                </ListItem>
              </List>

              <Text mt={4} fontWeight="bold">
                <FormattedMessage id="offer1.priceTitle" />
              </Text>
              <Text>
                <FormattedMessage id="offer1.priceDescription" />
              </Text>

              <Text mt={4} fontWeight="bold">
                <FormattedMessage id="offer1.idealForTitle" />
              </Text>
              <Text>
                <FormattedMessage id="offer1.idealForDescription" />
              </Text>
            </AccordionPanel>
          </AccordionItem>

          {/* Offer 2 */}
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Heading as="h2" size="md">
                  <FormattedMessage id="offer2.title" />
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text fontWeight="bold">
                <FormattedMessage id="offer2.scopeTitle" />
              </Text>
              <Text>
                <FormattedMessage id="offer2.scopeDescription" />
              </Text>

              <Text mt={4} fontWeight="bold">
                <FormattedMessage id="offer2.featuresTitle" />
              </Text>
              <List spacing={2} mt={2}>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  <FormattedMessage id="offer2.features.0" />
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  <FormattedMessage id="offer2.features.1" />
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  <FormattedMessage id="offer2.features.2" />
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  <FormattedMessage id="offer2.features.3" />
                </ListItem>
              </List>

              <Text mt={4} fontWeight="bold">
                <FormattedMessage id="offer2.priceTitle" />
              </Text>
              <Text>
                <FormattedMessage id="offer2.priceDescription" />
              </Text>

              <Text mt={4} fontWeight="bold">
                <FormattedMessage id="offer2.idealForTitle" />
              </Text>
              <Text>
                <FormattedMessage id="offer2.idealForDescription" />
              </Text>
            </AccordionPanel>
          </AccordionItem>

          {/* Offer 3 */}
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Heading as="h2" size="md">
                  <FormattedMessage id="offer3.title" />
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text fontWeight="bold">
                <FormattedMessage id="offer3.scopeTitle" />
              </Text>
              <Text>
                <FormattedMessage id="offer3.scopeDescription" />
              </Text>

              <Text mt={4} fontWeight="bold">
                <FormattedMessage id="offer3.featuresTitle" />
              </Text>
              <List spacing={2} mt={2}>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  <FormattedMessage id="offer3.features.0" />
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  <FormattedMessage id="offer3.features.1" />
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  <FormattedMessage id="offer3.features.2" />
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  <FormattedMessage id="offer3.features.3" />
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  <FormattedMessage id="offer3.features.4" />
                </ListItem>
              </List>

              <Text mt={4} fontWeight="bold">
                <FormattedMessage id="offer3.priceTitle" />
              </Text>
              <Text>
                <FormattedMessage id="offer3.priceDescription" />
              </Text>

              <Text mt={4} fontWeight="bold">
                <FormattedMessage id="offer3.idealForTitle" />
              </Text>
              <Text>
                <FormattedMessage id="offer3.idealForDescription" />
              </Text>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    );
  };

  return (
    <>
      {saleSelected && (
        <SalePanel
          modal={salePanelModal}
          toggle={() => {
            setSalePanelModal(false);
            setSaleSelected(undefined);
          }}
          title={saleSelected?.title}
          value={saleSelected}
        />
      )}
      <div className="whole">
        <MenuPanel level={level} />
        <ChakraProvider theme={theme}>
          <IntroductionSection />
          <PlatformSection />
          {/* <ProcessSection /> */}
          <EthicalStandardsSection />
          <BenefitsSection />
          <PricingOffers />
          <ContactSection />
          <WhitePaperSection />
          <SecondFooter />
          <Footer />
        </ChakraProvider>
        <LoginModal />
      </div>
    </>
  );
};

export default HomePage;
