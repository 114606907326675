import { CircularProgress, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { transferNFT } from "../Redux/properties/PropertiesSlice";

function SuccessMSGModel({ toggle, modal = false, title, value }) {
  const realEstateData = useSelector((state) => state.properties);
  const dispatch = useDispatch();

  const save = () => {
    dispatch(transferNFT(value));
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} style={{ height: "auto" }}>
        <ModalHeader toggle={toggle}>{"Successfully transferred"}</ModalHeader>
        <ModalBody>
          <Grid container>
            {"Successfully transferred Asset " +
              title +
              " from testseller2 (0.0.48903965) to testbuyer1 (0.0.48903622)"}
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default SuccessMSGModel;
