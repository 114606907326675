import { Grid, FormControl } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { loadLotImage } from "../Redux/properties/PropertiesSlice";
import ImageCropper from "./ImageCropper";
import FileInput from "./FileInput";
import {  FormattedMessage } from "react-intl";
import { FileButton, Button as MButton } from '@mantine/core';


function LoadImageModal({ toggle, modal, selectedData, onSave, onDraftSave }) {
  const [imageFile, setImageFile] = useState("");
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);


  const [image, setImage] = useState("");
  const [currentPage, setCurrentPage] = useState("choose-img");
  const [imgAfterCrop, setImgAfterCrop] = useState("");

  // Invoked when new image file is selected
  const onImageSelected = (selectedImg) => {
    setImage(selectedImg);
    setCurrentPage("crop-img");
  };

  // Generating Cropped Image When Done Button Clicked
  const onCropDone = (imgCroppedArea) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCroppedArea.width;
    canvasEle.height = imgCroppedArea.height;

    const context = canvasEle.getContext("2d");

    let imageObj1 = new Image();
    imageObj1.src = image;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      );

      const dataURL = canvasEle.toDataURL("image/jpeg");

      setImgAfterCrop(dataURL);
      setCurrentPage("img-cropped");
    };
  };

  // Handle Cancel Button Click
  const onCropCancel = () => {
    setCurrentPage("choose-img");
    clearData();
  };

  const saveData = () => {
    const obj = {
      // property_img: imgAfterCrop,
      property_img: imageFile,
      productID: selectedData.productID
    };

    dispatch(loadLotImage(obj));
    clearData();
    setSuccessMessage(<FormattedMessage id="modal.loadimage.success" />); // Set the success message
    setShowSuccess(true); // Show the success message
    toggle(); // Close the modal
  };

  const clearData = () => {
    setImage("");
    setImgAfterCrop("");
    setCurrentPage("choose-img");
  };

  function imageUploaded(file) {
    // var file = document.getElementById("fileType").files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = function () {
        const base64String = reader.result;
        setImageFile({ name: file.name, data: base64String });
      };

      reader.readAsDataURL(file);
    }
  }

  return (
    <Modal centered isOpen={modal} toggle={toggle} style={{ height: "auto" }}>
      <ModalHeader toggle={toggle}><FormattedMessage id="modal.loadimage.title" /></ModalHeader>
      <ModalBody>
        <Grid container spacing={1}>
          <Grid item>
            {/* <label>
              <input
                id="fileType"
                type="file"
                name="myImage"
                onChange={(event) => imageUploaded()}
                accept="image/*"
              />
              </label> */}
                <FileButton style={{ backgroundColor: 'grey', color: 'white' }} onChange={(file) => imageUploaded(file)} accept="image/*">
                  {(props) => <MButton {...props}><FormattedMessage id="button.chooseimg"></FormattedMessage> </MButton>}
                </FileButton>
                {imageFile && (
                  <div style={{ marginTop: '10px' }}>
                    {imageFile.name}
                  </div>
                )}
          </Grid> 
           <Grid item>
            {currentPage === "choose-img" ? (
              <></> 
            ) : currentPage === "crop-img" ? (
              <div style={{ height: "500px" }}>                
              </div>
            ) : (<></>)}
          </Grid>
          {/* <Grid item>
            <div>
              {currentPage === "choose-img" ? (
                <FileInput
                  setImage={setImage}
                  onImageSelected={onImageSelected} />
              ) : currentPage === "crop-img" ? (
                <ImageCropper
                  image={image}
                  onCropDone={onCropDone}
                  onCropCancel={onCropCancel}
                />
              ) : (
                <div>
                  <div>
                    <img
                      src={imgAfterCrop}
                      style={{ width: "100%" }}
                      className="cropped-img" />
                  </div>

                  <button
                    onClick={() => {
                      setCurrentPage("crop-img");
                    }}
                    className="btn"
                  >
                    <FormattedMessage id="button.img.crop" />
                  </button>

                  <button
                    onClick={() => {
                      setCurrentPage("choose-img");
                      setImage("");
                    }}
                    className="btn"
                  >
                    <FormattedMessage id="button.new" />
                  </button>
                </div>
              )}
            </div>
          </Grid> */}
        </Grid>
      </ModalBody>
      <ModalFooter>
        <MButton color="primary" onClick={() => saveData()}>
          <FormattedMessage id="button.img.upload" />
        </MButton>
        <MButton
          color="secondary"
          onClick={() => {
            clearData();
            toggle();
          }}
        >
          <FormattedMessage id="button.cancel" />
        </MButton>
      </ModalFooter>
    </Modal>
  );
}

export default LoadImageModal;