import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../Redux/user/slice';

let logoutTimer;

export function IdleTimeout() {
  const dispatch = useDispatch();

  useEffect(() => {
    function resetLogoutTimer() {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => {
        dispatch(logout());
        localStorage.clear('userLogged');
        localStorage.clear('userinfo');
    
        if (window.location.pathname === '/my-list') {
          window.location.href = '/';
        }
      }, 300000*2); // 10 minutes in milliseconds
    }

    // Event listeners for user activity
    document.addEventListener('mousemove', resetLogoutTimer);
    document.addEventListener('keydown', resetLogoutTimer);

    // Initial setup
    resetLogoutTimer();

    // Cleanup
    return () => {
      document.removeEventListener('mousemove', resetLogoutTimer);
      document.removeEventListener('keydown', resetLogoutTimer);
      clearTimeout(logoutTimer);
    };
  }, [dispatch]);

  return null; // You can return null or any other component you prefer
}