import { HashConnect } from "hashconnect";
import { BASE_URL } from '../apiConfig';

export const getAllProperties = async (language) => {
  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
  };
  return await fetch(
    `${BASE_URL}/getAllProductList?targetLanguage=${language}`, 
    // "https://s4kyjm76vdmpjod2vui3cz7zxm0jbxbh.lambda-url.ap-southeast-1.on.aws/getallproducts",
    options
  ).then((res) => res.json());
};

export const getRecentTransactionAPI = async (id) => {
  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await fetch(
    "https://testnet.mirrornode.hedera.com/api/v1/transactions?account.id=" +
      id,
    options
  ).then((res) => res.json());
};

export const associateTokenAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    "https://2v445l6fn75glpuq2nuwihq4wu0tmzjk.lambda-url.ap-southeast-1.on.aws/associatetoken",
    options
  ).then((res) => res.json());
};

export const transferTokenApi = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    "https://2v445l6fn75glpuq2nuwihq4wu0tmzjk.lambda-url.ap-southeast-1.on.aws/transfernfttoken",
    options
  ).then((res) => res.json());
};

export const getWalletBalance = async () => {
  let hashconnect = new HashConnect();
  let appMetadata = {
    name: "dApp Example",
    description: "An example hedera dApp",
    icon: "https://absolute.url/to/icon.png",
  };

  let initData = await hashconnect.init(appMetadata, "testnet", false);

  if (initData.savedPairings) {
    let provider = hashconnect.getProvider(
      "testnet",
      initData.topic,
      initData.savedPairings[0].accountIds[0]
    );
    let balance = await provider.getAccountBalance(
      initData.savedPairings[0].accountIds[0]
    );
    console.log("Waller", balance.hbars._valueInTinybar.toNumber());
    return balance.hbars._valueInTinybar.toNumber();
  } else {
    return "Not Linked";
  }
};

export const SavePropertiesAPI = async (payload) => {
  const { property_img, ...payloadWithoutImage } = payload; // Destructure the property_img from the payload

  // Example of making an authenticated request using fetch
  const authToken = localStorage.getItem("authToken");

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      apikey: "abc123",
    },
    body: JSON.stringify(payloadWithoutImage), // Use the payload without the property_img
  };

  const response = await fetch(`${BASE_URL}/addLot`, options);

  const data = await response.json();

  // If the first POST request is successful, make the second POST request to localhost
  if (response.ok) {
    // Assume success by default
    let uploadSuccess = true;

    try {
      // const uploadResponse = await fetch(`${BASE_URL}/loadImage", {
      const uploadResponse = await fetch("https://vertobackendapp.azurewebsites.net/loadImage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "abc123",
          apikey: "abc123",
        },
        body: JSON.stringify({ productID: data.productID, property_img }),
      });

      if (uploadResponse.status === 413) {
        throw new Error("Picture is too large");
      } else if (!uploadResponse.ok) {
        throw new Error("Failed to upload image to API");
      }
    } catch (uploadError) {
      // If there is an error in image upload, set uploadSuccess to false
      uploadSuccess = false;
      console.error("Image upload error:", uploadError.message);
    }

    // Attach the uploadSuccess information to the response data
    data.uploadSuccess = uploadSuccess;
  }

  return data;
};

export const SaveDraftPropertiesAPI = async (payload) => {
  const { property_img, ...payloadWithoutImage } = payload; // Destructure the property_img from the payload

  // Example of making an authenticated request using fetch
  const authToken = localStorage.getItem("authToken");

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      apikey: "abc123",
    },
    body: JSON.stringify(payloadWithoutImage), // Use the payload without the property_img
  };

  const response = await fetch(
    `${BASE_URL}/addDraftLot`, 
    options
  );

  const data = await response.json();

  // If the first POST request is successful, make the second POST request to localhost
  if (response.ok) {
    // const uploadResponse = await fetch(`${BASE_URL}/loadImage", {
    const uploadResponse = await fetch("https://vertobackendapp.azurewebsites.net/loadImage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "abc123",
        apikey: "abc123",
      },
      body: JSON.stringify({ productID: data.productID, property_img }),
    });
    
    if (uploadResponse.status === 413){
      throw new Error("Picture is too large");
    }
  }

  return data;
};

export const DeleteDraftPropertiesAPI = async (payload) => {
  const { property_img, ...payloadWithoutImage } = payload; // Destructure the property_img from the payload

  // Example of making an authenticated request using fetch
  const authToken = localStorage.getItem("authToken");

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      apikey: "abc123",
    },
    body: JSON.stringify(payloadWithoutImage), // Use the payload without the property_img
  };

  const response = await fetch(
    `${BASE_URL}/deleteDraftLot`, 
    options
  );

  const data = await response.json();

  return data;
};

export const loadLotImageAPI = async (payload) => {

  // Example of making an authenticated request using fetch
  const authToken = localStorage.getItem("authToken");

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      apikey: "abc123",
    },
    body: JSON.stringify(payload), // Use the payload without the property_img
  };

  const response = await fetch(
    `${BASE_URL}/loadImage`, 
    options
  );

  const data = await response.json();

  return data;
};

export const CreateNFTAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    "https://2v445l6fn75glpuq2nuwihq4wu0tmzjk.lambda-url.ap-southeast-1.on.aws/createnfttoken",
    options
  ).then((res) => res.json());
};

export const getAccountIdApi = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify({
      userId: payload,
    }),
  };
  return await fetch(
    "https://2v445l6fn75glpuq2nuwihq4wu0tmzjk.lambda-url.ap-southeast-1.on.aws/getaccountinfo",
    options
  ).then((res) => res.json());
};

export const addCommercialContractAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/addcommercialcontract",
    options
  ).then((res) => res.json());
};

export const createCommercialContractAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/createcommercial",
    options
  ).then((res) => res.json());

};

export const allSubjectsLiftedAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/allsubjectslifted",
    options
  ).then((res) => res.json());
};

export const createContractAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/initcontract",
    options
  ).then((res) => res.json());
};

export const sellerSignAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/sellersign",
    options
  ).then((res) => res.json());
};

export const buyerSignAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/buyersign",
    options
  ).then((res) => res.json());
};

export const updateCommercialAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/updatecommercial",
    options
  ).then((res) => res.json());
};

export const confirmBySellerAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/confirmbyseller",
    options
  ).then((res) => res.json());
};

export const confirmByBuyerAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/confirmbybuyer",
    options
  ).then((res) => res.json());
};

export const signUpFormApi = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    `${BASE_URL}/signUp`, 
    options
  ).then((res) => res.json());
};