import { Box, Button, Container, Grid, Stack, TextField, Typography, styled } from "@mui/material"
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import BasicTabs from "../components/WalletTabs";
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchRecentTransaction, fetchUserAccount } from '../Redux/properties/PropertiesSlice';
import { useParams } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import { Helmet } from "react-helmet";

import { IntlProvider, FormattedMessage } from "react-intl";

export const Wallet = () => {

    const { userId, walletId } = useParams();
    const [entereduserId, setEnteredUserId] = useState("");
    const [hedera, setHedera] = useState("");
    const [level, setLevel] = useState(0)

    const CustomeGrid = styled(Grid)(({ theme }) => ({
        height: "unset",
        width: "unset"

    }));

    const signIn = () =>{

        if(entereduserId && hedera){
            window.location.assign("/wallet/"+entereduserId+"/"+ hedera);
        }
    }


    return (
        <>
            <Helmet>
                <title><FormattedMessage id="wallet.title" /></title>
                <meta name="description" content="Wallet" />

            </Helmet>
            <Grid container sx={{ height: "100vh", backgroundColor: "rgb(7,7,16)", color: "white" }}>

                {userId &&
                    <Grid item sx={{ height: "30%" }}>
                        <AccountSummary id={userId} wallet={walletId} />
                    </Grid>
                }

                {!userId && <Box sx={{ height: "250px", width: "500px", marginTop: "10%", marginLeft: "30%", backgroundColor: "rgb(17,17,38)" }} alignContent={"center"}>
                    <CustomeGrid container direction={"column"}>
                        <CustomeGrid item sx={{ textAlign: "center" }}>

                            <span style={{ fontWeight: "bolder", fontSize: "16px" }}><FormattedMessage id="userInfo.userdetails" /></span>
                        </CustomeGrid>
                        <CustomeGrid item>
                            {level === 0 && <TextField id="outlined-basic" value={entereduserId}
                                
                                sx={{ backgroundColor: "white", width: "80%", marginLeft: "50px", marginTop: "10px" }}
                                onChange={(e) => setEnteredUserId(e.target.value)}
                                autoFocus
                                label={<FormattedMessage id="userInfo.userID" />} variant="outlined" />}
                        </CustomeGrid>
                        <CustomeGrid item>
                            {level !== 0 && <TextField id="outlined-basic" value={hedera}
                                sx={{ backgroundColor: "white", width: "80%", marginLeft: "50px", marginTop: "5px" }}
                                onChange={(e) => setHedera(e.target.value)}
                                autoFocus
                                label={<FormattedMessage id="wallet.ledger.hedera" />} variant="outlined" />}
                        </CustomeGrid>
                        <CustomeGrid item>
                            <Button onClick={() =>{ level === 0 ? setLevel(1) : signIn()}} sx={{ width: "300px", color: "white", backgroundColor: "rgb(7,7,16)", marginRight: "20px", marginLeft: "120px", marginTop: "25px" }} variant="contained">
                                {level === 0 ? "Next": "Ok"}
                            </Button>
                        </CustomeGrid>
                    </CustomeGrid>
                </Box>
                }

            </Grid>
        </>
    )
}


const AccountSummary = ({ id = "testseller2", wallet = "0.0.48903965" }) => {
    const CustomeGrid = styled(Grid)(({ theme }) => ({
        height: "unset",
        width: "unset"

    }))

    const CustomStack = styled(Stack)(({ theme }) => ({
        width: "unset"

    }))

    const [fetched, setFetched] = React.useState(false);
    const dispatch = useDispatch();
    const realEstateData = useSelector((state) => state.properties)



    React.useEffect(() => {
        if (!fetched) {
            setFetched(true);

            dispatch(fetchUserAccount(id))
            dispatch(fetchRecentTransaction(wallet))

        }
    }, [fetched])




    return (
        <CustomeGrid container spacing={2} direction={"column"}>

            <CustomeGrid item>
                <CustomStack sx={{ paddingLeft: "250px" }} direction={"row"} spacing={1}>
                    <PersonIcon fontSize="large" sx={{ color: "white", height: "50px" }} />
                    <div>
                        <CustomStack direction={"column"}>
                            <span style={{ fontWeight: "bolder" }}> {id?.toLocaleUpperCase()}</span>
                            <span style={{ fontWeight: "bolder", fontSize: "12px", color: "wheat" }}> {realEstateData?.userDetails?.details?.accInfo?.accountId}</span>

                        </CustomStack>

                    </div>

                </CustomStack>
            </CustomeGrid>

            <CustomeGrid item>
                <CustomStack sx={{ paddingLeft: "270px", fontSize: "25px" }} direction={"column"} >
                    <span> {realEstateData?.userDetails?.details?.accInfo?.balance}</span>
                    <span style={{ fontWeight: "bolder", fontSize: "12px" }}> <FormattedMessage id="wallet.hbars" /></span>


                </CustomStack>
            </CustomeGrid>
            <CustomeGrid item>
                <BasicTabs id={id} />
            </CustomeGrid>
            <CustomeGrid item>
                <CustomStack sx={{ marginTop: "25px" }} alignItems={"center"} direction={"row"}>
                    <Button sx={{ width: "300px", color: "white", backgroundColor: "rgb(17,17,38)", marginRight: "20px", marginLeft: "400px" }} variant="contained">
                        <FormattedMessage id="button.send" />
                    </Button>
                    <Button sx={{ width: "300px", color: "white", backgroundColor: "rgb(17,17,38)" }} variant="contained">
                      <FormattedMessage id="button.receive" />
                    </Button>
                </CustomStack>
            </CustomeGrid>
        </CustomeGrid>
    )
}   