import { Autocomplete, Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductsList } from "../Redux/products/slice";
import { getCategories, getProductNames } from "../Redux/options/slice";

import { FormattedMessage, useIntl } from 'react-intl';

const style = {
  comboStyle: {
    width: 200,

  },
  innerCombo: {
    backgroundColor: "white",
    width: "90%",
    borderRadius: 2,
    opacity: 0.9,

  },
  container: {
    // marginLeft: "50vh"
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "75%",
    columnGap: "20px",
    backgroundColor: "white",
    padding: "1rem 0.5rem",
    borderRadius: "5px",
    input: {
      borderRadius: "5px"
    },
    padding: '10px'
  },
  btnStyle: {
    backgroundColor: "rgb(91,166,0)",
    width: "10vw",
    color: "white",
    padding: '10px'
    // height: "8vh",

  },
}
const FilterComponent = ({ setFilter, filter }) => {

  const { categories } = useSelector((state) => state.options);
  const { productsList } = useSelector((state) => ({
    productsList: state.products.productsList.slice().sort((a, b) => a.label.localeCompare(b.label)),
  }));

  const filteredProducts = productsList.filter(product => {
    const categoryId = categories.find(cat => cat.id === filter?.UPC)?.id;
    return product.id_category === categoryId;
  });

  return (
    <Grid container direction={"row"} sx={style.container}>

      <Grid item sx={{ ...style.comboStyle, py: 2 }}>
        <FormControl sx={{ m: 0, minWidth: 120 }}>
          <Select
            native
            value={filter?.category}
            displayEmpty
            onChange={(e) => {
              const selectedCategory = categories.find(cat => cat.categoryName === e?.target?.value);
              setFilter(s => ({
                ...s,
                category: e?.target?.value,
                UPC: selectedCategory?.id || ''
              }));
            }}
            input={<OutlinedInput label="Category" id="demo-dialog-native" />}
          >
            <option value="">
              {filter?.category ? '' : <FormattedMessage id="userInput.filter.category" />}
            </option>
            {categories.map(e => {
              return <option key={e.id} value={e.categoryName}>{e.categoryName}</option>
            })}
          </Select>
        </FormControl>
      </Grid>
      {/* <Grid item sx={{ ...style.comboStyle, py: 2 }}>
        <FormControl sx={{ m: 0, minWidth: 120 }}>
          <Select
            native
            value={filter?.product}
            displayEmpty
            onChange={(e) => {
              const selectedProduct = filteredProducts.find(product => product.id === parseInt(e.target.value));
              setFilter(s => ({ ...s, product: e.target.value, UPC: selectedProduct?.id_category || '', product_name_id: selectedProduct?.id }));
            }}
            input={<OutlinedInput label="Product" id="demo-dialog-native" />}
          >
            <option value="">
            {filter?.category ? '' : <FormattedMessage id="userInput.filter.product" />}
            </option>
              {filteredProducts.map(e => {
                return <option value={e.id} key={e.id}>{e.label}</option>
              })}
          </Select>
        </FormControl>
      </Grid> */}
    </Grid>
  )
}


export default FilterComponent;