import React, { useState, useEffect } from 'react';
import {
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { saveProperties, saveDraftProperties } from '../Redux/properties/PropertiesSlice';
import CreatePropertiesModal from '../components/CreatePropertiesModal';
import MobileMyProducts from '../components/MobileMyProducts';
import MobileMyContracts from '../components/MobileMyContract';
import MobileMyPersonalSpace from '../components/MobileMyPersonalSpace';
import MobileMyFarm from '../components/MobileMyFarm';
import MobileMenuPanel from '../components/MobileMenuPanel';
import logo from '../assets/Logo.png';
import MobileFooter from '../components/MobileFooter';
import MobileSecondFooter from '../components/MobileSecondFooter';
import { logout } from '../Redux/user/slice';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastProperties } from '../utils/constants/toastProperties';

import { IntlProvider, FormattedMessage } from "react-intl";

const MobileMyList = () => {
  const [menu, setMenu] = useState([]);
  const props = useSelector((state) => state.properties);
  const prodStore = useSelector((state) => state.products);
  const currentComp = localStorage.getItem('comp');
  const [active, setActive] = useState('');

  const handleMenuClick = (item) => {
    setActive(item.id === active ? '' : item.id); // Toggle the active state
    localStorage.setItem('comp', item.id);
    setModal(item.id === 'createnew');
  };

  useEffect(() => {
    const menuData = [
      { label: <FormattedMessage id="mylist.navbar.products" />, id: 'myproducts', component: <MobileMyProducts /> },
      { label: <FormattedMessage id="mylist.navbar.cart" />, id: 'mycontracts', component: <MobileMyContracts /> },
      { label: <FormattedMessage id="mylist.navbar.newLot" />, id: 'createnew' },
      { label: <FormattedMessage id="mylist.navbar.userSettings" />, id: 'mypersonalspace', component: <MobileMyPersonalSpace /> },
      { label: <FormattedMessage id="mylist.navbar.myFarm" />, id: 'myfarm', component: <MobileMyFarm /> },
    ];

    const updatedMenu = menuData.map((item) => ({
      ...item,
      active: item.id === active,
    }));

    setMenu(updatedMenu);
  }, [active]);

  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);

  const onSave = async (obj, cb) => {
    try {
      await dispatch(saveProperties({ payload: obj, cb }));

    } catch (error) {
      // Handle errors from the save operation

      // Show error toast
      showToast('error', 'Error saving product');
    }
  };

  const onDraftSave = (obj, cb) => {
    try {
      dispatch(saveDraftProperties({ payload: obj, cb }));

    } catch (error) {
      // Handle errors from the save operation

      // Show error toast
      showToast('error', 'Error saving product draft');
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    localStorage.clear('userLogged');
    localStorage.clear('userinfo');

    if (window.location.pathname === '/my-list') {
      window.location.href = '/';
    }
  };

  useEffect(() => {
    setActive(""); // Reset the active state to an empty string when the component mounts
  }, []);

  const listItemStyle = {
    padding: '5px',
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F5F5F5', // Change the background color here
    borderBottom: '1px solid #ccc',
  };

  const activeItemStyle = {
    ...listItemStyle,
    backgroundColor: '#E6F0F5', // Change the active background color here
  };

  const user = useSelector((state) => state.user.userInfo);

  const showToast = (type, message) => {
    // Use the toast function to display the appropriate toast message
    if (type === 'success') {
      toast.success(message, {
        ...ToastProperties
      });
    } else if (type === 'error') {
      toast.error(message, {
        ...ToastProperties
      });
    }
  };

  return (
    <>
      <MobileMenuPanel></MobileMenuPanel>
      <div style={{ padding: '5px', backgroundColor: '#FFA500', fontSize: '0.8em', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '30px', fontWeight: 'bold', color: 'white' }}>
        {user && (
          <>
            <span style={{ width: '50%', textAlign: 'center' }}><FormattedMessage id="userInfo.userID" />{user.userId}</span>
            <span style={{ width: '50%', textAlign: 'center' }}><FormattedMessage id="userInfo.address" />{user.accountId}</span>
          </>
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{display: 'flex', flexDirection: 'column' , backgroundColor: '#F5F5F5' }}>
          <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
            {menu.map((item) => (
              <li
                key={item.id}
                style={item.id === active ? activeItemStyle : listItemStyle}
              >
                <div>
                  <div
                    onClick={() => {
                      handleMenuClick(item);
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: item.id === active ? '#E6F0F5' : '#F5F5F5',
                    }}>
                    {item.id === active ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
                    {item.label}
                  </div>
                    {item.id === active && (
                      <div  style={{backgroundColor:  '#F5F5F5', paddingTop: '20px', marginTop: '5px'}}>
                        {item.component}
                      </div>
                    )}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div style={{ width: '100%' }}>
          <Backdrop open={props.fetching || prodStore.fetching} style={{ zIndex: 1500 }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </div>
      <CreatePropertiesModal
        modal={modal}
        toggle={() => setModal(false)}
        onSave={(obj, cb) => onSave(obj, cb)}
        onDraftSave={(obj, cb) => onDraftSave(obj, cb)}
      />
      {/* <ToastContainer
        style={{height: "30px", maxWidth: "300px",fontSize: "0.7em", position: "top-center"}}
        position="top-center"
        autoClose= {5000}
        hideProgressBar= {false}
        closeOnClick= {true}
        pauseOnHover= {true}
        draggable= {true}
        progress= {undefined}
        theme="light"
        /> */}
      <MobileSecondFooter />
      <MobileFooter />
    </>
  );
};

export default MobileMyList;