import React from "react";
import { Container, Nav } from "react-bootstrap";
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="bg-lightGrey" style={{ borderTop: "1px solid #f2f2f2", backgroundColor: "#f2f2f2", width:"100%"}}>
            <Container>
            <Nav className="justify-content-center">
                <Navbar.Brand style={{ fontSize: "0.8rem" }}>
                    &copy; Verto - 2023: vertocommodities.io
                </Navbar.Brand>
            </Nav>
            </Container>
        </footer>
    );
}
export default Footer;