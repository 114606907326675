import { createSlice } from "@reduxjs/toolkit";

const languageSlice = createSlice({
  name: "language",
  initialState: JSON.parse(localStorage.getItem("language")) || "en", // Default language is English
  reducers: {
    setLanguage: (state, action) => {
      // console.log("setLanguage SLICE", state, action);
      // state = action.payload;
      return action.payload;
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;