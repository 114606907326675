import { useParams } from "react-router-dom";
import FilterComponent from "../components/FilterComponent";
import ItemCard from "../components/ItemCard";
import LocationDiv from "../components/LocationDiv";
import MenuPanel from "../components/MenuPanel";
import TitleDiv from "../components/TitleDiv";
import { Paper, Text, Container, Grid, Button, Image, Badge } from '@mantine/core';
import { useSelector, useDispatch } from "react-redux";
import "./HomePage.css";
import { useEffect, useMemo, useState } from "react";
import { getProducts } from "../Redux/CounterSlice";
import {
  fetchUserAccount,
  getPropertiesReducer,
  walletBalanceFetch,
} from "../Redux/properties/PropertiesSlice";

import {
  CURRENT_PURPOSE,
  WEBSITE_CONSTANTS,
  WEBSITE_FARM_TRADE,
  WEBSITE_REAL_ESTATE,
} from "../configs/website-config";
import RealEstateMediaCard from "../components/RealEstateItem";
import { HashConnect } from "hashconnect";
import SalePanel from "../components/SalePanel";
import { LoginModal } from "../components/LoginModal";
import MobileMenuPanel from "../components/MobileMenuPanel"; 
import MobileFooter from "../components/MobileFooter";
import MobileSecondFooter from "../components/MobileSecondFooter";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { FormattedMessage, useIntl } from 'react-intl';


// const backgroundImage =
//   CURRENT_PURPOSE !== WEBSITE_REAL_ESTATE
//     ? "../assets/hall-hd.jpg"
//     : "../assets/summer.png";
const backgroundImage =
  CURRENT_PURPOSE === WEBSITE_REAL_ESTATE
    ? "../assets/bg.jpeg"
    : "../assets/summer.png";

const style = {
  papaerStyle: {
    width: "100%",
    backgroundColor: '#F8F8FF', // Set the desired background color
    // backgroundImage: "url('" + backgroundImage + "')",
    padding: '16px', // Add padding to create space between elements
    overflow: 'hidden', // Hide overflowing content
  },
  gridStyle: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center", // Adjusted to center align
  },
  dummBar: {
    color: "#333333",
    textAlign: "center",
    fontWeight: "bolder",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: '16px',
    width: '100%', // Ensures the bar takes full width
    boxSizing: 'border-box', // Includes padding in the width
  },
  addressBar: {
  },
  menuBar: {
    width: "100%",
    padding: '16px', // Add padding to create space between elements
  },
  titleBar: {
    color: '#1a488f', // Set the title color
    fontSize: '1.5em', // Define the title font size
    // margin: '8px 0', // Margin around the title
  },
  dummBar1: {
    color: "#ffffff",
    textAlign: "center",
    fontWeight: "bolder",
  },
  lineDiv: {
    color: "#ffffff",
    textAlign: "center",
    fontWeight: "bolder",
    marginTop: "2%",
  },
  Filter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px', // Add padding to create space between elements
  },
  items: {
    height: "auto",
    padding: '16px', // Add padding to create space between elements
  },
  whole: {
    height: "100%",
    width: "100%",
    overflow: "auto", 
    display: "flex",
  },
};

const MobileMarketplace = () => {
  let { level } = useParams();
  const [filter, setFilter] = useState({ category: '', product: '' })
  const products = useSelector((state) => state.properties.data);

  const productsFilter = useMemo(() => {
    return products.filter(e => {
      const productFilter = filter.category
        ? e?.product_name_id === filter.UPC
        : true;

      const upcFilter = filter.category
      ? e?.product_name_id === filter.UPC
      : true;

      return productFilter && upcFilter;
    });
  }, [products, filter]);

  const dispatch = useDispatch();
  const [salePanelModal, setSalePanelModal] = useState(false);
  const [saleSelected, setSaleSelected] = useState();

  useEffect(() => {
    if (saleSelected) console.log("saleSelected", saleSelected);
    setSalePanelModal(true);
  }, [saleSelected]);
  const fetchWallet = () => {
    if (localStorage.getItem("userLogged")) {
      dispatch(fetchUserAccount(localStorage.getItem("userLogged")));
    } else {
      dispatch(fetchUserAccount("testbuyer1"));
    }
  };

  return (
    <>
        {saleSelected && (
          <SalePanel
            modal={salePanelModal}
            toggle={() => {
              setSalePanelModal(false);
              setSaleSelected(undefined);
            }}
            title={saleSelected?.title}
            value={saleSelected}
          >
            {" "}
          </SalePanel>
        )}
        <div className="whole" style={style.whole}>
        <Paper sx={style.papaerStyle} className="backgroundImage">
          <Grid container sx={style.gridStyle}>
            <Grid item sx={style.menuBar}>
              <MobileMenuPanel level={level}/>
            </Grid>
            <Grid item sx={style.dummBar}>
              <h3>{WEBSITE_CONSTANTS.QUOTE1}</h3>
            </Grid>
            <Grid item sx={style.Filter}>
              <FilterComponent setFilter={setFilter} filter={filter} />
            </Grid>
            <Grid item sx={style.items}>
              <Items selectedSale={setSaleSelected} products={productsFilter}/>
            </Grid>
              <MobileSecondFooter />
              <MobileFooter />
          </Grid>
        </Paper>
        </div>
        <LoginModal />
        <ToastContainer
          style={{height: "30px", width: "250px",fontSize: "0.7em", position: "top-center"}}
          position="top-center"
          autoClose= {5000}
          hideProgressBar= {false}
          closeOnClick= {true}
          pauseOnHover= {true}
          draggable= {true}
          progress= {undefined}
          theme="light"
          />
    </>
  );
};

const Items = ({ selectedSale, products }) => {

  const dispatch = useDispatch();

  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (!fetched) {
      setFetched(true);
      loadDetails();
    }
  }, [fetched]);

  const loadDetails = async () => {
    if (CURRENT_PURPOSE === WEBSITE_REAL_ESTATE) {
      dispatch(getPropertiesReducer());
    } else {
      dispatch(getProducts());
    }
  };

  const ItemsStyle = {
    gridItem: {
      // width: "100%", // Adjusted to display as single column
      // padding: "20px 0", // Adjusted padding
      width: "100%", // Adjusted to display as single column
      padding: "0 0", // Adjusted padding
    },
  };

  const getImages = (productID) => {
    const imageUrl = `https://vertoledgerstorage.blob.core.windows.net/sqldbledgerdigests/images/${productID}.png`;
    return imageUrl;
  };

  const [selectedProduct, setSelectedProduct] = useState(null);
  const openSalePanel = (product) => {
    setSelectedProduct(product);
    selectedSale(product);
  };

  return (
    <Container size="md">
      <Paper padding="md" shadow="xs" style={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
        {products
          .filter(product => product.publishId === 1) // Filters the products by their 'publish' status
          .map((product, index) => (
            <div>
             <div key={index} style={{ display: 'flex', marginBottom: '10px', border: '1px solid #ccc', borderRadius: '5px', padding: '10px' }}>
              <div style={{ width: '20%', position: 'relative', overflow: 'hidden', backgroundColor: 'white' }}>
                <img
                  src={getImages(product.productRefID)}
                  style={{
                    width: '100%',
                    height: 'auto',
                    marginBottom: '10px'
                  }}
                />
                <Button  size="compact-md"
                  variant="outline"
                  onClick={() => openSalePanel(product)}
                  style={{ width: '100%', fontSize: 'calc(0.5em + 0.5vw)', marginBottom: '10px' }}
                >
                  <FormattedMessage id="button.viewdetails" />
                </Button>
              </div>
              <div style={{ width: '80%', padding: '8px', display: 'flex' }}>
                <div style={{  display: 'flex', flexDirection: 'column', justifyContent: 'left', leftMargin: '8px' }}>
                  <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}>{product.productName}</p>
                  <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}><FormattedMessage id="product.quantity1" />{product.quantity}</p>
                  <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}><FormattedMessage id="product.skuid1" />{product.sku}</p>
                  <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}><FormattedMessage id="product.location1" />{product.location}</p>
                  <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}><FormattedMessage id="product.deliverprd1" />{product.period}</p>
                  <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}><FormattedMessage id="product.price1" />{product.price}</p>
                </div>
              </div>
            </div> 
            {/* <div>
              <Grid item  sx={ItemsStyle.realEstateMediaCard} key={index}>
                <RealEstateMediaCard selectedSale={selectedSale} item={product} />
              </Grid>
            </div> */}
            </div>
          ))}
      </Paper>
        {selectedProduct && (
          <SalePanel
            modal={true} // Set to true to display the panel
            toggle={() => {
              setSelectedProduct(null);
              selectedSale(undefined);
            }}
            title={selectedProduct.title} // Assuming 'title' exists in the product object
            value={selectedProduct}
          >
            {/* Display additional details of the selected product inside SalePanel */}
          </SalePanel>
        )}
    </Container>
  );
};

export default MobileMarketplace;