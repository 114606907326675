import React, { useState } from "react";
import {
  Box,
  Text,
  Heading,
  Button,
  Image,
  Link as ChakraLink,
  ListItem,
  UnorderedList,
  useMediaQuery,
  Stack,
  Container,
  SimpleGrid,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Alert,
  AlertIcon,
  Icon,
  useColorModeValue,
  ChakraProvider,
} from "@chakra-ui/react";

import { motion } from "framer-motion";

import {
  FaBook,
  FaClipboardCheck,
  FaExclamationTriangle,
  FaLightbulb,
  FaQuestionCircle,
  FaTools,
} from "react-icons/fa";

import { CheckCircleIcon } from "@chakra-ui/icons";

import MenuPanel from "../components/MenuPanel";
import SecondFooter from "../components/SecondFooter";
import Footer from "../components/Footer";
import MobileMenuPanel from "../components/MobileMenuPanel";
import MobileSecondFooter from "../components/MobileSecondFooter";
import MobileFooter from "../components/MobileFooter";
import { LoginModal } from "../components/LoginModal";

import EcuacaoLogo from "../assets/ecuacao-crest@3x-8.png";
import GenerosoLogo from "../assets/logo_generoso.png";
import LogoAppleStore from "../assets/available-on-the-app-store.svg";
import LogoGoogleStore from "../assets/google-play-badge-2022-2.svg";
import gptImage from "../assets/images/gptImage.jpeg";

import { FormattedMessage } from "react-intl";

import "@fontsource/raleway/400.css";
import "@fontsource/raleway/500.css";
import "@fontsource/raleway/600.css";
import "@fontsource/arimo/400.css";
import "@fontsource/playfair-display";

const googlePlayUrl =
  "https://play.google.com/store/apps/details?id=com.vertocommodities.gpsapp&pcampaignid=web_share";
const appleStoreUrl = "https://apps.apple.com/ch/app/verto-gps-app/id6651856518?l=en-GB";
const generosoUrl = "https://genereso.com";

const MotionBox = motion(Box);

const ChatbotFrame = ({ botId, clientId, webhookId, height, width }) => {
  const iframeSrc = `
    <body>
      <script src='https://cdn.botpress.cloud/webchat/v1/inject.js'></script>
      <script>
        window.botpressWebChat.init({
          'composerPlaceholder': '${"Chat with EUDR specialist bot"}',
          'botConversationDescription': '${"This chatbot was built by Verto"}',
          'botId': '${botId}',
          'hostUrl': 'https://cdn.botpress.cloud/webchat/v1',
          'messagingUrl': 'https://messaging.botpress.cloud',
          'clientId': '${clientId}',
          'webhookId': '${webhookId}',
          'lazySocket': true,
          'themeName': 'prism',
          'frontendVersion': 'v1',
          'showPoweredBy': true,
          'theme': 'prism',
          'themeColor': '#2563eb'
        });

        window.addEventListener('message', function(event) {
          if (event.data === 'openChat') {
            window.botpressWebChat.sendEvent({ type: 'show' });
          }
        }, false);
      </script>
    </body>
  `;

  return (
    <Box
      as="iframe"
      id="chatbot-iframe"
      border="none"
      width={width}
      height={height}
      srcDoc={iframeSrc}
      onLoad={() => console.log("Iframe loaded and ready")}
    />
  );
};

const SideNav = ({ isMobile }) => {
  const linkHoverColor = useColorModeValue("blue.600", "blue.300");
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.700", "gray.200");

  return (
    <>
      {!isMobile && (
        <Box
          position="sticky"
          top="20"
          width="250px"
          p="6"
          bg={bgColor}
          borderWidth="1px"
          borderRadius="md"
          boxShadow="md"
          height="fit-content"
          alignSelf="flex-start"
        >
          <Text fontSize="xl" fontWeight="bold" mb="6">
            <FormattedMessage id="sideNav.title" />
          </Text>
          <UnorderedList styleType="none" m="0" spacing="4">
            <ListItem>
              <ChakraLink
                href="#introduction"
                fontSize="md"
                display="flex"
                alignItems="center"
                color={textColor}
                _hover={{ color: linkHoverColor }}
              >
                <Icon as={FaBook} mr="3" />
                <FormattedMessage id="sideNav.introduction" />
              </ChakraLink>
            </ListItem>
            <ListItem>
              <ChakraLink
                href="#impact"
                fontSize="md"
                display="flex"
                alignItems="center"
                color={textColor}
                _hover={{ color: linkHoverColor }}
              >
                <Icon as={FaQuestionCircle} mr="3" />
                <FormattedMessage id="sideNav.impact" />
              </ChakraLink>
            </ListItem>
            <ListItem>
              <ChakraLink
                href="#criteria"
                fontSize="md"
                display="flex"
                alignItems="center"
                color={textColor}
                _hover={{ color: linkHoverColor }}
              >
                <Icon as={FaClipboardCheck} mr="3" />
                <FormattedMessage id="sideNav.criteria" />
              </ChakraLink>
            </ListItem>
            <ListItem>
              <ChakraLink
                href="#sanctions"
                fontSize="md"
                display="flex"
                alignItems="center"
                color={textColor}
                _hover={{ color: linkHoverColor }}
              >
                <Icon as={FaExclamationTriangle} mr="3" />
                <FormattedMessage id="sideNav.sanctions" />
              </ChakraLink>
            </ListItem>
            <ListItem>
              <ChakraLink
                href="#challenges"
                fontSize="md"
                display="flex"
                alignItems="center"
                color={textColor}
                _hover={{ color: linkHoverColor }}
              >
                <Icon as={FaLightbulb} mr="3" />
                <FormattedMessage id="sideNav.challenges" />
              </ChakraLink>
            </ListItem>
            <ListItem>
              <ChakraLink
                href="#solution"
                fontSize="md"
                display="flex"
                alignItems="center"
                color={textColor}
                _hover={{ color: linkHoverColor }}
              >
                <Icon as={FaTools} mr="3" />
                <FormattedMessage id="sideNav.solution" />
              </ChakraLink>
            </ListItem>
          </UnorderedList>
        </Box>
      )}
    </>
  );
};

const ChatbotContent = ({ isMobile }) => {
  const [isChatbotVisible, setIsChatbotVisible] = useState(false);

  const handleButtonClick = () => {
    setIsChatbotVisible(true);
    setTimeout(() => {
      const iframe = document.getElementById("chatbot-iframe");
      if (iframe) {
        iframe.contentWindow.postMessage("openChat", "*");
        iframe.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);
  };

  return (
    <Box p="0" m="0">
      {/* Image at the top */}
      <Box position="relative">
        <Image
          src={gptImage}
          alt={<FormattedMessage id="chatbotContent.topImageAlt" />}
          width="100vw"
          height="50vh"
          objectFit="cover"
        />
      </Box>

      {/* Main content with side navigation */}
      <Container maxW="6xl" py="10" display={{ base: "block", md: "flex" }}>
        <SideNav isMobile={isMobile} />
        <Box flex="1" ml={{ base: "0", md: "10" }}>
          <MotionBox
            id="introduction"
            bg="gray.50"
            py="10"
            px="6"
            borderRadius="md"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <Heading as="h1" size="xl" textAlign="center" mb="6" fontFamily="Playfair Display">
              <FormattedMessage id="chatbotContent.intro.title" />
            </Heading>
            <Text fontSize="lg" textAlign="justify" mb="6">
              <FormattedMessage id="chatbotContent.intro.text" />
            </Text>
          </MotionBox>

          {/* Content Sections */}
          <Stack spacing="10" mt="10">
            {/* Does the EUDR impact your commodity? */}
            <Box id="impact" bg="white" py="8" px="6" borderRadius="md" boxShadow="sm">
              <Heading as="h2" size="lg" mb="4">
                <FormattedMessage id="chatbotContent.impact.title" />
              </Heading>
              <Text fontSize="md" textAlign="justify" mb="4">
                <FormattedMessage id="chatbotContent.impact.text1" />
              </Text>
              <UnorderedList spacing="2" ml="6" styleType="none">
                <ListItem>
                  <Icon as={CheckCircleIcon} color="green.500" mr="2" />
                  <FormattedMessage id="chatbotContent.impact.listItem1" />
                </ListItem>
                <ListItem>
                  <Icon as={CheckCircleIcon} color="green.500" mr="2" />
                  <FormattedMessage id="chatbotContent.impact.listItem2" />
                </ListItem>
              </UnorderedList>
            </Box>

            {/* What criteria must you meet to adhere to the EUDR? */}
            <Box id="criteria" bg="gray.50" py="8" px="6" borderRadius="md">
              <Heading as="h2" size="lg" mb="4">
                <FormattedMessage id="chatbotContent.criteria.title" />
              </Heading>
              <Text fontSize="md" textAlign="justify" mb="4">
                <FormattedMessage id="chatbotContent.criteria.text1" />
              </Text>
              <UnorderedList spacing="2" ml="6" styleType="none">
                <ListItem>
                  <Icon as={CheckCircleIcon} color="green.500" mr="2" />
                  <FormattedMessage id="chatbotContent.criteria.listItem1" />
                </ListItem>
                <ListItem>
                  <Icon as={CheckCircleIcon} color="green.500" mr="2" />
                  <FormattedMessage id="chatbotContent.criteria.listItem2" />
                </ListItem>
                <ListItem>
                  <Icon as={CheckCircleIcon} color="green.500" mr="2" />
                  <FormattedMessage id="chatbotContent.criteria.listItem3" />
                </ListItem>
              </UnorderedList>
            </Box>

            {/* What sanctions could you face? */}
            <Box id="sanctions" bg="white" py="8" px="6" borderRadius="md" boxShadow="sm">
              <Heading as="h2" size="lg" mb="4">
                <FormattedMessage id="chatbotContent.sanctions.title" />
              </Heading>
              <Text fontSize="md" textAlign="justify" mb="4">
                <FormattedMessage id="chatbotContent.sanctions.text1" />
              </Text>
              <Alert status="warning" variant="left-accent" mb="4">
                <AlertIcon />
                <Text fontSize="md">
                  <Text as="span" fontWeight="bold">
                    <FormattedMessage id="chatbotContent.sanctions.alert1.bold" />
                  </Text>{" "}
                  <FormattedMessage id="chatbotContent.sanctions.alert1.text" />
                </Text>
              </Alert>
              <Alert status="warning" variant="left-accent">
                <AlertIcon />
                <Text fontSize="md">
                  <Text as="span" fontWeight="bold">
                    <FormattedMessage id="chatbotContent.sanctions.alert2.bold" />
                  </Text>{" "}
                  <FormattedMessage id="chatbotContent.sanctions.alert2.text" />
                </Text>
              </Alert>
            </Box>

            {/* Why is the EUDR challenging? */}
            <Box id="challenges" bg="gray.50" py="8" px="6" borderRadius="md">
              <Heading as="h2" size="lg" mb="4">
                <FormattedMessage id="chatbotContent.challenges.title" />
              </Heading>
              <Accordion allowToggle>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <FormattedMessage id="chatbotContent.challenges.accordion1.title" />
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Text fontSize="md" textAlign="justify">
                      <FormattedMessage id="chatbotContent.challenges.accordion1.text" />
                    </Text>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <FormattedMessage id="chatbotContent.challenges.accordion2.title" />
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <UnorderedList spacing="2" ml="6">
                      <ListItem>
                        <Text as="span" fontWeight="bold">
                          <FormattedMessage id="chatbotContent.challenges.accordion2.listItem1.bold" />
                        </Text>{" "}
                        <FormattedMessage id="chatbotContent.challenges.accordion2.listItem1.text" />
                      </ListItem>
                      <ListItem>
                        <Text as="span" fontWeight="bold">
                          <FormattedMessage id="chatbotContent.challenges.accordion2.listItem2.bold" />
                        </Text>{" "}
                        <FormattedMessage id="chatbotContent.challenges.accordion2.listItem2.text" />
                      </ListItem>
                      <ListItem>
                        <Text as="span" fontWeight="bold">
                          <FormattedMessage id="chatbotContent.challenges.accordion2.listItem3.bold" />
                        </Text>{" "}
                        <FormattedMessage id="chatbotContent.challenges.accordion2.listItem3.text" />
                      </ListItem>
                      <ListItem>
                        <Text as="span" fontWeight="bold">
                          <FormattedMessage id="chatbotContent.challenges.accordion2.listItem4.bold" />
                        </Text>{" "}
                        <FormattedMessage id="chatbotContent.challenges.accordion2.listItem4.text" />
                      </ListItem>
                    </UnorderedList>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>

            {/* Learn More Button */}
            <Box textAlign="center" my="10">
              <Button
                onClick={handleButtonClick}
                size="lg"
                bgGradient="linear(to-r, teal.400, blue.500)"
                color="white"
                _hover={{ bgGradient: "linear(to-r, teal.500, blue.600)" }}
                boxShadow="xl"
              >
                <FormattedMessage id="chatbotContent.learnMoreButton" />
              </Button>
            </Box>

            {/* Solution Section */}
            <Box id="solution" bg="white" py="8" px="6" borderRadius="md" boxShadow="sm">
              <Heading as="h2" size="lg" mb="4">
                <FormattedMessage id="chatbotContent.solution.title" />
              </Heading>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing="6" gridTemplateColumns={{ md: "2fr 1fr" }}>
                <Box>
                  <Text fontSize="md" textAlign="justify" mb="4">
                    <FormattedMessage id="chatbotContent.solution.text1" />
                  </Text>
                  <Text fontSize="md" textAlign="justify" mb="4">
                    <FormattedMessage id="chatbotContent.solution.text2" />
                  </Text>
                  <UnorderedList spacing="2" ml="6" styleType="none">
                    <ListItem>
                      <Icon as={CheckCircleIcon} color="green.500" mr="2" />
                      <FormattedMessage id="chatbotContent.solution.listItem1" />
                    </ListItem>
                    <ListItem>
                      <Icon as={CheckCircleIcon} color="green.500" mr="2" />
                      <FormattedMessage id="chatbotContent.solution.listItem2" />
                    </ListItem>
                  </UnorderedList>
                  <Text fontSize="md" textAlign="justify" mt="4" mb="4">
                    <FormattedMessage id="chatbotContent.solution.text3" />
                  </Text>
                  <UnorderedList spacing="2" ml="6" styleType="none">
                    <ListItem>
                      <Icon as={CheckCircleIcon} color="green.500" mr="2" />
                      <FormattedMessage id="chatbotContent.solution.listItem3" />
                    </ListItem>
                    <ListItem>
                      <Icon as={CheckCircleIcon} color="green.500" mr="2" />
                      <FormattedMessage
                        id="chatbotContent.solution.listItem4"
                        values={{
                          generosoLink: (
                            <ChakraLink href={generosoUrl} isExternal color="blue.500" fontWeight="bold">
                              Generoso
                            </ChakraLink>
                          ),
                        }}
                      />
                    </ListItem>
                    <ListItem>
                      <Icon as={CheckCircleIcon} color="green.500" mr="2" />
                      <FormattedMessage id="chatbotContent.solution.listItem5" />
                    </ListItem>
                  </UnorderedList>
                </Box>
                <Box textAlign="center">
                  <ChakraLink href={generosoUrl} isExternal>
                    <Image src={GenerosoLogo} alt="Generoso Logo" mx="auto" width="150px" mb="6" mt="4" />
                  </ChakraLink>

                  <Box textAlign="center">
                    <Box>
                      <ChakraLink href={appleStoreUrl} isExternal>
                        <Image src={LogoAppleStore} alt="Logo 1" mx="auto" width="150px" mb="2" />
                      </ChakraLink>

                      <ChakraLink href={googlePlayUrl} isExternal>
                        <Image src={LogoGoogleStore} alt="Logo 2" mx="auto" width="150px" />
                      </ChakraLink>
                    </Box>
                    <Text fontSize="md">
                      <FormattedMessage id="chatbotContent.solution.discoverOffers" />
                    </Text>
                  </Box>
                  <Text fontSize="md">
                    <FormattedMessage id="chatbotContent.solution.checkPackages" />
                  </Text>
                </Box>
              </SimpleGrid>
            </Box>

            {/* Testimonials and Logos */}
            <Box bg="gray.50" py="8" px="6" borderRadius="md" textAlign="center">
              <Text fontSize="xl" fontStyle="italic" mb="6">
                <FormattedMessage id="chatbotContent.testimonial.quote" />
              </Text>
              <Box display="inline-block" mt="4">
                <ChakraLink href="https://ecuacao.ch/" isExternal>
                  <Image src={EcuacaoLogo} alt="Ecuacao Logo" mx="auto" width="150px" />
                </ChakraLink>
              </Box>
            </Box>
          </Stack>

          {/* Chatbot Frame */}
          {isChatbotVisible && (
            <Box mt="10">
              <ChatbotFrame
                botId="f90b5c7f-3c17-4ae4-b195-6aa6767fc945"
                clientId="f90b5c7f-3c17-4ae4-b195-6aa6767fc945"
                webhookId="12e19aa2-d849-4727-a68e-06613c703e00"
                height="600px"
                width="100%"
              />
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

const MyGPT = () => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  return (
    <>
      {isMobile ? (
        <>
          <MobileMenuPanel />
          <ChakraProvider>
            <ChatbotContent isMobile={true} />
          </ChakraProvider>
          <MobileSecondFooter />
          <MobileFooter />
        </>
      ) : (
        <>
          <MenuPanel />
          <ChakraProvider>
            <ChatbotContent isMobile={false} />
          </ChakraProvider>
          <SecondFooter />
          <Footer />
        </>
      )}
      <LoginModal />
    </>
  );
};

export default MyGPT;
