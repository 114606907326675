import { call, put, takeEvery } from "redux-saga/effects";
import { getuserinfoAPI, validateUserAPI, fetchUserDetailsApi } from "./api";
import {
  validateUser,
  getUserInfo,
  setIsUserLoggedIn,
  setUserInfo,
  setShowLogin,
  setInValidUser,
  getUserFullDetails,
  setUserFullDetails
} from "./slice";

import { toast } from "react-toastify";
import "../../index.css";
import { ToastProperties } from "../../utils/constants/toastProperties";
import { FormattedMessage } from "react-intl";

export function* validateUserS({ payload, type }) {
  // console.log({ saga: payload });
  try {
    const { status, result, message, token } = yield call(validateUserAPI, payload);
    // console.log({ status, result });

    if (status === 'Error') {
      yield put(setInValidUser(message))
    }
    if (result) {
      // Save the token to localStorage
      yield localStorage.setItem("authToken", token);
      yield put(getUserInfo({ email: payload.email, cb: payload.cb }));
      yield put(setIsUserLoggedIn(result));
      yield localStorage.setItem("userLogged", payload.email);
      yield toast.success(<FormattedMessage id="toast.userloginsuccess" />, {
        ...ToastProperties
      });
    }
  } catch (error) {
    console.log({ error });
    yield put(setInValidUser(error));
    yield toast.error(<FormattedMessage id="toast.userloginsuccess" />, {
      ...ToastProperties
    });
  }
}

export function* getUserInfoS({ payload, type }) {
  try {
    const { status, result } = yield call(getuserinfoAPI, payload);
    yield put(setUserInfo(result[0]));
    yield localStorage.setItem("userinfo", JSON.stringify(result[0]));
    yield put(setShowLogin({ value: false, cb: null }))
    if (payload.cb) {
      yield payload.cb()
    }

  } catch (error) {
    console.log({ error });
  }
}

function* fetchUserFullDetails({ payload, type }) {
  try {
    const { status, result } = yield call(fetchUserDetailsApi, payload);
    if (status === "Success") {
      yield put(setUserFullDetails(result[0]));
    }
  } catch (error) {
    console.log({ error });
  }
}

function* UserSageWatcher() {
  yield takeEvery(validateUser, validateUserS);
  yield takeEvery(getUserInfo, getUserInfoS);
  yield takeEvery(getUserFullDetails, fetchUserFullDetails);
}

const UserSaga = UserSageWatcher;

export default UserSaga;
