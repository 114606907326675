import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import { useEffect, useState } from "react";
import "../pages/HomePage.css";
import { useSelector, useDispatch } from "react-redux";
import Details from "../components/DetailsList";
import {
  saveProperties,
  saveDraftProperties,
} from "../Redux/properties/PropertiesSlice";
import CreatePropertiesModal from "../components/CreatePropertiesModal";
import { productsByUser } from "../Redux/products/slice";
import MyProducts from "../components/MyProducts";
import MyContracts from "../components/MyContract";
import MenuPanel from "../components/MenuPanel";
import MyPersonalSpace from "../components/MyPersonalSpace";
import MyFarm from "../components/MyFarm";
import logo from '../assets/Logo.png';
import { createStyles, Navbar, Group, Code, getStylesRef, rem } from '@mantine/core';
import {
  IconBellRinging,
  IconFingerprint,
  IconKey,
  IconCirclePlus,
  Icon2fa,
  IconDatabaseImport,
  IconReceipt2,
  IconSwitchHorizontal,
  IconLogout,
  IconId,
  IconBuildingCottage
} from '@tabler/icons-react';
import { MantineLogo } from '@mantine/ds';
import Footer from "../components/Footer";
import SecondFooter from "../components/SecondFooter";
import { Select } from '@mantine/core';
import { logout, setShowLogin } from "../Redux/user/slice";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastProperties } from '../utils/constants/toastProperties';

import { IntlProvider, FormattedMessage } from "react-intl";

const useStyles = createStyles((theme) => ({
  
  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: `calc(${theme.spacing.md} * 0)`,
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  link: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,

      [`& .${getStylesRef('icon')}`]: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
    },
  },

  linkIcon: {
    ref: getStylesRef('icon'),
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
      [`& .${getStylesRef('icon')}`]: {
        color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
      },
    },
  },
}));

const data = [
  // { link: '', label: <FormattedMessage id="mylist.navbar.notifications" />, icon: IconBellRinging },
  { link: '', label: <FormattedMessage id="mylist.navbar.products" />, icon: IconDatabaseImport, id: "myproducts"  },
  { link: '', label: <FormattedMessage id="mylist.navbar.cart" />, icon: IconReceipt2, id: "mycontracts" },
  // { link: '', label: <FormattedMessage id="mylist.navbar.security" />, icon: IconFingerprint },
  // { link: '', label: <FormattedMessage id="mylist.navbar.sshKeys" />, icon: IconBuildingCottage },
  // { link: '', label: <FormattedMessage id="mylist.navbar.authentication" />, icon: Icon2fa },
  { link: '', label: <FormattedMessage id="mylist.navbar.newLot" />, icon: IconCirclePlus, id: "createnew" },
  { link: '', label: <FormattedMessage id="mylist.navbar.userSettings" />, icon: IconId, id: "mypersonalspace" },
  { link: '', label: <FormattedMessage id="mylist.navbar.myFarm" />, icon: IconBuildingCottage, id: "myfarm" },
];

const MyList = () => {
  const [menu, setMenu] = useState([]);
  const props = useSelector((state) => state.properties);
  const prodStore = useSelector((state) => state.products);
  const currentComp = localStorage.getItem('comp');
  const [initialLoad, setInitialLoad] = useState(true);

  const { classes, cx } = useStyles();
  const [active, setActive] = useState('myproducts');

  const handleMenuClick = (item) => {
    setActive(item.label);
    localStorage.setItem('comp', item.id);
  
    if (item.id === 'createnew') {
      setModal(true);
      setInitialLoad(false);
    } else {
      setModal(false);
    }
  };
  
  useEffect(() => {
    const menu = data.map((item) => ({
      ...item,
      active: item.label === active,
    }));
  
    if (!initialLoad && currentComp === 'createnew') {
      setModal(true);
    } else {
      setModal(false);
    }
  
    // Update the menu state
    setMenu(menu);
  }, [active, currentComp, initialLoad]);  
  
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);

  const onSave = (obj, cb) => {
    try {
      dispatch(saveProperties({ payload: obj, cb }));

    } catch (error) {
      // Handle errors from the save operation
    
      // Show error toast
      showToast('error', 'Error saving product');
    }

  };

  const onDraftSave = (obj, cb) => {
    try {
      dispatch(saveDraftProperties({ payload: obj, cb }));

    } catch (error) {
      // Handle errors from the save operation
    
      // Show error toast
      showToast('error', 'Error saving product draft');
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    localStorage.clear('userLogged');
    localStorage.clear('userinfo');

    if (window.location.pathname === '/my-list') {
      window.location.href = '/';
    }
  }; 

  // Get the value of 'comp' from localStorage
  const comp = localStorage.getItem('comp');

  // Determine which component to render based on the value of 'comp'
  let pageToRender;
  if (comp === 'mycontracts') {
    pageToRender = <MyContracts />;
  } else if (comp === 'mypersonalspace') {
    pageToRender = <MyPersonalSpace />;
  } else if (comp === 'myfarm') {
    pageToRender = <MyFarm />;
  } else {
    pageToRender = <MyProducts />;
  }

  useEffect(() => {
    setActive(comp);
  }, [comp]);

  const showToast = (type, message) => {
    // Use the toast function to display the appropriate toast message
    if (type === 'success') {
      toast.success(message, {
        ...ToastProperties
      });
    } else if (type === 'error') {
      toast.error(message, {
        ...ToastProperties
      });
    }
  };  

  return (
    <>
      <MenuPanel />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.fetching || prodStore.fetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <nav className={classes.navbar}>
        <div className={classes.navbarMain} style={{ display: 'flex'}}>
            {/* Left Content */}
            <div style={{ padding: '1rem', width: 'auto',  borderRight: '1px solid #ccc'}}>
                {/* Top Content */}
                <div>
                  <Navbar.Section grow>
                    <Group className={classes.header} position="apart">
                      <img src={logo} alt="Logo" height={28} />
                      <Code sx={{ fontWeight: 700 }}>v1.0.0</Code>
                    </Group>
                    {data.map((item) => (
                      <a
                        className={cx(classes.link, { [classes.linkActive]: item.label === active || (active === 'myproducts' && item.id === 'myproducts') })}
                        href={item.link}
                        key={item.id}
                        onClick={(event) => {
                          event.preventDefault();
                          handleMenuClick(item);
                        }}
                      >
                        <item.icon className={classes.linkIcon} stroke={1.5} />
                        <span>{item.label}</span>
                      </a>
                    ))}
                  </Navbar.Section>
                </div>
                {/* Bottom Content */}
                <div style={{ marginTop: "30vh" }}>
                  <Navbar.Section className={classes.footer}>
                    <a href="#" className={classes.link} onClick={(event) => event.preventDefault()}>
                      <IconSwitchHorizontal  onClick={handleLogout} className={classes.linkIcon} stroke={1.5} />
                      <span><FormattedMessage id="button.changeAcct" /></span>
                    </a>
                    <a href="#" className={classes.link} onClick={(event) => event.preventDefault()}>
                      <IconLogout  onClick={handleLogout} className={classes.linkIcon} stroke={1.5} />
                      <span><FormattedMessage id="button.logout" /></span>
                    </a>
                  </Navbar.Section>
                </div>
              </div>
            <div style={{ flex: '2 1 auto', padding: '1rem', display: "flex", flexDirection: "column" }}>
              {/* Right Content */}
              <div style={{ border: '0px solid #ccc', padding: '.1rem', borderRadius: '4px' }}>
                {pageToRender}
              </div>
            </div>
        </div>
      </nav>
      <CreatePropertiesModal
        modal={modal}
        toggle={() => setModal(false)}
        onSave={(obj, cb) => onSave(obj, cb)}
        onDraftSave={(obj, cb) => onDraftSave(obj, cb)}
      />
      <SecondFooter />
      <Footer />
    </>
  );
};

export default MyList;