import {
  delay,
  put,
  takeEvery,
  Effect,
  ForkEffect,
  call,
  select,
} from "redux-saga/effects";
import {
  getCategoriesListAPI, getCountriesListAPI, getCurrenciesListAPI, getProductNamesAPI
} from "./api";
import { getCategories, getCountries, getCurrencies, setCategories, setCountries, setCurrencies, getProductNames, setProductNames } from "./slice";
const axios = require('axios');
const apiKey = 'AIzaSyBqzTRKyZoQuNk-xsLoZLEsfY_hi7GJe64';

export function* getCategoriesSaga() {
  try {
    const storedLanguage = localStorage.getItem("language");
    const targetLanguage = storedLanguage ? storedLanguage.replace(/["']/g, '') : "en";

    var { result } = yield call(getCategoriesListAPI, targetLanguage);

    yield put(setCategories(result));
    // yield put(setCategories(result));
  } catch (error) {
    console.log("API ERROR", error);
  }
}

export function* getCountriesSaga() {
  try {
    var { result } = yield call(getCountriesListAPI);
    yield put(setCountries(result));
  } catch (error) {
    console.log("API ERROR", error);
  }
}

export function* getCurrenciesSaga() {
  try {
    var { result } = yield call(getCurrenciesListAPI);
    yield put(setCurrencies(result));
  } catch (error) {
    console.log("API ERROR", error);
  }
}

export function* getProductNamesSaga() {
  try {
    var { result } = yield call(getProductNamesAPI, { upc: "2" });
    yield put(setProductNames(result));
  } catch (error) {
    console.log("API ERROR", error);
  }
}

function* OptionsSagaWatcher() {
  yield takeEvery(getCategories, getCategoriesSaga);
  yield takeEvery(getCountries, getCategoriesSaga);
  yield takeEvery(getCurrencies, getCurrenciesSaga);
  yield takeEvery(getProductNames, getProductNamesSaga);

}

async function translateTextWithHTTP(text, targetLanguage, apiKey) {
  const apiUrl = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;
  const requestBody = {
    q: text,
    target: targetLanguage,
  };

  try {
    const response = await axios.post(apiUrl, requestBody);
    const translations = response.data.data.translations;
    return translations;
  } catch (error) {
    console.error('Error translating text:', error.message);
    return Array.isArray(text) ? Array(text.length).fill(text) : [text];
  }
}

const OptionsaSaga = OptionsSagaWatcher;

export default OptionsaSaga;
