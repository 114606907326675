import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function TransactionBoard({ record }) {


    const { transactions } = record || [] ;

    const getTransactionId = (token) =>{
        let id = token.split("-");
        return id[0]+ "@"+ id[1]+ "."+id[2];
        
    }

    const getDateTime = (date12) =>{
        let date = new Date(date12 * 1000);
        return date.toISOString();
    } 
    return (
        <TableContainer component={Paper} sx={{backgroundColor: "rgb(64,64,138)",color: "white", maxHeight: 350 , overflow: "auto" }}>
            <Table sx={{ minWidth: 650 }}  size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow sx={{backgroundColor: "rgb(20,20,64)", color: ""}}>
                        <TableCell sx={{color: "white", backgroundColor: "rgb(20,20,64)"}} align="center">Transaction Id</TableCell>
                        <TableCell sx={{color: "white",backgroundColor: "rgb(20,20,64)"}} align="center">Type</TableCell>
                        <TableCell sx={{color: "white", backgroundColor: "rgb(20,20,64)"}} align="center">Content</TableCell>
                        <TableCell sx={{color: "white", backgroundColor: "rgb(20,20,64)"}} align="center">Time</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactions?.map((transaction) => (
                        <TableRow
                            key={transaction.transaction_id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell sx={{color: "white"}} align="center" component="th" scope="row">
                                {getTransactionId(transaction.transaction_id)}
                            </TableCell>
                            <TableCell sx={{color: "white"}} align="center">{transaction.name}</TableCell>
                            <TableCell sx={{color: "white"}} align="center">{transaction.entity_id}</TableCell>
                            <TableCell sx={{color: "white"}} align="center">{getDateTime(transaction.valid_start_timestamp)}</TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}