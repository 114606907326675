import en from ".././lang/en.json"; // Import the English translations
import es from ".././lang/es.json"; // Import the Spanish translations
import pt from ".././lang/pt.json"; // Import the Portuguese translations
import fr from ".././lang/fr.json"; // Import the French translations
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import PropTypes from 'prop-types';

const messages = {
    en,
    es,
    pt,
    fr,
};

const Localization = ({ children }) => {
    const selectedLanguage = useSelector((state) => state.language);

    // Get the language from local storage
    const localStorageLanguage = JSON.parse(localStorage.getItem('language')) || 'en';

    return (
        <IntlProvider locale={selectedLanguage} messages={messages[selectedLanguage]}>
            {children}
        </IntlProvider>
    );
};

Localization.propTypes = {
    children: PropTypes.element.isRequired
};

export default Localization;