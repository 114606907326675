import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  NativeSelect,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocationsList } from "../Redux/products/slice";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  getProductStateList,
  transferProduct,
  transportProduct,
  getProductNextStateList,
} from "../Redux/products/slice";

import { IntlProvider, FormattedMessage, useIntl } from 'react-intl';
import {Autocomplete } from '@mantine/core';

export const TAG_TRANSFORM_ACTION = "TRANSFORM_ACTION";
export const TAG_TRANSPORT_ACTION = "TRANSPORT_ACTION";

function TransportationModal({ toggle, modal, title, data, tag, onSave, dataLot }) {
  const dispatch = useDispatch();
  const { productStateList, locationsList, productNextStateList } = useSelector(
    (state) => state.products
  );

  const storedLanguage = localStorage.getItem("language");
  const targetLanguage = storedLanguage ? storedLanguage.replace(/["']/g, '') : "en";

  useEffect(() => {
    // Dispatch actions to fetch data when the modal is opened
    if (modal) {
      dispatch(getProductStateList());
      dispatch(getLocationsList());
      dispatch(getProductNextStateList());
    }
  }, [modal, dispatch]);

  const sortedLocations = locationsList
  .slice()
  .sort((a, b) => (a.location && b.location ? a.location.localeCompare(b.location) : 0));

  const sortedProductStates = productStateList
    .slice()
    .sort((a, b) => (a.label && b.label ? a.label.localeCompare(b.label) : 0));

  const { categories } = useSelector((state) => state.options);

  const [fromPeriod, setFromPeriod] = useState(new Date().toISOString().split('T')[0]);
  const [toPeriod, setToPeriod] = useState(new Date().toISOString().split('T')[0]);

  const [physicalState, setPhysicalState] = useState("");
  const [location, setLocation] = useState("");
  const [country, setCountry] = useState();
  const [transportDescription, setTransportDescription] = useState("");

  // Update state when data prop changes
  useEffect(() => {
    if (data) {
      dispatch(getProductStateList());
      dispatch(getLocationsList());
      dispatch(getProductNextStateList());
    }
  }, [data, dispatch]);

  function getMonthDifference(startDate, endDate) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }

  const saveData = () => {
    let FromPeriod = new Date(fromPeriod);
    let ToPeriod = new Date(toPeriod);

    let month = getMonthDifference(FromPeriod, ToPeriod);

    // Assuming locationsList is a dictionary with id and label
    const selectedLocation = locationsList.find(value => value.location === location);
    const locationsIds = selectedLocation?.id || null;

    if (tag === TAG_TRANSFORM_ACTION) {
      const productId = productStateList.find(state => state.ActionVerb === physicalState && state.id_category === data.category)?.product_ref_id || null;
      const payload = {
        ...data, 
        physicalState: physicalState,
        location: location,
        period: `${fromPeriod} - ${toPeriod}`,
        country: country,
        location_id: locationsIds,
        product_ref_id: productId,
        product_next_id: productId,
        transportDescription, // Include the new description field
      };
      dispatch(transferProduct(payload));
    } else {
      const payload = {
        ...data,
        physicalState: physicalState,
        location: location,
        period: `${fromPeriod} - ${toPeriod}`,
        country: country,
        location_id: locationsIds,
        transportDescription, // Include the new description field
      };
      dispatch(transportProduct({ ...payload, location }));
    }

    // clearData();

    // Call the onSave callback function
    if (onSave) {
      onSave();
    }
  };

  const clearData = () => {
    setFromPeriod("");
    setToPeriod("");
    setPhysicalState("");
    setLocation("");
    setTransportDescription(""); // Clear the transport description field
  };

  const intl = useIntl();

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} style={{ height: "auto" }}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <Grid container spacing={1}>
            {(tag === TAG_TRANSFORM_ACTION || tag === TAG_TRANSPORT_ACTION) && (
              <Grid item>
                <Autocomplete
                  label={intl.formatMessage({ id: "product.location" })}
                  placeholder="Select a location"
                  data={sortedLocations.map(location => location.location)}
                  value={location}
                  onChange={setLocation}
                  maxDropdownHeight={200} // Limit the dropdown height to 200px
                  sx={{
                    '.mantine-Autocomplete-dropdown': {
                      maxHeight: '200px',
                    },
                  }}
                />
            </Grid>
            )}
            {tag === TAG_TRANSFORM_ACTION && (
              <Grid item>
                <FormControl sx={{ m: 0, minWidth: 120 }}>
                  <InputLabel htmlFor="demo-dialog-native">
                    <FormattedMessage id="product.physicalstate" />
                  </InputLabel>
                  <Select
                    native
                    value={physicalState}
                    onChange={(e) => setPhysicalState(e.target.value)}
                    input={
                      <OutlinedInput
                        label={intl.formatMessage({ id: "product.physicalstate" })}
                        id="demo-dialog-native"
                      />
                    }
                  >
                    <option aria-label="None" value="" />
                    {sortedProductStates
                      .filter(state => state.id_category === data.category)
                      .map((state, index) => (
                        <option
                          key={state.ActionVerb}
                          value={state.ActionVerb}
                          disabled={state.product_ref_id <= data.product_ref_id}
                          style={{
                            color: state.product_ref_id > data.product_ref_id ? 'inherit' : '#ccc', // Lighter font color for disabled options
                            fontStyle: state.product_ref_id > data.product_ref_id ? 'normal' : 'italic', // Italics for disabled options
                          }}
                        >
                          {index + 1}: {state.ActionVerb}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                id="transport-description"
                label={intl.formatMessage({ id: "product.transportDescription" })}
                fullWidth
                multiline
                rows={4}
                value={transportDescription}
                onChange={(e) => setTransportDescription(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <div><FormattedMessage id="product.period1" /></div>
              <TextField
                id="outlined-basic"
                type={"date"}
                sx={{ width: "50%" }}
                value={fromPeriod}
                placeholder={""}
                onChange={(e) => setFromPeriod(e.target.value)}
                variant="outlined"
              />
              <TextField
                id="outlined-basic"
                type={"date"}
                sx={{ width: "50%" }}
                value={toPeriod}
                placeholder={""}
                onChange={(e) => setToPeriod(e.target.value)}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => saveData()}>
            {tag === TAG_TRANSFORM_ACTION ? <FormattedMessage id="myprods.action.transform" /> : <FormattedMessage id="myprods.action.transport" />}
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              clearData();
              onSave();
              toggle();
            }}
          >
            <FormattedMessage id="button.cancel" />
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default TransportationModal;
