import createSagaMiddleware from "@redux-saga/core";
import { applyMiddleware, configureStore } from "@reduxjs/toolkit";
import counterReducer from "./CounterSlice";
import propertiesReducer from "./properties/PropertiesSlice";
import prodcutsReducer from "./products/slice";
import userReducer from "./user/slice";
import optionsReducer from "./options/slice";
import rootSaga from "./rootsaga";
import languageReducer from "./languageSlice";

const preloadedState = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) : {};

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    properties: propertiesReducer,
    products: prodcutsReducer,
    user: userReducer,
    options: optionsReducer,
    language: languageReducer, // Add the language reducer to the store
  },
  preloadedState,
  middleware: (gDM) => gDM().concat([sagaMiddleware]),
  devTools: process.env.NODE_ENV !== "production",
});

sagaMiddleware.run(rootSaga);

export default store;
