import React, { useState, useEffect } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import brandLogo from "./../assets/brand.png";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../Redux/languageSlice";
import Navbar from "react-bootstrap/Navbar";
import fongitLogo from "./../assets/fongit.png"; // Import the Fongit logo
import { FormattedMessage, useIntl } from "react-intl";

const MobileSecondFooter = () => {
  const getLanguageMessages = (language) => {
    switch (language) {
      case "en":
        return require("../lang/en.json");
      case "fr":
        return require("../lang/fr.json");
      case "es":
        return require("../lang/es.json");
      case "pt":
        return require("../lang/pt.json");
      // Add more languages here as needed
      default:
        return require("../lang/en.json");
    }
  };

  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.language);

  const handleLanguageChange = (language) => {
    dispatch(setLanguage(language)); // Dispatch the setLanguage action to update the selected language
    localStorage.setItem("language", JSON.stringify(language));
  };

  // Language options for the select dropdown
  const languageOptions = [
    { value: "en", label: <FormattedMessage id="languageOptions.en" /> },
    { value: "fr", label: <FormattedMessage id="languageOptions.fr" /> },
    { value: "es", label: <FormattedMessage id="languageOptions.es" /> },
    { value: "pt", label: <FormattedMessage id="languageOptions.pt" /> },
    // Add more options for additional languages
  ];

  return (
    <footer
      className="bg-lightGrey"
      style={{
        width: "100%",
        backgroundColor: "#f2f2f2",
        borderTop: ".1px solid #f2f2f2",
        minHeight: "250px"
      }}
    >
      <Container fluid>
        <Row className="ms-0 me-0 justify-content-center align-items-center">
          {/* Fongit Logo and Supported by Fongit text */}
          <Col xs={12} className="d-flex flex-column align-items-center mb-2 text-center">
            <img src={fongitLogo} alt="Fongit Logo" style={{ width: "80px", height: "auto", margin: "10px" }} />
            <p style={{ fontSize: "0.8rem", marginTop: "5px", fontFamily: "Montserrat", fontStyle: "italic" }}>
              <FormattedMessage id="footerText.fongit" />
            </p>
          </Col>
          {/* Language dropdown and links */}
          <Col xs={12} className="text-center">
            <Nav className="justify-content-center">
              <Navbar.Brand style={{ fontSize: "0.8rem", flexGrow: 1, display: "block", width: "100%", }}>
                <select value={selectedLanguage} onChange={(e) => handleLanguageChange(e.target.value)}>
                  {languageOptions.map((language) => (
                    <option key={language.value} value={language.value}>
                      {language.label}
                    </option>
                  ))}
                </select>
                <div className="d-flex flex-wrap justify-content-center mt-2">
                  <Link to="/ethics" className="text-muted me-3">
                    {getLanguageMessages(selectedLanguage).footerLinks.ethics}
                  </Link>
                  <Link to="/terms" className="text-muted me-3">
                    {getLanguageMessages(selectedLanguage).footerLinks.terms}
                  </Link>
                  <Link to="/privacy" className="text-muted me-3">
                    {getLanguageMessages(selectedLanguage).footerLinks.privacy}
                  </Link>
                  <Link to="/press" className="text-muted me-3">
                    {getLanguageMessages(selectedLanguage).footerLinks.press}
                  </Link>
                  <Link to="/cookies-policy" className="text-muted me-3">
                    {getLanguageMessages(selectedLanguage).footerLinks.cookiesPolicy}
                  </Link>
                </div>
              </Navbar.Brand>
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default MobileSecondFooter;
