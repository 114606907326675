import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLocationsList,
  getProductStateList,
  getProductsList,
  productsByUser,
  transferProduct,
  transportProduct,
  updateProductDB,
  getProductLotList,
} from "../Redux/products/slice";
import { PropertyTableConfig, tableConfig } from "../components/mockData";
import moment from "moment";
import ProductPanel from "./ProductPanel";
import { saveProperties, saveDraftProperties, deleteDraftProperties } from "../Redux/properties/PropertiesSlice";
import { deleteValue } from "../Redux/CounterSlice";
import FormDialog, {
  TAG_TRANSFORM_ACTION,
  TAG_TRANSPORT_ACTION,
} from "./TransportationModal";
import TransportationModal from "./TransportationModal";
import {  createStyles,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  rem, } from '@mantine/core';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons-react';
import Dropdown from "../components/Dropdown";
import CreatePropertiesModal from "../components/CreatePropertiesModal";
import LoadImageModal from "../components/LoadImageModal";
import "react-toastify/dist/ReactToastify.css";
import { Paper,  Container, Grid, Button, Image, Badge, Input, Modal } from '@mantine/core';
import RemoveFieldConfirmationModal from '../components/RemoveFieldConfirmationModal';
import { IntlProvider, FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from '../Redux/apiConfig';

const useStyles = createStyles((theme) => ({
  th: {
    padding: '0 !important',
  },
  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },
  icon: {
    width: rem(21),
    height: rem(21),
    borderRadius: rem(21),
  },
  resizeHandle: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '8px',
    zIndex: 1,
    backgroundColor: '#fff',
  },
  resizeOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '8px',
    zIndex: 2,
    opacity: 0,
    cursor: 'col-resize',
  },
  tableContainer: {
    maxHeight: '200px',
    overflowY: 'auto',
  },
}));

function Th({ children, reversed, sorted, onSort }) {
  const { classes } = useStyles();
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position="apart">
          <div> {/* Wrap the contents of the <th> element */}
            <Text fw={500} fz="sm">
              {children}
            </Text>
            {sorted && <Icon size="0.9rem" stroke={1.5} />} 
          </div>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function filterData(data, search) {
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    Object.values(item).some((value) => {
      if (typeof value === 'string') {
        return value.toLowerCase().includes(query);
      } else if (typeof value === 'number' || typeof value === 'bigint') {
        return value.toString().includes(query);
      }
      // Handle other data types if needed
      return false; // Return false for non-searchable types
    })
  );
}

function sortData(data, payload) {
  const { sortBy } = payload;

  let sortedData = [...data];

  if (sortBy) {
    sortedData.sort((a, b) => {
      let valueA = a[sortBy];
      let valueB = b[sortBy];

      if (sortBy === 'sku') {
        valueA = parseInt(valueA, 10);
        valueB = parseInt(valueB, 10);
      }

      if (payload.reversed) {
        return valueB - valueA;
      }

      return valueA - valueB;
    });
  }

  return filterData(sortedData, payload.search);
}

export default function MobileMyProducts() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.products.productLotList);
  const locationList = useSelector((state) => state.products.locationsList);
  const productsList = useSelector((state) => state.products.productsList);
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const userinfo = JSON.parse(localStorage.getItem("userinfo"));
  const [openExtrasDialog, setOpenExtrasDialog] = useState(false);
  const [extrasTitle, setExtrasTitle] = useState();
  const [persistedPayload, setPayload] = useState();
  const [tag, setTag] = useState();
  const [selectedAction, setSelectedAction] = useState('');
  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState(data || []);
  useEffect(() => {
    if (data && data.length > 0) {
      setSortedData(data);
    }
  }, [data]);
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [modal, setModal] = useState(false);
  const [loadImageModalVisible, setLoadImageModalVisible] = useState(false);
  const [showSellConfirmationModal, setShowSellConfirmationModal] = useState(false);
  const [sellConfirmationFieldName, setSellConfirmationFieldName] = useState('');
  const selectedLanguage = useSelector((state) => state.language);

  const handleSellButtonClick = (fieldName) => {
    if (fieldName.publish === "N") {
      setSellConfirmationFieldName(fieldName);
      setShowSellConfirmationModal(true);
    } else {
      // Assuming there is a publish method on fieldName, replace it with the actual method
      publish(fieldName); // Call the publish method directly
    }
  };

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    const sorted = sortData(data, { sortBy: field, reversed, search });
    setSortedData(sorted);
  };
  
  const handleSearchChange = (event) => {
    const { value } = event.currentTarget;
    setSearch(value);
    const sorted = sortData(data, { sortBy, reversed: reverseSortDirection, search: value });
    setSortedData(sorted);
  };
  
  const getWithdrawLabel = (field) => {
    return field.publish === "Y" ? <FormattedMessage id="myprods.state.withdraw" /> : <FormattedMessage id="myprods.state.sell" />;
  };

  const handleActionChange = (value, data) => {

    setSelectedAction(value);
    setSelectedData(data);

    switch (value) {
      case "LoadImage":
        setLoadImageModalVisible(true);
        break;
      case "UpdateRelease":
        setSelectedForUpdateRelease(data); // Set selected data for update/release
        setModal(true);
        break;
      case "Delete":
        dispatch(deleteDraftProperties(data));
        break;
      case "Sell":
        handleSellButtonClick(data);
        break;      
      case "View":
        setShow(true);
        break;
      case "Transport":
        transport(data);
        break;
      case "Transform":
        transfer(data);
        break;
      default:
        break;
    }
  };

  const onSave = (obj, cb) => {
    dispatch(saveProperties({ payload: obj, cb }));
  };

  const onDraftSave = (obj, cb) => {
    dispatch(saveDraftProperties({ payload: obj, cb }));
  };
  
  const publish = (data) => {
    var obj = {
      ...data,
      contractId: userinfo.product_contractId,
      upc: data.UPC,
      publish: data.publish === "Y" ? "N" : "Y",
      state: data.state === "ForSale" ? "NotForSale" : "ForSale",
    };
  
    dispatch(updateProductDB(obj));
  };
  
  useEffect(() => {
    dispatch(productsByUser());
    dispatch(getProductsList());
    dispatch(getLocationsList());
    dispatch(getProductLotList());
    dispatch(getProductStateList());
  }, []);

  useEffect(() => {
    dispatch(getProductLotList());
  }, [dispatch, selectedLanguage]);

  const transfer = (obj) => {
    const product_contractId = JSON.parse(
      localStorage.getItem("userinfo")
    ).product_contractId;

    const locationId = locationList.find(state => state.location === obj.location)?.id || null;
    const id_category = productsList.find(state => state.label === obj.productName)?.id_category || null;

    var payload = {
      contractID: product_contractId,
      sku: obj.sku,
      productLotUuid: obj.productID,
      physicalState: obj.physicalState,
      location: obj.location,
      period: obj.period,
      categoryName: obj.productName,
      locationId: locationId,
      category: id_category,
      product_ref_id: obj.product_ref_id,
    };
    setPayload(payload);
    setExtrasTitle(<FormattedMessage id="myprods.xtratitle.transform" />);
    setOpenExtrasDialog(true);
    setTag(TAG_TRANSFORM_ACTION);
  };

  const transport = (obj) => {
    const product_contractId = JSON.parse(
      localStorage.getItem("userinfo")
    ).product_contractId;

    const locationId = locationList.find(state => state.location === obj.location)?.id || null;

    var payload = {
      contractID: product_contractId,
      sku: obj.sku,
      productLotUuid: obj.productID,
      location: obj.location,
      period: obj.period,
      locationId: locationId,
      product_ref_id: obj.product_ref_id,
    };

    setPayload(payload);
    setExtrasTitle(<FormattedMessage id="myprods.xtratitle.transport" />);
    setOpenExtrasDialog(true);
    setTag(TAG_TRANSPORT_ACTION);
  };

  const classes = useStyles();
  const [selectedForUpdateRelease, setSelectedForUpdateRelease] = useState(null);

  const getImages = async (value) => {
    const imageUrl = `https://vertoledgerstorage.blob.core.windows.net/sqldbledgerdigests/images/${value.productID}.png`;
    const defaultImage= `https://vertoledgerstorage.blob.core.windows.net/sqldbledgerdigests/images/${value.product_name_id}.jpg`; 
    try {
      const response = await fetch(imageUrl);
      if (response.ok) {
        return imageUrl;
      } else {
        return defaultImage;
      }
    } catch (error) {
      return defaultImage;
    }
  };

  const getActionsForProduct = (product) => {
    if (product.state === "Draft") {
      return [
        {
          value: "UpdateRelease",
          label: <FormattedMessage id="myprods.action.updaterelease" />,
        },{
          value: "Delete",
          label: <FormattedMessage id="myprods.action.delete" />,
        },{
          value: "LoadImage",
          label: <FormattedMessage id="myprods.action.loadimg" />,
        },
      ];
    } else {
      return [
        {
          value: "Sell",
          label: getWithdrawLabel(product),
        },
        { value: "View", label: <FormattedMessage id="myprods.action.view" /> },
        { value: "Transport", label: <FormattedMessage id="myprods.action.transport" /> },
        { value: "Transform", label: <FormattedMessage id="myprods.action.transform" /> },
        {
          value: "LoadImage",
          label: <FormattedMessage id="myprods.action.loadimg" />,
        },
      ];
    }
  };

  const [imageUrls, setImageUrls] = useState({});
  useEffect(() => {
    const fetchImages = async () => {
      const urls = await Promise.all(sortedData.map(async (product) => {
        const url = await getImages(product);
        return { productID: product.productID, url };
      }));
      setImageUrls(urls.reduce((acc, { productID, url }) => ({ ...acc, [productID]: url }), {}));
    };
  
    fetchImages();
  }, [sortedData]);  

  const renderMobileProducts = () => {
    
    return sortedData.map((product, index) => {

      return (
      <div key={index} style={{ display: 'flex', marginBottom: 5, border: '1px solid #ccc', borderRadius: 8, backgroundColor: 'white' }}>
        <div style={{ width: '20%', position: 'relative', overflow: 'hidden', backgroundColor: 'white' }}>
          {/* Image component */}
          <img
            src={imageUrls[product.productID]}
            // alt={product.productName}
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        </div>
        <div style={{ width: '80%', padding: 0, display: 'flex' }}>
          <div style={{ flex: '1 0 0', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '4px' }}>
            <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}>{product.productName}</p>
            <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}>Quantity: {product.quantity}</p>
          </div>
          <div style={{ flex: '1 0 0', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '4px' }}>
            <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}>SKUID: {product.sku}</p>
            <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}>Location: {product.location}</p>
          </div>
          <div style={{ flex: '1 0 0', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '4px' }}>
            <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}>State: {product.state}</p>
            <p style={{ fontSize: 'calc(0.5em + 0.5vw)' }}>
            <a
              href={product}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              onClick={async (e) => {
                e.preventDefault();
                // Call getProductCertificate to ensure the product exists
                try {
                  const productResponse = await fetch(`${BASE_URL}/getProductCertificate?product_id=${product.productID}&targetLanguage=${selectedLanguage}`);
                  if (!productResponse.ok) {
                      console.error('Error fetching product certificate:', productResponse.statusText);
                      return;
                  }
                  // getProductCertificate succeeded, now call certificateContent
                  const certificateResponse = await fetch(`${BASE_URL}/certificateContent?product_id=${product.productID}`);
                  if (certificateResponse.ok) {
                    // API call was successful, you can handle the response or redirect to the PDF URL
                    window.open(certificateResponse.url, '_blank');
                  } else {
                    // API call failed, handle the error
                    console.error('Error fetching PDF:', certificateResponse.statusText);
                  }
                } catch (error) {
                  console.error('Error fetching product certificate or PDF:', error.message);
                }
              }}
            >
              <FontAwesomeIcon icon={faFilePdf} />
            </a>
            </p>
            <div style={{ width: '5px', fontSize: 'calc(0.5em + 0.5vw)' }}>
            <Dropdown
              actions={getActionsForProduct(product)}
              handleActionChange={handleActionChange}
              data={product}
            />
            </div>
          </div>
        </div>
      </div>
      );
    });
  };  

  const intl = useIntl();

  return (
    <>
      <TransportationModal
        modal={openExtrasDialog}
        toggle={() => setOpenExtrasDialog(false)}
        title={extrasTitle}
        data={persistedPayload}
        tag={tag}
        onSave={() => {
          // Handle any post-save logic here
          dispatch(getProductLotList()); // Refresh product list after saving
          setOpenExtrasDialog(false); // Close the modal after saving
        }}
      />
      {selectedData && (
        <ProductPanel
          modal={show}
          toggle={() => setShow(false)}
          readonly={true}
          value={selectedData}
        />
      )}
      <ScrollArea>
        {/* Your mobile-friendly content structure */}
        <TextInput
          placeholder={intl.formatMessage({ id:"myprods.search.placeholder" })}
          mb="md"
          onChange={handleSearchChange}
          style={{marginTop: '10px', display: 'flex' }}
        />
        {sortedData && sortedData.length > 0 ? renderMobileProducts() : <p>No products available.</p>}
        <CreatePropertiesModal
          modal={modal}
          toggle={() => setModal(false)}
          selectedData={selectedForUpdateRelease}
          onSave={(obj, cb) => onSave(obj, cb)}
          onDraftSave={(obj, cb) => onDraftSave(obj, cb)}
          style={{
            maxHeight: 'calc(100vh - 100px)', // Adjust the height based on your layout and content
            overflowY: 'auto',
          }}
        />
        <LoadImageModal
          modal={loadImageModalVisible}
          toggle={() => setLoadImageModalVisible(!loadImageModalVisible)}
          selectedData={selectedData}
          onSave={(obj, toggle) => {
            // Handle saving logic here
            setLoadImageModalVisible(false); // Close the modal after saving
          }}
          style={{
            maxHeight: 'calc(100vh - 100px)', // Adjust the height based on your layout and content
            overflowY: 'auto',
          }}
        />
        {/* Additional components or modals */}
      </ScrollArea>
      <RemoveFieldConfirmationModal
        fieldName={sellConfirmationFieldName}
        onConfirm={() => {
          // Handle the sell confirmation logic here
          // For example, you can call the sell function or dispatch an action
          publish(sellConfirmationFieldName); 
          setShowSellConfirmationModal(false); // Close the modal after confirming
        }}
        onCancel={() => setShowSellConfirmationModal(false)}
        opened={showSellConfirmationModal}
      />
    </>
  );
}
