export const ToastProperties = {
    position: "top-center",
    className: "toast-message",
    style: {
      maxHeight: "auto", 
      minHeight: "auto",
      color: "black",
    },
    autoClose: 800,
    rtl: false,
    hideProgressBar: true,
    closeOnClick: true,      
  };