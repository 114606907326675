import React, { useRef } from 'react';
import { Text, Group, Button, rem, useMantineTheme } from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { IconCloudUpload, IconX, IconDownload } from '@tabler/icons-react';
import { IntlProvider, FormattedMessage } from "react-intl";

export function DropzoneButton() {
  const theme = useMantineTheme();
  const openRef = useRef(null);

  const styles = {
    wrapper: {
      position: 'relative',
      marginBottom: rem(30),
    },
    dropzone: {
      borderWidth: rem(1),
      paddingBottom: rem(50),
    },
    icon: {
      color: 'light-dark(var(--mantine-color-gray-4), var(--mantine-color-dark-3))',
    },
    control: {
      position: 'absolute',
      width: rem(250),
      left: '50%', // Center horizontally
      transform: 'translateX(-50%)', // Center horizontally
      bottom: rem(-20),
      textAlign: 'center', // Center text
    },
  };

  return (
    <div style={styles.wrapper}>
      <Dropzone
        openRef={openRef}
        onDrop={() => {}}
        style={styles.dropzone}
        radius="md"
        accept={[MIME_TYPES.pdf]}
        maxSize={30 * 1024 ** 2}
      >
        <div style={{ pointerEvents: 'none' }}>
          <Group justify="center">
            <Dropzone.Accept>
              <IconDownload
                style={{ width: rem(50), height: rem(50), ...styles.icon }}
                color={theme.colors.blue[6]}
                stroke={1.5}
              />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconX
                style={{ width: rem(50), height: rem(50), ...styles.icon }}
                color={theme.colors.red[6]}
                stroke={1.5}
              />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <IconCloudUpload
                style={{ width: rem(50), height: rem(50), ...styles.icon }}
                stroke={1.5}
              />
            </Dropzone.Idle>
          </Group>

          <Text ta="center" fw={700} fz="lg" mt="xl">
            <Dropzone.Accept><FormattedMessage id="userInfo.Dropfileshere" /></Dropzone.Accept>
            <Dropzone.Reject><FormattedMessage id="userInfo.Filelessthan30mb" /></Dropzone.Reject>
            <Dropzone.Idle><FormattedMessage id="userInfo.Uploadyourfiles" /></Dropzone.Idle>
          </Text>
          <Text ta="center" fz="sm" mt="xs" c="dimmed">
            <FormattedMessage id="userInfo.Drag" /><i>.pdf, .csv, .xlsx </i> 
            <FormattedMessage id="userInfo.filesize" />
          </Text>
        </div>
      </Dropzone>

      <Button style={styles.control} size="md" radius="xl" onClick={() => openRef.current?.()}>
      <FormattedMessage id="userInfo.Selectfiles" />
      </Button>
    </div>
  );
}