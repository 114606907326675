import { FarmConstants } from "./Constants-farm";
import { realEstateConstant } from "./Constants-real-estate";

export const WEBSITE_REAL_ESTATE = "WEBSITE_REAL_ESTATE";
export const WEBSITE_FARM_TRADE = "WEBSITE_FARM_TRADE";


export const CURRENT_PURPOSE = WEBSITE_REAL_ESTATE;
//export const CURRENT_PURPOSE = WEBSITE_FARM_TRADE;



export const WEBSITE_CONSTANTS = CURRENT_PURPOSE === WEBSITE_REAL_ESTATE ? realEstateConstant() : FarmConstants();






