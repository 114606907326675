import {
  delay,
  put,
  takeEvery,
  Effect,
  ForkEffect,
  call,
  select,
} from "redux-saga/effects";
import {
  addCommercialContractAPI,
  allSubjectsLiftedAPI,
  buyerSignAPI,
  createCommercialContractAPI,
  sellerSignAPI,
  updateCommercialAPI,
  contractsListByUserAPI,
  updateCommercialDBAPI,
  updateCommercialDB2API,
  updateCommercialDB3API,
  confirmBySellerAPI,
  confirmByBuyerAPI,
  updateProductDBAPI,
  transportProductApi,
  transferProductApi,
  getProductsListAPI,
  getLocationsListAPI,
  getProductStateAPI,
  getFetchLedgerLotAPI,
  getFetchLedgerLotHistoryAPI,
  getUOMListAPI,
  getProductLotListAPI,
  getProductNextStateAPI,
} from "./api";
import {
  addCommercialContract,
  allSubjectsLift,
  buyerConfirm,
  createCommercialContract,
  sellerConfirm,
  updateAddCommercialContract,
  updateCommercialContract,
  updateCreateCommercialContract,
  productsByUser,
  setUserProductList,
  contractsByUser,
  setUserContractsList,
  updateCommercialDB,
  setUpdateCommercialDB,
  setFetching,
  setUpdateProductDB,
  updateProductDB,
  setUpdateCommercialDB2,
  updateCommmercialDB2,
  transferProduct,
  transportProduct,
  getProductsList,
  getLocationsList,
  setProductsList,
  setLocationsList,
  getProductStateList,
  setProductStateList,
  updateCommercialDB3,
  setUpdateCommercialDB3,
  getFetchLedgerLot,
  setFetchLedgerLot,
  getFetchLedgerLotHistory,
  setFetchLedgerLotHistory,
  getUOMList,
  setUOMList,
  getProductLotList,
  setProductLotList,
  getProductNextStateList,
  setProductNextStateList,
} from "./slice";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { ToastProperties } from "../../utils/constants/toastProperties";
import { FormattedMessage } from "react-intl";
const axios = require('axios');
const apiKey = 'AIzaSyBqzTRKyZoQuNk-xsLoZLEsfY_hi7GJe64';


const showToast = (type, message) => {
  // Use the toast function to display the appropriate toast message
  if (type === 'success') {
    toast.success(message, {
      ...ToastProperties
    });
  } else if (type === 'error') {
    toast.error(message, {
      ...ToastProperties
    });
  }
};

export function* addCommercialContractS({ payload, type }) {
  try {
    const { status, productRefId, result } = yield call(
      addCommercialContractAPI,
      payload
    );

    if (status === "error") {
      console.log();
      yield showToast('error', <FormattedMessage id="toast.contract.confirmcontract.error" />);
    } else {
      yield showToast('success', <FormattedMessage id="toast.contract.confirmcontract.success" />);
    }
    yield put(updateAddCommercialContract({ status, productRefId }));

    if (status === "Success") {
      const {
        status: dbStatus,
        contractId,
        contractAddress,
      } = yield call(createCommercialContractAPI, payload);

      yield put(
        updateCreateCommercialContract({
          status: dbStatus,
          contractId,
          contractAddress,
        })
      );

      yield showToast('success', `Created Contract Id: ${contractId} and Contract Address: ${contractAddress}`);
    }
  } catch (error) {
    console.log("API ERROR", error);
  }
  yield put(setFetching(false));
}

export function* allSubjectsLiftS({ payload, type }) {
  try {
    var { result: allSubjectsResult } = yield call(
      allSubjectsLiftedAPI,
      payload
    );
    //   yield put(updateAddCommercialContract(result));
    // var {result: createCommercialResult} = yield call(createCommercialContractAPI, payload)
  } catch (error) {
    console.log("API ERROR", error);
  }
  yield put(setFetching(false));
}

export function* sellerSignConfirmS({ payload, type }) {
  try {
    var { status, message } = yield call(confirmBySellerAPI, payload);
    //   yield put(updateAddCommercialContract(result));
    // var {result: createCommercialResult} = yield call(createCommercialContractAPI, payload)
    if (status === "Success") {
      yield showToast('success', <FormattedMessage id="toast.contract.contractupdate.success" />);
    } else {
      yield showToast('error', <FormattedMessage id="toast.contract.contractupdate.error" />);
    }
  } catch (error) {
    console.log("API ERROR", error);
  }
  yield put(setFetching(false));
}

export function* buyerSignConfirmS({ payload, type }) {
  try {
    var { status, message } = yield call(confirmByBuyerAPI, payload);
    //   yield put(updateAddCommercialContract(result));
    // var {result: createCommercialResult} = yield call(createCommercialContractAPI, payload)
    if (status === "Success") {
      yield showToast('success', <FormattedMessage id="toast.contract.contractupdate.success" />);
    } else {
      yield showToast('error', <FormattedMessage id="toast.contract.contractupdate.error" />);
    }
  } catch (error) {
    console.log("API ERROR", error);
  }

  yield put(setFetching(false));
}

export function* createCommercialContractS({ payload, type }) {
  try {
    var { result: allSubjectsResult } = yield call(
      createCommercialContractAPI,
      payload
    );
    //   yield put(updateAddCommercialContract(result));
    // var {result: createCommercialResult} = yield call(createCommercialContractAPI, payload)
  } catch (error) {
    console.log("API ERROR", error);
  }
  yield put(setFetching(false));
}

export function* updateCommercialContractS({ payload, type }) {
  try {
    var { result: allSubjectsResult } = yield call(
      updateCommercialAPI,
      payload
    );
    //   yield put(updateAddCommercialContract(result));
    // var {result: createCommercialResult} = yield call(createCommercialContractAPI, payload)
  } catch (error) {
    console.log("API ERROR", error);
  }
  yield put(setFetching(false));
}

export function* getProductsListByUserS() {
  const userinfo = JSON.parse(localStorage.getItem("userinfo"));
  try {

    yield put(setUserProductList());
  } catch (error) {
    console.log("API ERROR", error);
  }
  yield put(setFetching(false));
}

export function* getContractsListByUserS() {
  const storedLanguage = localStorage.getItem("language");
  const targetLanguage = storedLanguage ? storedLanguage.replace(/["']/g, '') : "en";
  const userinfo = JSON.parse(localStorage.getItem("userinfo"));
  try {
    var { result } = yield call(contractsListByUserAPI, {
      userId: userinfo.userId,
      language: targetLanguage
    });
    yield put(setUserContractsList(result));
    // var {result: createCommercialResult} = yield call(createCommercialContractAPI, payload)
  } catch (error) {
    console.log("API ERROR", error);
  }
  yield put(setFetching(false));
}

export function* updateCommmercialDBS({ payload, type }) {
  // const { userEmail } = select((s) => s.user);
  // const userinfo = JSON.parse(localStorage.getItem("userinfo"));
  try {
    var { message } = yield call(updateCommercialDBAPI, payload);
    yield showToast('success', <FormattedMessage id="toast.contract.contractupdate.success" />);
    yield put(setUpdateCommercialDB(message));
    yield call(getContractsListByUserS);
    // var {result: createCommercialResult} = yield call(createCommercialContractAPI, payload)
  } catch (error) {
    yield showToast('error', <FormattedMessage id="toast.contract.contractupdate.error" />);
    console.log("API ERROR", error);
  }
  yield put(setFetching(false));
}

export function* updateCommmercialDB2S({ payload, type }) {
  // const { userEmail } = select((s) => s.user);
  // const userinfo = JSON.parse(localStorage.getItem("userinfo"));
  try {
    var { message } = yield call(updateCommercialDB2API, payload);
    yield showToast('success', <FormattedMessage id="toast.contract.contractupdate.success" />);
    yield put(setUpdateCommercialDB2(message));
    yield call(getContractsListByUserS);
    // var {result: createCommercialResult} = yield call(createCommercialContractAPI, payload)
  } catch (error) {
    yield showToast('error', <FormattedMessage id="toast.contract.contractupdate.error" />);
    console.log("API ERROR", error);
  }
  yield put(setFetching(false));
}

export function* updateCommmercialDB3S({ payload, type }) {
  try {
    var { message, error, status } = yield call(updateCommercialDB3API, payload);
    if (error && status === 400) {
      yield showToast('error', <FormattedMessage id="toast.contract.contractinterest.exist" />);
    } else if (error) {
      yield showToast('error', <FormattedMessage id="toast.contract.contractinterest.error" />);
    } else {
      yield showToast('success', <FormattedMessage id="toast.contract.contractinterest.success" />);
    }
    yield put(setUpdateCommercialDB3(message));
    yield call(getContractsListByUserS);
    // var {result: createCommercialResult} = yield call(createCommercialContractAPI, payload)
  } catch (error) {
    yield showToast('error', <FormattedMessage id="toast.contract.contractinterest.error" />);
    console.log("API ERROR", error);
  }
  yield put(setFetching(false));
}

export function* updateProductDBS({ payload, type }) {
  // const { userEmail } = select((s) => s.user);
  // const userinfo = JSON.parse(localStorage.getItem("userinfo"));
  try {
    var { message } = yield call(updateProductDBAPI, payload);
    yield put(setUpdateProductDB(message));
    yield showToast('success', <FormattedMessage id="toast.product.forsale.successful" />);
    yield call(getProductsListByUserS);
    yield put(getProductLotList());
    // var {result: createCommercialResult} = yield call(createCommercialContractAPI, payload)
  } catch (error) {
    console.log("API ERROR", error);
  }
  yield put(setFetching(false));
}

export function* transferProducts({ payload, type }) {
  try {
    var result = yield call(transferProductApi, payload);

    if (result.error && result.error === "Unauthorized: Invalid token") {
      yield showToast('error', <FormattedMessage id="toast.expiredtoken" />); 
    } else if (result.error) {
      yield showToast('error', <FormattedMessage id="toast.product.transform.error" />);
    } else {
      yield showToast('success', <FormattedMessage id="toast.product.transform.success" />);
      yield put(getProductLotList());
    }

    yield put(setFetching(false));
  } catch (error) {
    console.log("API ERROR", error);
  }
  yield put(setFetching(false));
}

export function* transportProducts({ payload, type }) {
  try {
    var result = yield call(transportProductApi, payload);

    if (result.error && result.error === "Unauthorized: Invalid token") {
      yield showToast('error', <FormattedMessage id="toast.expiredtoken" />); 
    } else if (result.error) {
      yield showToast('error', <FormattedMessage id="toast.product.transform.error" />);
    } else {
      yield showToast('success', <FormattedMessage id="toast.product.transform.success" />);
      yield put(getProductLotList());
    }

    yield put(setFetching(false));
  } catch (error) {
    console.log("API ERROR", error);
  }
  yield put(setFetching(false));
}

export function* productsList({ payload, type }) {
  try {
    const storedLanguage = localStorage.getItem("language");
    const targetLanguage = storedLanguage ? storedLanguage.replace(/["']/g, '') : "en";

    const response = yield call(getProductsListAPI, targetLanguage);
    
    yield put(setProductsList(response.result));
    // yield put(setProductsList(reformData));
    yield put(setFetching(false));
  } catch (error) {
    console.log("API ERROR", error);
  }
  yield put(setFetching(false));
}

export function* productStateList({ payload, type }) {
  try {
    const storedLanguage = localStorage.getItem("language");
    const targetLanguage = storedLanguage ? storedLanguage.replace(/["']/g, '') : "en";

    const response = yield call(getProductStateAPI, targetLanguage);

    yield put(setProductStateList(response.result));
    yield put(setFetching(false));
  } catch (error) {
    console.log("API ERROR", error);
  }
  yield put(setFetching(false));
}

export function* locationList({ payload, type }) {
  try {
    const storedLanguage = localStorage.getItem("language");
    const targetLanguage = storedLanguage ? storedLanguage.replace(/["']/g, '') : "en";

    var response = yield call(getLocationsListAPI, targetLanguage);

    // Update Redux state with translated results
    yield put(setLocationsList(response.result));

    yield put(setFetching(false));
  } catch (error) {
    yield put(
      setLocationsList([
        { id: 1, location: "Singapore" },
        { id: 2, location: "Malaysia" },
        { id: 3, location: "America" },
        { id: 4, location: "Austria" },
      ])
    );
    console.log("API ERROR", error);
  }
  yield put(setFetching(false));
}

export function* getFetchLedgerLotS({ payload, type }) {
  try {
    const storedLanguage = localStorage.getItem("language");
    const targetLanguage = storedLanguage ? storedLanguage.replace(/["']/g, '') : "en";
    payload.targetLanguage = targetLanguage;

    const response = yield call(getFetchLedgerLotAPI, payload);
    yield put(setFetchLedgerLot(response));
  } catch (error) {
    console.log("API ERROR", error);
  }
  yield put(setFetching(false));
}

export function* getFetchLedgerLotHistoryS({ payload, type }) {
  try {
    const response = yield call(getFetchLedgerLotHistoryAPI, payload);
    const ledgeResultList = Object.values(response.result); // Check if 'response.ledgeResult' is defined before converting
    // Now, you can set the 'ledgeResultList' in the state
    yield put(setFetchLedgerLotHistory(ledgeResultList));
  } catch (error) {
    console.log("API ERROR", error);
  }
  yield put(setFetching(false));
}

export function* getUOMListS() {
  try {
    const storedLanguage = localStorage.getItem("language");
    const targetLanguage = storedLanguage ? storedLanguage.replace(/["']/g, '') : "en";

    var response = yield call(getUOMListAPI, targetLanguage);

    yield put(setUOMList(response.result));
  } catch (error) {
    console.log("API ERROR", error);
  }
  yield put(setFetching(false));
}

export function* getProductLotListS() {
  try {
    const storedLanguage = localStorage.getItem("language");
    const targetLanguage = storedLanguage ? storedLanguage.replace(/["']/g, '') : "en";
    const userinfo = JSON.parse(localStorage.getItem("userinfo"));

    var response = yield call(getProductLotListAPI, {
      targetLanguage, userId: userinfo.userId});

    yield put(setProductLotList(response.result));
  } catch (error) {
    console.log("API ERROR", error);
  }
  yield put(setFetching(false));
}

export function* productNextStateList({ payload, type }) {
  try {
    const response = yield call(getProductNextStateAPI);

    yield put(setProductNextStateList(response.result));
    // yield put(setProductStateList(reformData));
    yield put(setFetching(false));
  } catch (error) {
    console.log("API ERROR", error);
  }
  yield put(setFetching(false));
}

function* ProductSagaWatcher() {
  yield takeEvery(addCommercialContract, addCommercialContractS);
  yield takeEvery(createCommercialContract, createCommercialContractS);
  yield takeEvery(allSubjectsLift, allSubjectsLiftS);
  yield takeEvery(updateCommercialContract, updateCommercialContractS);
  yield takeEvery(buyerConfirm, buyerSignConfirmS);
  yield takeEvery(sellerConfirm, sellerSignConfirmS);
  yield takeEvery(productsByUser, getProductsListByUserS);
  yield takeEvery(contractsByUser, getContractsListByUserS);
  yield takeEvery(updateCommercialDB, updateCommmercialDBS);
  yield takeEvery(updateCommmercialDB2, updateCommmercialDB2S);
  yield takeEvery(updateCommercialDB3, updateCommmercialDB3S);
  yield takeEvery(updateProductDB, updateProductDBS);
  yield takeEvery(transferProduct, transferProducts);
  yield takeEvery(transportProduct, transportProducts);
  yield takeEvery(getProductsList, productsList);
  yield takeEvery(getLocationsList, locationList);
  yield takeEvery(getProductStateList, productStateList);
  yield takeEvery(getFetchLedgerLot, getFetchLedgerLotS);
  yield takeEvery(getFetchLedgerLotHistory, getFetchLedgerLotHistoryS);
  yield takeEvery(getUOMList, getUOMListS);
  yield takeEvery(getProductLotList, getProductLotListS);
  yield takeEvery(getProductNextStateList, productNextStateList);
}

const ProductSaga = ProductSagaWatcher;

export default ProductSaga;
