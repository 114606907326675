import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { validateUser } from "../Redux/user/slice";
import { Navigate } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  const [passwordHelpText, setPasswordHelpText] = React.useState("");
  const [userHelpText, setUserHelpText] = React.useState("");
  const dispatch = useDispatch();
  const [isUser, setIsUSer] = React.useState(false);
  const { isUserLoggedIn } = useSelector((s) => s.user);

  React.useEffect(() => {
    if (isUserLoggedIn) {
      setIsUSer(isUserLoggedIn);
    }
  }, [isUserLoggedIn]);

  // const userName = new Map();
  // const accountIds = new Map();

  // accountIds.set("testbuyer1", "0.0.48903622");
  // accountIds.set("testseller2", "0.0.48903965");

  // userName.set("testseller2", "Arkadia");
  // userName.set("testbuyer1", "Arkadia");
  // userName.set("admin@arkadia.global.com", "Arkadia");

  // const userNameArray = [
  //   "buyer@arkadia.global.com",
  //   "seller@arkadia.global.com",
  //   "admin@arkadia.global.com",
  // ];

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // var password = userName.get(data.get("email"));
    if (!data.get("email")) {
      setUserHelpText("Please provide a username.");
      return;
    }
    if (!data.get("password")) {
      setPasswordHelpText("Please provide a password.");
      return;
    }

    dispatch(
      validateUser({
        email: data.get("email"),
        password: data.get("password"),
        cb: () => { console.log('nothing to call') }
      })
    );

    // if (!password) {
    //   setUserHelpText("User not exists.");
    // } else if (password === data.get("password")) {
    //   let user = userNameArray.indexOf(data.get("email"));
    //   user = user + 1;
    //   localStorage.setItem("userLogged", data.get("email") || "testseller2");
    //   window.location.assign("/");
    // } else {
    //   setPasswordHelpText("Please provide a correct password.");
    // }

    // localStorage.setItem(
    //   "accountId",
    //   accountIds.get(userName || "testseller2")
    // );
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        {isUser && <Navigate to="/" replace={true} />}
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "rgba(255,255,255,0.8)",
            padding: 5,
            borderRadius: 10,
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onKeyDown={() => setUserHelpText("")}
              error={userHelpText !== ""}
              helperText={userHelpText}
              autoFocus
            />
            <TextField
              margin="normal"
              helperText={passwordHelpText}
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              onKeyDown={() => setPasswordHelpText("")}
              id="password"
              error={passwordHelpText !== ""}
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
