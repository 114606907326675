import { createSlice } from "@reduxjs/toolkit";

export const PropertiesSlice = createSlice({
  name: "properties",
  initialState: {
    isSuccess: false,
    fetching: false,
    msg: "",
    data: [],
    contractCreated: false,
    buyerTransaction: {},
    sellerTransaction: {},
  },
  reducers: {
    getPropertiesReducer: (state) => {
      state.fetching = true;
      state.isSuccess = false;
      state.msg = "";
    },
    getPropertiesSuccess: (state, { payload, type }) => {
      state.fetching = false;
      state.isSuccess = true;
      state.msg = "";
      state.data = payload.result;
    },
    saveProperties: (state, { payload, type }) => {
      state.fetching = true;
      state.isSuccess = false;
      state.msg = "";
    },
    saveDraftProperties: (state, { payload, type }) => {
      state.fetching = true;
      state.isSuccess = false;
      state.msg = "";
    },
    loadLotImage: (state, { payload, type }) => {
      state.fetching = true;
      state.isSuccess = false;
      state.msg = "";
    },
    deleteDraftProperties: (state, { payload, type }) => {
      state.fetching = true;
      state.isSuccess = false;
      state.msg = "";
    },
    walletBalanceFetch: (state) => {
      state.fetching = true;
      state.isSuccess = false;
      state.msg = "";
    },
    walletBalanceFetchSuccess: (state, { payload, type }) => {
      state.fetching = false;
      state.isSuccess = true;
      state.msg = "";
      state.walletBalance = payload;
    },
    walletBalanceFetchFailed: (state, { payload, type }) => {
      state.fetching = false;
      state.isSuccess = true;
      state.msg = "";
      state.walletBalance = "Wallet not linked";
    },
    transferNFT: (state, { payload, type }) => {
      state.fetching = true;
      state.isSuccess = false;
      state.msg = "";
    },
    removeSuccessMessage: (state, { payload, type }) => {
      state.transferedNFT = undefined;
    },
    transferNFTSuccess: (state, { payload, type }) => {
      state.fetching = false;
      state.isSuccess = true;
      state.msg = "";
      state.transferedNFT = "Completed";
      console.log("Completed");
    },
    fetchUserAccount: (state, { payload, type }) => {
      state.fetching = true;
      state.isSuccess = false;
      state.msg = "";
      state.userDetails = {};
    },
    fetchUserAccountSuccess: (state, { payload, type }) => {
      state.fetching = false;
      state.isSuccess = true;
      state.msg = "";
      state.userDetails = payload;
    },
    fetchRecentTransaction: (state, { payload, type }) => {
      state.fetching = true;
      state.fetchTrans = true;
      state.isSuccess = false;
    },
    fetchRecentTransactionSuccess: (state, { payload, type }) => {
      state.fetching = false;
      state.fetchTrans = false;
      state.isSuccess = true;
      state.msg = "";
      state.recentTrans = payload;
    },
    failMsg: (state, { payload, type }) => {
      state.fetching = false;
      state.isSuccess = false;
      state.msg = payload;
      state.data = [];
    },
    createContract: (state, { payload, type }) => {
      state.fetching = true;
      state.isSuccess = false;
    },
    createCommercialContract: (state, { payload, type }) => {
      state.fetching = true;
      state.isSuccess = false;
    },
    sellerSign: (state, { payload, type }) => {
      state.fetching = true;
      state.isSuccess = false;
    },
    buyerSign: (state, { payload, type }) => {
      state.fetching = true;
      state.isSuccess = false;
    },
    createContractSuccess: (state, { payload, type }) => {
      state.fetching = false;
      state.isSuccess = true;
      state.contractCreated = true;
    },
    createCommercialContractSuccess: (state, { payload, type }) => {
      state.fetching = false;
      state.isSuccess = true;
      state.contractCreated = true;
    },
    sellerSignSuccess: (state, { payload, type }) => {
      state.fetching = false;
      state.isSuccess = true;
      state.sellerTransaction = payload;
    },
    buyerSignSuccess: (state, { payload, type }) => {
      state.fetching = false;
      state.isSuccess = true;
      state.sellerTransaction = payload;
    },
    setFetching: (state, { payload, type }) => {
      console.log({ payload })
      state.fetching = payload
    },
    signUpForm: (state, { payload, type }) => {
      state.fetching = true;
      state.isSuccess = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchRecentTransaction,
  fetchRecentTransactionSuccess,
  removeSuccessMessage,
  fetchUserAccount,
  fetchUserAccountSuccess,
  saveProperties,
  saveDraftProperties,
  deleteDraftProperties,
  loadLotImage,
  transferNFT,
  transferNFTSuccess,
  getPropertiesReducer,
  getPropertiesSuccess,
  failMsg,
  walletBalanceFetch,
  walletBalanceFetchSuccess,
  walletBalanceFetchFailed,
  createContract,
  createCommercialContract,
  sellerSign,
  buyerSign,
  createContractSuccess,
  createCommercialContractSuccess,
  sellerSignSuccess,
  buyerSignSuccess,
  setFetching,
  signUpForm,
} = PropertiesSlice.actions;

export default PropertiesSlice.reducer;
