import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRecentTransaction, fetchUserAccount } from '../Redux/properties/PropertiesSlice';
import AssetsBoard from './AssetsBoard';
import TransactionBoard from './TransactionBoard';
import { styled } from '@mui/material';

import { IntlProvider, FormattedMessage } from "react-intl";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs({ id }) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const realEstateData = useSelector((state) => state.properties)

    const StyledTab = styled(Tab)(({ theme }) => ({
        '&.Mui-selected': {
            color: 'white',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&.Mui-disabled': {
            color: 'gray',
            fontWeight: theme.typography.fontWeightMedium,
        },
        color: 'gray',

    }))

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} sx={{paddingLeft: "250px"}} onChange={handleChange} aria-label="basic tabs example">
                    <StyledTab label={<FormattedMessage id="wallet.tabs.assets" />} {...a11yProps(0)} />
                    <StyledTab label={<FormattedMessage id="wallet.tabs.lasttrans" />} {...a11yProps(1)} />
                    <StyledTab label={<FormattedMessage id="wallet.tabs.swap" />} disabled />
                    <StyledTab label={<FormattedMessage id="wallet.tabs.NTFS" />} disabled />
                    <StyledTab label={<FormattedMessage id="wallet.tabs.DAPPS" />} disabled />
                    <StyledTab label={<FormattedMessage id="wallet.tabs.STAKE" />} disabled />


                </Tabs>
            </Box>
            <TabPanel value={value} style={{ backgroundColor: "rgb(17,17,38)", height: "54vh" }} index={0}>
                <AssetsBoard record={realEstateData.userDetails?.details?.acctBalInfo} />
            </TabPanel>
            <TabPanel value={value} style={{ backgroundColor: "rgb(17,17,38)", height: "54vh" }} index={1}>
                <TransactionBoard record={realEstateData.recentTrans} />
            </TabPanel>

        </Box>
    );
}
