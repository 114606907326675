import { createSlice } from "@reduxjs/toolkit";

export const ProductsSlice = createSlice({
  name: "products",
  initialState: {
    isSuccess: false,
    fetching: false,
    addCommercialContractD: {},
    createCommercialContractD: {},
    allSubjectsLiftedD: {},
    sellerConfirmationD: {},
    buyerConfirmationD: {},
    updateCommercialContractD: {},
    userProducts: [],
    userContracts: [],
    updateCommercialMessage: "",
    updateCommercialMessage2: "",
    updateProductMessage: "",
    productsList: [],
    locationsList: [],
    productStateList: [],
    fetchLedgerLot: {},
    fetchLedgerLotHistory: {},
    UOMList: [],
    productLotList: [],
    productNextStateList: [],
  },
  reducers: {
    addCommercialContract: (state) => {
      state.fetching = true;
    },
    updateAddCommercialContract: (state, { payload, type }) => {
      state.fetching = false;
      state.addCommercialContractD = payload;
    },
    createCommercialContract: (state) => {
      state.fetching = true;
    },
    updateCreateCommercialContract: (state, { payload, type }) => {
      state.fetching = false;
      state.createCommercialContractD = payload;
    },
    allSubjectsLift: (state) => {
      state.fetching = true;
    },
    updateAllSubjectsLift: (state, { payload, type }) => {
      state.fetching = false;
      state.allSubjectsLiftedD = payload;
    },
    buyerConfirm: (state) => {
      state.fetching = true;
    },
    updateBuyerConfirmation: (state, { payload, type }) => {
      state.fetching = false;
      state.buyerConfirmationD = payload;
    },
    sellerConfirm: (state) => {
      state.fetching = true;
    },
    updateSellerConfirmation: (state, { payload, type }) => {
      state.fetching = false;
      state.sellerConfirmationD = payload;
    },
    updateCommercialContract: (state) => {
      state.fetching = true;
    },
    setUpdateCommercialContract: (state, { payload, type }) => {
      state.fetching = false;
      state.updateCommercialContractD = payload;
    },
    productsByUser: (state) => {
      state.fetching = true;
    },
    setUserProductList: (state, { payload, type }) => {
      state.fetching = false;
      state.userProducts = payload;
    },
    contractsByUser: (state) => {
      state.fetching = true;
    },
    setUserContractsList: (state, { payload, type }) => {
      state.fetching = false;
      state.userContracts = payload;
    },
    updateCommercialDB: (state, { payload, type }) => {
      state.fetching = true;
    },
    setUpdateCommercialDB: (state, { payload, type }) => {
      state.fetching = false;
      state.updateCommercialMessage = payload;
    },
    updateCommmercialDB2: (state, { payload, type }) => {
      state.fetching = true;
    },
    setUpdateCommercialDB2: (state, { payload, type }) => {
      state.fetching = false;
      state.updateCommercialMessage2 = payload;
    },
    updateCommercialDB3: (state, { payload, type }) => {
      state.fetching = true;
    },
    setUpdateCommercialDB3: (state, { payload, type }) => {
      state.fetching = false;
      state.updateCommercialMessage = payload;
    },
    updateProductDB: (state, { payload, type }) => {
      state.fetching = true;
    },
    setUpdateProductDB: (state, { payload, type }) => {
      state.fetching = false;
      state.updateProductMessage = payload;
    },
    setFetching: (state, { payload, type }) => {
      state.fetching = payload;
    },
    transferProduct: (state, { payload, type }) => {
      state.fetching = true;
    },
    transportProduct: (state, { payload, type }) => {
      state.fetching = true;
    },
    getProductsList: (state, { payload, type }) => {
      state.fetching = true;
    },
    setProductsList: (state, { payload, type }) => {
      state.productsList = payload;
    },
    getProductStateList: (state, { payload, type }) => {
      state.fetching = true;
    },
    setProductStateList: (state, { payload, type }) => {
      state.productStateList = payload;
    },
    getLocationsList: (state, { payload, type }) => {
      state.fetching = true;
    },
    setLocationsList: (state, { payload, type }) => {
      state.locationsList = payload;
    },
    getFetchLedgerLot: (state, { payload, type }) => {
      state.fetching = true;
    },
    setFetchLedgerLot: (state, { payload, type }) => {
      state.fetchLedgerLot = payload;
    },
    getFetchLedgerLotHistory: (state, { payload, type }) => {
      state.fetching = true;
    },
    setFetchLedgerLotHistory: (state, { payload, type }) => {
      state.fetchLedgerLotHistory = payload;
    },
    getUOMList: (state, { payload, type }) => {
      state.fetching = true;
    },
    setUOMList: (state, { payload, type }) => {
      state.UOMList = payload;
    },
    getProductLotList: (state, { payload, type }) => {
      state.fetching = true;
    },
    setProductLotList: (state, { payload, type }) => {
      state.productLotList = payload;
    },
    getProductNextStateList: (state, { payload, type }) => {
      state.fetching = true;
    },
    setProductNextStateList: (state, { payload, type }) => {
      state.productNextStateList = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addCommercialContract,
  transferProduct,
  transportProduct,
  updateAddCommercialContract,
  createCommercialContract,
  updateCreateCommercialContract,
  allSubjectsLift,
  updateAllSubjectsLift,
  buyerConfirm,
  updateBuyerConfirmation,
  sellerConfirm,
  updateSellerConfirmation,
  updateCommercialContract,
  setUpdateCommercialContract,
  productsByUser,
  setUserProductList,
  contractsByUser,
  setUserContractsList,
  updateCommercialDB,
  setUpdateCommercialDB,
  updateProductDB,
  setUpdateProductDB,
  setFetching,
  setUpdateCommercialDB2,
  updateCommmercialDB2,
  getProductsList,
  getLocationsList,
  setProductsList,
  setLocationsList,
  getProductStateList,
  setProductStateList,
  updateCommercialDB3,
  setUpdateCommercialDB3,
  getFetchLedgerLot,
  setFetchLedgerLot,
  getFetchLedgerLotHistory,
  setFetchLedgerLotHistory,
  getUOMList,
  setUOMList,
  getProductLotList,
  setProductLotList,
  getProductNextStateList,
  setProductNextStateList,
} = ProductsSlice.actions;

export default ProductsSlice.reducer;
