import { createSlice } from "@reduxjs/toolkit";

export const UserSlice = createSlice({
  name: "user",
  initialState: {
    isFetching: false,
    redirectUrl:"",
    isUserLoggedIn: false,
    userInfo: JSON.parse(localStorage.getItem("userinfo")) || {
      userId: "",
      userName: "",
      email: "",
      Country: "",
      accountId: "",
      product_contractId: "",
      commercial_contractId: "",
    },
    userEmail: "",
    showLogin: false,
    callBack: null,
    invalidUser: "",
    userFullDetails: {},
  },
  reducers: {
    setRedirectUrl: (state, {payload, type}) => {
      state.redirectUrl = payload.redirectUrl;
    },

    validateUser: (state) => {
      state.isFetching = true;
    },
    setIsUserLoggedIn: (state, { payload, type }) => {
      state.isFetching = false;
      state.isUserLoggedIn = payload;
      state.invalidUser = "";
    },
    getUserInfo: (state) => {
      state.isFetching = true;
    },
    setUserInfo: (state, { payload, type }) => {
      state.isFetching = false;
      
      if (payload && payload.length > 0 && payload[0].email) {
        state.userInfo = payload;
        state.userEmail = payload[0].email;
      } else {
        state.userInfo = {
          userId: "",
          userName: "",
          email: "",
          Country: "",
          accountId: "",
          product_contractId: "",
          commercial_contractId: "",
        };
        state.userEmail = "";
      }
      state.invalidUser = "";
    },    
    setShowLogin: (state, { payload, type }) => {
      state.showLogin = payload.value;
      state.callBack = payload.cb;      
    },
    logout: (state) => {
      state.isFetching = false;
      state.isUserLoggedIn = false;
      state.userInfo = {
        userId: "",
        userName: "",
        email: "",
        Country: "",
        accountId: "",
        product_contractId: "",
        commercial_contractId: "",
      };
      state.invalidUser = "";
    },
    setInValidUser: (state, { payload, type }) => {
      state.invalidUser = payload;
    },
    getUserFullDetails: (state, { payload, type }) => {
      state.isFetching = true;
      state.isSuccess = false;
      state.userFullDetails = payload;
    },
    setUserFullDetails: (state, { payload, type }) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.userFullDetails = payload;
    },
  },
});

export const {
  validateUser,
  setIsUserLoggedIn,
  getUserInfo,
  setUserInfo,
  logout,
  setShowLogin,
  setRedirectUrl,
  resetShowSuccessMessage,
  setInValidUser,
  getUserFullDetails,
  setUserFullDetails
} = UserSlice.actions;

export default UserSlice.reducer;
